import React, { useRef, useEffect } from 'react';

function ConfirmationModal({ isOpen, onClose, onConfirm, onKeepMonth, onKeepWeek, theme, t }) {
  const modalRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className={`confirmation-modal ${theme}`}>
      <div className="modal-content" ref={modalRef}>
        <p className="confirmation-text">{t.confirmClearData}</p>
        <div className="modal-buttons">
          <button onClick={onConfirm}>{t.yes}</button>
          <button onClick={onKeepMonth}>{t.keepMonth}</button>
          <button onClick={onKeepWeek}>{t.keepWeek}</button>
          <button onClick={onClose}>{t.no}</button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmationModal;