import React, { useMemo, useCallback, useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine } from 'recharts';
import { format } from 'date-fns';
import { scaleLog } from 'd3-scale';

const interpolateColor = (color1, color2, factor) => {
  const result = color1.map((channel, index) => 
    Math.round(channel + factor * (color2[index] - channel))
  );
  return `rgb(${result.join(',')})`;
};

const CustomTopAxisTick = ({ x, y, payload, data, unit, theme }) => {
  const index = data.findIndex(item => item.date === payload.value);
  const weight = data[index].weight;
  let formattedValue = unit === 'lbs' ? weight.toFixed(2) : weight.toFixed(2);
  
  let [integerPart, decimalPart] = formattedValue.split('.');
  decimalPart = decimalPart.replace(/0+$/, '');
  
  let color;
  if (data.length === 1) {
    color = theme === 'light' ? 'black' : 'white';
  } else {
    color = data[index].color;
  }
  
  const lighterColor = theme === 'light' 
    ? `rgba(${parseInt(color.slice(4, -1).split(',')[0])}, ${parseInt(color.slice(4, -1).split(',')[1])}, ${parseInt(color.slice(4, -1).split(',')[2])}, 0.77)`
    : `rgba(255, 255, 255, 0.77)`;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={-4} textAnchor="middle" fontSize="12" fontWeight="bold" textRendering="optimizeLegibility">
        <tspan fill={color}>{integerPart}</tspan>
        {decimalPart && <tspan fill={lighterColor}>.{decimalPart}</tspan>}
      </text>
    </g>
  );
};

function WeightGraph({ data, goalWeight, theme, t, timeRange, animateGraphs, unit }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useLayoutEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    handleResize(); // Call it immediately to set the initial width
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const convertWeightMult = useCallback((weight) => {
    return unit === 'lbs' ? (weight * 2.20462) : weight;
  }, [unit]);

  const calculateColors = useCallback((converted, goalWeight) => {
    const isLosingWeight = goalWeight < converted[converted.length - 1].weight;

    const maxWeight = Math.max(...converted.map(d => d.weight));
    const minWeight = Math.min(...converted.map(d => d.weight));
    const greenColor = [83, 172, 0];
    const redColor = [220, 35, 0];

    return converted.map(item => {
      const normalizedWeight = (item.weight - minWeight) / (maxWeight - minWeight);
      const color = isLosingWeight
        ? interpolateColor(greenColor, redColor, normalizedWeight) 
        : interpolateColor(redColor, greenColor, normalizedWeight);
      return { ...item, color };
    });
  }, []);

  const convertedData = useMemo(() => {
    if (!data || !Array.isArray(data)) return [];
    
    const converted = data.map(d => ({
      ...d,
      weight: convertWeightMult(d.weight),
      movingAverage: d.movingAverage ? convertWeightMult(d.movingAverage) : undefined
    }));

    return calculateColors(converted, goalWeight);
  }, [data, calculateColors, goalWeight, convertWeightMult]);

  const formatXAxis = useCallback((dateString, firstDate, lastDate) => {
    const date = new Date(dateString);
    
    if (firstDate.getFullYear() !== lastDate.getFullYear()) {
      return format(date, 'dd/MM/yy');
    } else {
      return format(date, 'dd/MM');
    }
  }, []);

  const logScale = scaleLog().base(1);

  const yAxisDomain = useMemo(() => {
    if (!convertedData.length) return ['auto', 'auto'];
    
    const weights = convertedData.map(d => d.weight);
    const minWeight = Math.min(...weights);
    const maxWeight = Math.max(...weights);
    
    let minDomain = minWeight * 0.90;
    let maxDomain = maxWeight * 1.05;

    if (goalWeight) {
      const convertedGoalWeight = goalWeight;
      minDomain = Math.min(minDomain, convertedGoalWeight * 0.99);
      maxDomain = Math.max(maxDomain, convertedGoalWeight * 1.01);
    }

    return [Math.max(1, minDomain), maxDomain];
  }, [convertedData, goalWeight]);

  const getCustomTicks = useCallback((data, maxTicks) => {
    if (data.length <= maxTicks) return data.map(d => d.date);

    const step = Math.ceil((data.length - 1) / (maxTicks - 1));
    const ticks = [];

    for (let i = 0; i < data.length; i += step) {
      ticks.push(data[i].date);
    }

    // Ensure first and last ticks are always included
    if (ticks[0] !== data[0].date) {
      ticks[0] = data[0].date;
    }
    if (ticks[ticks.length - 1] !== data[data.length - 1].date) {
      ticks[ticks.length - 1] = data[data.length - 1].date;
    }

    return ticks;
  }, []);

  const customTicks = useMemo(() => {
    const maxTicks = windowWidth < 768 ? 9 : 12;
    return getCustomTicks(convertedData, maxTicks);
  }, [convertedData, windowWidth, getCustomTicks]);

  const yAxisTicks = useMemo(() => {
    if (!convertedData.length || !goalWeight) return [];
    
    const startWeight = convertedData[0].weight;
    const endWeight = convertedData[convertedData.length - 1].weight;
    const convertedGoalWeight = goalWeight;
    
    const uniqueTicks = [...new Set([startWeight, endWeight, convertedGoalWeight])].sort((a, b) => a - b);
    
    if (uniqueTicks.length < 3) {
      const min = Math.min(...uniqueTicks);
      const max = Math.max(...uniqueTicks);
      const range = max - min;
      if (uniqueTicks.length === 1) {
        uniqueTicks.push(min - range * 0.1, max + range * 0.1);
      } else {
        uniqueTicks.push((min + max) / 2);
      }
      uniqueTicks.sort((a, b) => a - b);
    }
    
    return uniqueTicks;
  }, [convertedData, goalWeight]);

  const getColorForValue = useCallback((value) => {
    const minWeight = Math.min(...convertedData.map(d => d.weight));
    const maxWeight = Math.max(...convertedData.map(d => d.weight));
    const normalizedWeight = (value - minWeight) / (maxWeight - minWeight);
    const greenColor = [83, 172, 0];
    const redColor = [220, 35, 0];
    
    const isGainingWeight = goalWeight > convertedData[convertedData.length - 1].weight;
    
    return isGainingWeight
      ? interpolateColor(redColor, greenColor, normalizedWeight)
      : interpolateColor(greenColor, redColor, normalizedWeight);
  }, [convertedData, goalWeight]);

  return (
    <div className="graph-wrapper">
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          data={convertedData}
          margin={{ top: -15, right: 20, left: -10, bottom: -10 }}
        >
          <defs>
            <linearGradient id="colorWeight" x1="0" y1="0" x2="0" y2="1">
              <stop offset="30%" stopColor={theme === 'light' ? "#8884d8" : "#61dafb"} stopOpacity={0.8} />
              <stop offset="95%" stopColor={theme === 'light' ? "#FFFFFF" : "#282c34"} stopOpacity={0.35} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" stroke={theme === 'light' ? "#ccc" : "#555"} />
          
          <XAxis
            dataKey="date"
            tickFormatter={(dateString) => formatXAxis(
              dateString, 
              new Date(convertedData[0].date), 
              new Date(convertedData[convertedData.length - 1].date)
            )}
            style={{ fontSize: '12px', fill: theme === 'light' ? "#333" : "#fff" }}
            ticks={customTicks}
          />
          
          <XAxis
            xAxisId="weight"
            orientation="top"
            dataKey="date"
            type="category"
            ticks={customTicks}
            tick={<CustomTopAxisTick data={convertedData} unit={unit} theme={theme} />}
            height={40}
            interval={0}
          />
          
          <YAxis
            scale={logScale}
            domain={yAxisDomain}
            ticks={yAxisTicks}
            style={{ fontSize: '12px', fill: theme === 'light' ? "#333" : "#fff" }}
            tickFormatter={(value) => {
              const formatted = unit === 'lbs' ? value.toFixed(2) : value.toFixed(2);
              return formatted.endsWith('.00') ? formatted.slice(0, -3) : formatted;
            }}
          />
          
          <Tooltip 
            labelFormatter={(label) => format(new Date(label), 'dd-MM-yyyy')}
            formatter={(value, name, props) => {
              if (name === 'weight' || name === 'movingAverage') {
                let formatted = unit === 'lbs' ? value.toFixed(2) : value.toFixed(2);
                if (formatted.endsWith('.00')) {
                  formatted = formatted.slice(0, -3);
                }
                const color = getColorForValue(value);
                return [<span style={{ color: color }}>{`${formatted} ${unit}`}</span>, name === 'weight' ? t.weight : t.movingAverage];
              }
              return [value, name];
            }}
            contentStyle={{
              backgroundColor: theme === 'light' ? 'white' : '#333',
              border: 'none',
              borderRadius: '12px',
              boxShadow: '0 2px 10px rgba(0,0,0,0.2)'
            }}
          />
                    
          <Area
            type="monotone"
            dataKey="weight"
            stroke={theme === 'light' ? "#8884d8" : "#61dafb"}
            fill="url(#colorWeight)"
            isAnimationActive={animateGraphs}
            animationDuration={500}
          />
          
          <Area
            type="monotone"
            dataKey="movingAverage"
            stroke={theme === 'light' ? "#82ca9d" : "#98ee99"}
            fill="none"
            isAnimationActive={animateGraphs}
            animationDuration={500}
          />
          
          {goalWeight && <ReferenceLine y={goalWeight} stroke="orange" strokeDasharray="10 5" strokeWidth={3} />}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

WeightGraph.propTypes = {
  data: PropTypes.array.isRequired,
  goalWeight: PropTypes.number,
  theme: PropTypes.string,
  t: PropTypes.object.isRequired,
  timeRange: PropTypes.string.isRequired,
  animateGraphs: PropTypes.bool,
  unit: PropTypes.string.isRequired,
};

WeightGraph.defaultProps = {
  data: [],
  goalWeight: null,
  theme: 'light',
  animateGraphs: true,
};

export default WeightGraph;