import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import { format } from 'date-fns';

function BarGraph({ data, theme, timeRange, animateGraphs, t, unit, newWeightAdded, goalWeight }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const convertWeight = (weight) => {
    return unit === 'lbs' ? weight * 2.20462 : weight;
  };

  const processedData = data.map((entry, index) => {
    if (index === 0) return { ...entry, difference: 0 };
    const currentWeight = convertWeight(entry.weight);
    const previousWeight = convertWeight(data[index - 1].weight);
    return { ...entry, difference: Number((currentWeight - previousWeight).toFixed(2)) };
  });

  const formatXAxis = useCallback((dateString, firstDate, lastDate) => {
    const date = new Date(dateString);
    
    if (firstDate.getFullYear() !== lastDate.getFullYear()) {
      return format(date, 'dd/MM/yy');
    } else {
      return format(date, 'dd/MM');
    }
  }, []);

  const getCustomTicks = useCallback((data, maxTicks) => {
    if (data.length <= maxTicks) return data.map(d => d.date);

    const step = Math.max(1, Math.floor((data.length - 1) / (maxTicks - 1)));
    const ticks = [];

    for (let i = 0; i < data.length; i += step) {
      ticks.push(data[i].date);
    }

    if (ticks[0] !== data[0].date) {
      ticks[0] = data[0].date;
    }
    if (ticks[ticks.length - 1] !== data[data.length - 1].date) {
      ticks[ticks.length - 1] = data[data.length - 1].date;
    }

    return [...new Set(ticks)];
  }, []);

  const customTicks = useMemo(() => {
    let maxTicks;
    if (windowWidth < 600) maxTicks = 7;
    else if (windowWidth < 500) maxTicks = 6;
    else maxTicks = 8;

    return getCustomTicks(processedData, maxTicks);
  }, [processedData, windowWidth, getCustomTicks]);

  const maxAbsDifference = Math.max(...processedData.map(d => Math.abs(d.difference)));
  const padding = maxAbsDifference * 0.1;
  const yAxisDomain = [
    -maxAbsDifference - padding,
    maxAbsDifference + padding
  ];

  const normalizeValue = (value) => {
    return Math.abs(value) / maxAbsDifference;
  };

  const mapOpacity = (normalizedValue) => {
    return 0.5 + normalizedValue * 0.2;
  };

  const getBarColor = (value) => {
    const convertedGoalWeight = goalWeight;
    const currentWeight = convertWeight(data[data.length - 1].weight);
    const isLossMode = currentWeight > convertedGoalWeight;
    const normalizedValue = normalizeValue(value);
    const opacity = mapOpacity(normalizedValue);

    if (theme === 'light') {
      return isLossMode
        ? (value >= 0 ? `rgba(255, 99, 71, ${opacity})` : `rgba(144, 238, 144, ${opacity})`)
        : (value >= 0 ? `rgba(144, 238, 144, ${opacity})` : `rgba(255, 99, 71, ${opacity})`);
    } else {
      return isLossMode
        ? (value >= 0 ? `rgba(205, 92, 92, ${opacity})` : `rgba(50, 205, 50, ${opacity})`)
        : (value >= 0 ? `rgba(50, 205, 50, ${opacity})` : `rgba(205, 92, 92, ${opacity})`);
    }
  };

  const getTextColor = () => {
    return theme === 'light' ? '#8884d8' : '#61dafb';
  };

  const formatDifference = (value) => {
    return value > 0 ? `+${value.toFixed(2)}` : value.toFixed(2);
  };

  return (
    <div className={`bar-graph-wrapper ${newWeightAdded ? 'animate-graph' : ''}`}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart 
          data={processedData} 
          margin={{ top: 0, right: 20, left: -10, bottom: -10 }}
        >
          <CartesianGrid strokeDasharray="3 3" stroke={theme === 'light' ? "#ccc" : "#555"} />
          <XAxis
            dataKey="date"
            tickFormatter={(dateString) => formatXAxis(
              dateString, 
              new Date(processedData[0].date), 
              new Date(processedData[processedData.length - 1].date)
            )}
            style={{ fontSize: '12px', fill: theme === 'light' ? "#333" : "#fff" }}
            ticks={customTicks}
          />
          <YAxis
            domain={yAxisDomain}
            style={{ fontSize: '12px', fill: theme === 'light' ? "#333" : "#fff" }}
            tickFormatter={(value) => formatDifference(value)}
          />
          <Tooltip
            labelFormatter={(label) => format(new Date(label), 'dd-MM-yyyy')}
            formatter={(value, name) => {
              const textColor = getTextColor();
              if (name === 'difference') return [
                <span style={{ color: textColor }}>{`${formatDifference(value)} ${unit}`}</span>,
                <span style={{ color: textColor }}>{t.difference}</span>
              ];
              return [value, name];
            }}
            contentStyle={{
              backgroundColor: theme === 'light' ? 'white' : '#333',
              border: 'none',
              borderRadius: '12px',
              boxShadow: '0 2px 10px rgba(0,0,0,0.2)'
            }}
            cursor={{ fill: theme === 'light' ? 'rgba(0,0,0,0.1)' : 'rgba(255,255,255,0.1)', radius: [10, 10, 0, 0] }}
          />
          <Bar
            dataKey="difference"
            radius={[10, 10, 0, 0]}
            isAnimationActive={animateGraphs}
            animationDuration={500}
          >
            {processedData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={getBarColor(entry.difference)} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default BarGraph;