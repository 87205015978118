import React, { useEffect, useRef } from 'react';

const CongratulationsPopup = ({ show, message, theme, t, onClose }) => {
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (show) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [show, onClose]);

  if (!show) return null;

  return (
    <div className={`congratulations-overlay ${theme}`}>
      <div className={`congratulations-popup ${theme}`} ref={popupRef}>
        <div className="congratulations-content">
          <h2>{t.congratulationsHeader}</h2>
          <p>{message}</p>
        </div>
      </div>
    </div>
  );
};

export default CongratulationsPopup;