import React, { useEffect, useRef } from 'react';

const Fireworks = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    class Particle {
      constructor(x, y, color, angle, speed, creationTime) {
        this.x = x;
        this.y = y;
        this.color = color;
        this.angle = angle;
        this.speed = speed;
        this.velocity = {
          x: Math.cos(angle) * speed,
          y: Math.sin(angle) * speed
        };
        this.radius = 2;
        this.creationTime = creationTime;
        this.opacity = 1;
      }

      draw() {
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2);
        ctx.fillStyle = this.color.replace(')', `, ${this.opacity})`).replace('rgb', 'rgba');
        ctx.fill();
      }

      update(currentTime) {
        this.x += this.velocity.x;
        this.y += this.velocity.y;
        this.velocity.y += 0.09;
        const age = currentTime - this.creationTime;
        if (age > 8000) {
          const fadeTime = Math.min(2000, 10000 - age);
          this.opacity = Math.max(0, fadeTime / 2000);
        }
      }
    }

    let particles = [];
    let startTime = Date.now();

    function createFirework(x, y) {
      const colors = ['rgb(255,0,0)', 'rgb(0,255,0)', 'rgb(0,0,255)', 'rgb(255,255,0)', 'rgb(255,0,255)', 'rgb(0,255,255)'];
      const particleCount = 50;
      const currentTime = Date.now();
      for (let i = 0; i < particleCount; i++) {
        const angle = (Math.PI * 2 * i) / particleCount;
        const speed = 1 + Math.random() * 3;
        particles.push(new Particle(
          x, 
          y, 
          colors[Math.floor(Math.random() * colors.length)],
          angle,
          speed,
          currentTime
        ));
      }
    }

    function animate() {
      const currentTime = Date.now();
      const elapsedTime = currentTime - startTime;

      if (elapsedTime > 10000) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        return;  // Stop the animation
      }

      let canvasOpacity = 1;
      if (elapsedTime > 8000) {
        canvasOpacity = Math.max(0, (10000 - elapsedTime) / 2000);
      }

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.globalAlpha = canvasOpacity;

      particles = particles.filter(particle => particle.opacity > 0);
      particles.forEach(particle => {
        particle.draw();
        particle.update(currentTime);
      });

      if (Math.random() < 0.05) {
        const x = canvas.width / 2 + (Math.random() - 0.5) * canvas.width * 0.5;
        const y = Math.random() * canvas.height / 2;
        createFirework(x, y);
      }

      requestAnimationFrame(animate);
    }

    animate();

    return () => {
      cancelAnimationFrame(animate);
    };
  }, []);

  return <canvas ref={canvasRef} className="fireworks" style={{ position: 'fixed', top: 0, left: 0, pointerEvents: 'none' }} />;
};

export default Fireworks;