import React from 'react';

function TimeRangeSelector({ timeRange, setTimeRange, t }) {
  const handleTimeRangeChange = (range) => {
    setTimeRange(range);
  };

  const ranges = [
    { key: 'week', label: t.week },
    { key: 'month', label: t.month },
    { key: '3-month', label: t.threeMonths },
    { key: '6-month', label: t.sixMonths },
    { key: '9-month', label: t.nineMonths },
    { key: 'year', label: t.year },
    { key: 'all-time', label: t.allTime }
  ];

  return (
    <div className="time-range-selector-container">
      {ranges.map((range) => (
        <button
          key={range.key}
          className={`time-range-button ${timeRange === range.key ? 'active' : ''}`}
          onClick={() => handleTimeRangeChange(range.key)}
        >
          {range.label}
        </button>
      ))}
    </div>
  );
}

export default TimeRangeSelector;