import React, { useState, useEffect, useCallback, useRef } from 'react';
import WeightInput from './components/WeightInput';
import WeightGraph from './components/WeightGraph';
import BarGraph from './components/BarGraph';
import TimeRangeSelector from './components/TimeRangeSelector';
import ThemeToggle from './components/ThemeToggle';
import SaveLoadData from './components/SaveLoadData';
import LanguageSelector from './components/LanguageSelector';
import ConfirmationModal from './components/ConfirmationModal';
import CongratulationsPopup from './components/CongratulationsPopup';
import WeightConflictPopup from './components/WeightConflictPopup';
import Fireworks from './components/Fireworks';
import Skeleton from './components/Skeleton';
import { subDays, format, parseISO, addDays, differenceInDays } from 'date-fns';

import { 
  enUS, ar, be, bn, cs, da, de, el, es, et, fi, fr, gu, hr, it, ja, 
  ka, kk, ko, lt, lv, nl, pl, pt, ro, ru, sk, sr, sv, ta, tr, uk, 
  vi, zhCN, he, id, te, th
} from 'date-fns/locale';

import translations from './translations';
import './App.css';

function App() {
  const getDateLocale = (lang) => {
    switch (lang) {
      case 'en': return enUS;
      case 'ur': return ar;
      case 'be': return be;
      case 'bn': return bn;
      case 'cs': return cs;
      case 'da': return da;
      case 'de': return de;
      case 'el': return el;
      case 'es': return es;
      case 'et': return et;
      case 'fi': return fi;
      case 'fr': return fr;
      case 'gu': return gu;
      case 'hr': return hr;
      case 'id': return id;
      case 'it': return it;
      case 'ja': return ja;
      case 'ka': return ka;
      case 'kk': return kk;
      case 'ko': return ko;
      case 'lt': return lt;
      case 'lv': return lv;
      case 'nl': return nl;
      case 'pl': return pl;
      case 'pt': return pt;
      case 'ro': return ro;
      case 'ru': return ru;
      case 'sk': return sk;
      case 'sr': return sr;
      case 'sv': return sv;
      case 'ta': return ta;
      case 'te': return te;
      case 'th': return th;
      case 'tr': return tr;
      case 'uk': return uk;
      case 'vi': return vi;
      case 'zh': return zhCN;
      case 'he': return he;
      default: return enUS;
    }
  };

  const [weights, setWeights] = useState([]);
  const [timeRange, setTimeRange] = useState(localStorage.getItem('timeRange') || 'week');
  const [goalWeight, setGoalWeight] = useState(() => {
    const stored = localStorage.getItem('goalWeight');
    return stored ? parseFloat(stored) : null;
  });
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  const [prediction, setPrediction] = useState(null);
  const [language, setLanguage] = useState(() => {
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) return storedLanguage;

    const browserLang = navigator.language.split('-')[0];
    return Object.keys(translations).includes(browserLang) ? browserLang : 'en';
  });

  const [unit, setUnit] = useState(localStorage.getItem('unit') || 'kg');
  const [animateGraphs, setAnimateGraphs] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newWeightAdded, setNewWeightAdded] = useState(false);
  const [showCongratulations, setShowCongratulations] = useState(false);
  const [congratsMessage, setCongratsMessage] = useState('');
  const [showFireworks, setShowFireworks] = useState(false);
  const [showHelpTips, setShowHelpTips] = useState(true);
  const [dateLocale, setDateLocale] = useState(getDateLocale(language));
  const goalRef = useRef(null);
  const [weightConflict, setWeightConflict] = useState(null);
  const [isWeightConflictModalOpen, setIsWeightConflictModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isFirstEntry, setIsFirstEntry] = useState(() => {
    return localStorage.getItem('isFirstEntry') !== 'false';
  });

  const interpolateData = useCallback((data) => {
    if (data.length < 2) return data;
  
    const interpolatedData = [];
    for (let i = 0; i < data.length - 1; i++) {
      interpolatedData.push(data[i]);
  
      const currentDate = parseISO(data[i].date);
      const nextDate = parseISO(data[i + 1].date);
  
      let diffDays = differenceInDays(nextDate, currentDate);
      if (diffDays > 1) {
        for (let j = 1; j < diffDays; j++) {
          const interpolatedDate = addDays(currentDate, j);
          const interpolatedWeight = data[i].weight + (data[i + 1].weight - data[i].weight) * (j / diffDays);
          interpolatedData.push({
            date: format(interpolatedDate, 'yyyy-MM-dd'),
            weight: Number(interpolatedWeight.toFixed(2))
          });
        }
      }
    }
    interpolatedData.push(data[data.length - 1]);
    return interpolatedData;
  }, []);

  useEffect(() => {
    const loadData = async () => {
      // Simulate loading time
      await new Promise(resolve => setTimeout(resolve, 500));
      
      const storedWeights = JSON.parse(localStorage.getItem('weights') || '[]');
      const interpolatedWeights = interpolateData(storedWeights);
      setWeights(interpolatedWeights);
      document.body.className = theme;
      calculatePrediction(storedWeights, goalWeight, unit);
      localStorage.setItem('language', language);
      localStorage.setItem('unit', unit);
      setShowHelpTips(storedWeights.length === 0);
      setDateLocale(getDateLocale(language));
      
      

      setIsLoading(false);
    };

    loadData();
  }, [theme, goalWeight, language, unit, interpolateData]);

  useEffect(() => {
    const storedUnit = localStorage.getItem('unit') || 'kg';
    setUnit(storedUnit);
  }, []);

  useEffect(() => {
    calculatePrediction(weights, goalWeight, unit);
  }, [weights, goalWeight, unit]);

  const t = translations[language];

  const handleCongratulations = useCallback((newWeight) => {
    if (weights.length === 1) {
      setCongratsMessage(t.firstEntryMessage || "It's your first point! Don't forget to add next one tomorrow!");
      setShowCongratulations(true);
      setTimeout(() => setShowCongratulations(false), 7000);
    } else if (goalWeight !== null) {
      const lastWeight = weights[weights.length - 1].weight;
      
      const kgToGoal = Math.abs(goalWeight - lastWeight);
      const newKgToGoal = Math.abs(goalWeight - newWeight);
      
      if (Math.floor(kgToGoal) > Math.floor(newKgToGoal)) {
        const randomIndex = Math.floor(Math.random() * 5) + 1;
        setCongratsMessage(t[`congrats${randomIndex}`]);
        setShowCongratulations(true);
        setTimeout(() => setShowCongratulations(false), 3000);
      }
  
      const isWeightGain = weights[0].weight < goalWeight;
      if ((isWeightGain && newWeight >= goalWeight) || (!isWeightGain && newWeight <= goalWeight)) {
        setShowFireworks(true);
        setCongratsMessage(t.congratsGoal);
        setShowCongratulations(true);
        setTimeout(() => {
          setShowFireworks(false);
          setShowCongratulations(false);
        }, 10000);
      }
    }
  }, [weights, goalWeight, t, setCongratsMessage, setShowCongratulations, setShowFireworks]);

  const resolveWeightConflict = (resolution) => {
    if (!weightConflict) return;
  
    let updatedWeights;
    let newWeight;
    switch (resolution) {
      case 'average':
        newWeight = (weightConflict.existingWeight + weightConflict.newWeight) / 2;
        updatedWeights = weights.map((w, index) => 
          index === weightConflict.index ? { ...w, weight: Number(newWeight.toFixed(2)) } : w
        );
        break;
      case 'new':
        newWeight = weightConflict.newWeight;
        updatedWeights = weights.map((w, index) => 
          index === weightConflict.index ? { ...w, weight: Number(newWeight.toFixed(2)) } : w
        );
        break;
      case 'keep':
        updatedWeights = [...weights];
        newWeight = weightConflict.existingWeight;
        break;
      default:
        updatedWeights = [...weights];
        newWeight = weightConflict.existingWeight;
        break;
    }
  
    const interpolatedWeights = interpolateData(updatedWeights);
    setWeights(interpolatedWeights);
    localStorage.setItem('weights', JSON.stringify(interpolatedWeights));
  
    setNewWeightAdded(true);
    setTimeout(() => setNewWeightAdded(false), 1000);
    setShowHelpTips(false);
  
    setWeightConflict(null);
    setIsWeightConflictModalOpen(false);
  
    // Check if this is the first entry
    if (interpolatedWeights.length === 1) {
      setCongratsMessage(t.firstEntryMessage || "It's your first point! Don't forget to add the next one tomorrow!");
      setShowCongratulations(true);
      setTimeout(() => setShowCongratulations(false), 7000);
    } else {
      handleCongratulations(newWeight);
    }
  };

  const addWeight = useCallback((weight, date) => {
  const formattedDate = format(new Date(date), 'yyyy-MM-dd');
  const newWeight = parseFloat(weight);

  const existingIndex = weights.findIndex(w => w.date === formattedDate);

  if (existingIndex !== -1) {
    setWeightConflict({
      existingWeight: weights[existingIndex].weight,
      newWeight,
      date: formattedDate,
      index: existingIndex
    });
    setIsWeightConflictModalOpen(true);
  } else {
    const updatedWeights = [...weights, { date: formattedDate, weight: Number(newWeight.toFixed(2)) }]
      .sort((a, b) => new Date(a.date) - new Date(b.date));

    const interpolatedWeights = interpolateData(updatedWeights);
    setWeights(interpolatedWeights);
    localStorage.setItem('weights', JSON.stringify(interpolatedWeights));
    
    setNewWeightAdded(true);
    setTimeout(() => setNewWeightAdded(false), 1000);
    setShowHelpTips(false);

    if (isFirstEntry) {
      setCongratsMessage(t.firstEntryMessage || "It's your first point! Don't forget to add the next one tomorrow!");
      setShowCongratulations(true);
      setTimeout(() => setShowCongratulations(false), 7000);
      setIsFirstEntry(false);
      localStorage.setItem('isFirstEntry', 'false');
    } else {
      handleCongratulations(newWeight);
    }
  }
}, [weights, interpolateData, handleCongratulations, setWeightConflict, setIsWeightConflictModalOpen, setWeights, setNewWeightAdded, setShowHelpTips, t, isFirstEntry]);


  const handleDataLoaded = (newWeights, newGoalWeight) => {
    const interpolatedWeights = interpolateData(newWeights);
    setWeights(interpolatedWeights);
    localStorage.setItem('weights', JSON.stringify(interpolatedWeights));
    
    if (newGoalWeight !== null && newGoalWeight !== undefined) {
      setGoalWeight(newGoalWeight);
      localStorage.setItem('goalWeight', newGoalWeight.toString());
    }
  
    setNewWeightAdded(true);
    setTimeout(() => setNewWeightAdded(false), 100);
    setShowHelpTips(newWeights.length === 0);
  
    calculatePrediction(interpolatedWeights, newGoalWeight || goalWeight, unit);
  };

  const deleteWeight = (date) => {
    const updatedWeights = weights.filter(w => w.date !== date);
    const interpolatedWeights = interpolateData(updatedWeights);
    setWeights(interpolatedWeights);
    localStorage.setItem('weights', JSON.stringify(interpolatedWeights));
  };

  const clearAllWeights = () => {
    setWeights([]);
    setPrediction(null);
    localStorage.removeItem('weights');
    setIsFirstEntry(true);
    localStorage.setItem('isFirstEntry', 'true');
    
    setNewWeightAdded(true);
    setTimeout(() => setNewWeightAdded(false), 100);
    setShowHelpTips(true);
  };

  const keepLastMonth = () => {
    const sortedWeights = [...weights].sort((a, b) => new Date(b.date) - new Date(a.date));
    const updatedWeights = sortedWeights.slice(0, 30);
    const interpolatedWeights = interpolateData(updatedWeights.reverse());
    setWeights(interpolatedWeights);
    localStorage.setItem('weights', JSON.stringify(interpolatedWeights));
    setIsModalOpen(false);
  };
  
  const keepLastWeek = () => {
    const sortedWeights = [...weights].sort((a, b) => new Date(b.date) - new Date(a.date));
    const updatedWeights = sortedWeights.slice(0, 7);
    const interpolatedWeights = interpolateData(updatedWeights.reverse());
    setWeights(interpolatedWeights);
    localStorage.setItem('weights', JSON.stringify(interpolatedWeights));
    setIsModalOpen(false);
  };

  const handleGoalWeightChange = (e) => {
    const value = e.target.value.trim();
    if (value === '') {
      setGoalWeight(null);
      localStorage.removeItem('goalWeight');
    } else {
      const numValue = parseFloat(value);
      if (!isNaN(numValue) && numValue > 0) {
        setGoalWeight(numValue);
        localStorage.setItem('goalWeight', numValue.toString());
      }
    }
  };

  const calculateMovingAverage = (data, days) => {
    return data.map((entry, index) => {
      const start = Math.max(0, index - days + 1);
      const slice = data.slice(start, index + 1);
      const sum = slice.reduce((acc, curr) => acc + curr.weight, 0);
      return {
        ...entry,
        movingAverage: Number((sum / slice.length).toFixed(2))
      };
    });
  };

  const getFilteredData = () => {
    if (weights.length === 0) return [];
    
    const latestDate = new Date(weights[weights.length - 1].date);
    let startDate;

    switch (timeRange) {
      case 'week':
        startDate = subDays(latestDate, 6);
        break;
      case 'month':
        startDate = subDays(latestDate, 29);
        break;
      case '3-month':
        startDate = subDays(latestDate, 89);
        break;
        case '6-month':
          startDate = subDays(latestDate, 179);
          break;
        case '9-month':
          startDate = subDays(latestDate, 269);
          break;
        case 'year':
          startDate = subDays(latestDate, 364);
          break;
        case 'all-time':
          return weights;
        default:
          startDate = subDays(latestDate, 6);
      }
  
      const filteredWeights = weights.filter(entry => new Date(entry.date) >= startDate);
      return calculateMovingAverage(filteredWeights, 7);
    };
  
    const calculatePrediction = (data, goalWeight, unit) => {
      if (data.length < 2 || !goalWeight) {
        setPrediction(null);
        return;
      }
    
      const goalWeightKg = unit === 'lbs' ? goalWeight / 2.20462 : goalWeight;
      const recentData = data.map(d => ({
        ...d,
        weight: d.weight
      }));
    
      const actualWeights = recentData.map(d => d.weight);
      const dates = recentData.map(d => new Date(d.date).getTime());
      const n = actualWeights.length;
    
      const sumX = dates.reduce((acc, date) => acc + date, 0);
      const sumY = actualWeights.reduce((acc, weight) => acc + weight, 0);
      const sumXY = dates.reduce((acc, date, i) => acc + date * actualWeights[i], 0);
      const sumXX = dates.reduce((acc, date) => acc + date * date, 0);
    
      const slope = (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX);
      const intercept = (sumY - slope * sumX) / n;
    
      const latestDate = dates[n - 1];
      const latestWeight = actualWeights[n - 1];
    
      const goalTime = (goalWeightKg - intercept) / slope;
      let daysToGoal = Math.ceil((goalTime - latestDate) / (24 * 60 * 60 * 1000));
    
      // Check if goal is reached
      const isGoalReached = (latestWeight <= goalWeightKg && slope <= 0) || (latestWeight >= goalWeightKg && slope >= 0);
      if (isGoalReached) {
        daysToGoal = 0;
      }
    
      const weightIn30Days = slope * (latestDate + 30 * 24 * 60 * 60 * 1000) + intercept;
      const weightDifference = data[data.length - 1].weight - data[0].weight;
      const formattedWeightDifference = weightDifference >= 0 
        ? `+${weightDifference.toFixed(2)}` 
        : `-${Math.abs(weightDifference).toFixed(2)}`;
    
      const isWeightGain = latestWeight < goalWeightKg;
    
      let percentageLeft;
      if (isWeightGain) {
        const totalWeightToGain = goalWeightKg - data[0].weight;
        const weightGainedSoFar = latestWeight - data[0].weight;
        percentageLeft = ((totalWeightToGain - weightGainedSoFar) / totalWeightToGain) * 100;
      } else {
        const totalWeightToLose = data[0].weight - goalWeightKg;
        const weightLostSoFar = data[0].weight - latestWeight;
        percentageLeft = ((totalWeightToLose - weightLostSoFar) / totalWeightToLose) * 100;
      }
    
      const totalDays = (new Date(data[data.length - 1].date).getTime() - new Date(data[0].date).getTime()) / (24 * 60 * 60 * 1000);
      const averageDaily = weightDifference / totalDays;
      const averageWeekly = averageDaily * 7;
    
      const formattedAverageDaily = averageDaily.toFixed(2);
      const formattedAverageWeekly = averageWeekly.toFixed(2);
    
      setPrediction({
        daysToGoal: Math.max(0, daysToGoal),
        weightIn30Days: weightIn30Days.toFixed(2),
        percentageLeft: Math.max(0, Math.min(100, percentageLeft.toFixed(2))),
        weightDifference: formattedWeightDifference,
        averageDaily: formattedAverageDaily,
        averageWeekly: formattedAverageWeekly,
        isWeightGain: isWeightGain,
        isGoalReached: isGoalReached
      });
    };
    
    const toggleTheme = () => {
      const newTheme = theme === 'light' ? 'dark' : 'light';
      setTheme(newTheme);
      localStorage.setItem('theme', newTheme);
      document.body.className = newTheme;
    };
  
    const handleTimeRangeChange = (newTimeRange) => {
      setTimeRange(newTimeRange);
      localStorage.setItem('timeRange', newTimeRange);
      setAnimateGraphs(true);
      setTimeout(() => setAnimateGraphs(false), 1000);
    };
  
    const handleClearAllWeights = () => {
      setIsModalOpen(true);
    };
    
    const confirmClearAllWeights = () => {
      clearAllWeights();
      setIsModalOpen(false);
    };
  
    const convertWeight = (weight) => {
      if (weight === null || isNaN(weight)) return '';
      const numWeight = parseFloat(weight);
      return unit === 'lbs' ? (numWeight * 2.20462).toFixed(2) : numWeight.toFixed(2);
    };
  
    const handleUnitChange = (newUnit) => {
      setUnit(newUnit);
      setGoalWeight((prevGoalWeight) => {
        if (prevGoalWeight === null) return null;
        const convertedWeight = newUnit === 'lbs' ? (prevGoalWeight * 2.20462).toFixed(2) : (prevGoalWeight / 2.20462).toFixed(2);
        localStorage.setItem('goalWeight', convertedWeight.toString());
        return parseFloat(convertedWeight);
      });
    };
  
    const filteredData = getFilteredData();
    const dataWithMovingAverage = calculateMovingAverage(filteredData, 7);
    const lastWeight = weights.length > 0 ? weights[weights.length - 1].weight : null;
  
    const weightLabel = weights.length > 0 ? t.currentGoalWeight : t.goalWeight;
  
    const totalDays = weights.length > 1 
      ? differenceInDays(new Date(weights[weights.length - 1].date), new Date(weights[0].date))
      : 0;
  
    const handleFocus = () => {
      goalRef.current.select();
    };
    
    if (isLoading) {
      return <Skeleton />;
    }
  
    return (
      <div className={`App ${theme} ${isLoading ? '' : 'loaded'}`}>
        <div className="header ui-group">
          <div className="header-top">
            <div className="logo-title">
              <p className="made-by">
                made by Artur Baranov
                <a href="https://www.youtube.com/@artur.baranov" target="_blank" rel="noopener noreferrer">
                  <img src="https://cdn.jsdelivr.net/npm/simple-icons@v9/icons/youtube.svg" alt="YouTube" className="social-icon" />
                </a>
                <a href="https://www.linkedin.com/in/artur-baranov-42a3b048/" target="_blank" rel="noopener noreferrer">
                  <img src="https://cdn.jsdelivr.net/npm/simple-icons@v9/icons/linkedin.svg" alt="LinkedIn" className="social-icon" />
                </a>
                <a href="https://x.com/ArchiZT" target="_blank" rel="noopener noreferrer">
                  <img src="https://cdn.jsdelivr.net/npm/simple-icons@v9/icons/x.svg" alt="X (Twitter)" className="social-icon" />
                </a>
              </p>
              <div className="logo-title-inner">
                <img src={theme === 'light' ? '/weight_icon_light.svg' : '/weight_icon_dark.svg'} alt="Weight Tracker Icon" className="app-icon" />
                <div>
                  <h1>Scale Story</h1>
                  <h3>{t.title}</h3>
                </div>
              </div>
            </div>
            <div className="header-controls">
              <SaveLoadData 
                weights={weights} 
                handleDataLoaded={handleDataLoaded}
                goalWeight={goalWeight}
                t={translations[language]} 
              />
              <ThemeToggle theme={theme} toggleTheme={toggleTheme} />
              <LanguageSelector 
                language={language} 
                setLanguage={(newLang) => {
                  setLanguage(newLang);
                  setDateLocale(getDateLocale(newLang));
                }}
                theme={theme}
                unit={unit}
                setUnit={handleUnitChange}
              />
            </div>
          </div>
        </div>
        <div className="input-container ui-group">
          <div className="weight-input-wrapper">
            <WeightInput 
              addWeight={addWeight} 
              lastWeight={lastWeight} 
              t={t} 
              unit={unit} 
              newWeightAdded={newWeightAdded} 
              theme={theme} 
              goalWeight={goalWeight}
              dateLocale={dateLocale}
            />
            {showHelpTips && (
              <div className="help-tip">
                {"▲ " + (t.enterCurrentWeightHere || "Enter your CURRENT weight here.")}
              </div>
            )}
          </div>
          <div className="goal-weight-container">
            <label htmlFor="goalWeight">{weightLabel}</label>
            {weights.length > 0 && (
              <input
                type="number"
                value={convertWeight(lastWeight)}
                readOnly
                className="current-weight-input number-input"
                aria-label={t.currentWeight}
              />
            )}
            <h2>&#9654;</h2>
            <div className="goal-weight-input-container">
              <input
                id="goalWeight"
                type="number"
                ref={goalRef}
                value={goalWeight || ''}
                onChange={handleGoalWeightChange}
                onFocus={handleFocus}
                className="goal-weight-input number-input"
                step="0.1"
                min="0"
                placeholder={t.enterGoalWeight}
                aria-label={t.goalWeight}
              />
              <span className="goal-trophy">🏆</span>
            </div>
            {showHelpTips && (
              <div className="help-tip">
                {"▲ " + (t.enterGoalWeightHere || "Enter your GOAL weight here, then click Add Weight.")}
              </div>
            )}
            {prediction && (
              <div className="goal-progress-bar-container">
                <div 
                  className="goal-progress-bar" 
                  style={{width: `${100 - prediction.percentageLeft}%`}}
                ></div>
              </div>
            )}
          </div>
        </div>
        {weights.length > 0 && (
          <>
            <div className="time-range-container">
              <TimeRangeSelector timeRange={timeRange} setTimeRange={handleTimeRangeChange} t={t} />
              <span className="total-days">{totalDays} {t.daysTotal}</span>
            </div>
            <div className={`graph-container ui-group ${newWeightAdded ? 'animate-graph' : ''}`}>
              <WeightGraph 
                data={dataWithMovingAverage} 
                timeRange={timeRange}
                deleteWeight={deleteWeight}
                goalWeight={goalWeight}
                theme={theme}
                t={t}
                animateGraphs={animateGraphs}
                unit={unit}
                newWeightAdded={newWeightAdded}
              />
            </div>
            <div className={`bar-graph-container ui-group ${newWeightAdded ? 'animate-graph' : ''}`}>
              <BarGraph 
                data={dataWithMovingAverage} 
                theme={theme}
                timeRange={timeRange}
                animateGraphs={animateGraphs}
                t={t}
                unit={unit}
                newWeightAdded={newWeightAdded}
                goalWeight={goalWeight}
              />
            </div>
            {prediction && (
              <div className={`prediction ${theme} ui-group`}>
                <div className="prediction-row">
                  <div className="prediction-item">
                    <span>{t.daysToGoal}</span>
                    <span>{prediction.daysToGoal} {t.days}</span>
                  </div>
                  <div className="prediction-item">
                    <span>{t.weightIn30Days}</span>
                    <span>{convertWeight(prediction.weightIn30Days)} {unit}</span>
                  </div>
                  <div className="prediction-item">
                    <span>{t.percentageLeft}</span>
                    <span>{prediction.percentageLeft}%</span>
                  </div>
                  <div className={`prediction-item ${prediction.isWeightGain ? 'weight-gain' : 'weight-loss'}`}>
                    <span>{prediction.isWeightGain ? t.gainWeight : t.lostWeight}</span>
                    <span className={prediction.weightDifference.startsWith('-') ? 'negative' : 'positive'}>
                      {convertWeight(prediction.weightDifference)} {unit}
                    </span>
                  </div>
                  <div className={`prediction-item ${prediction.isWeightGain ? 'weight-gain' : 'weight-loss'}`}>
                    <span>{t.averageDaily}</span>
                    <span className={parseFloat(prediction.averageDaily) < 0 ? 'negative' : 'positive'}>
                      {convertWeight(prediction.averageDaily)} {unit}
                    </span>
                  </div>
                  <div className={`prediction-item ${prediction.isWeightGain ? 'weight-gain' : 'weight-loss'}`}>
                    <span>{t.averageWeekly}</span>
                    <span className={parseFloat(prediction.averageWeekly) < 0 ? 'negative' : 'positive'}>
                      {convertWeight(prediction.averageWeekly)} {unit}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        {weights.length > 0 && (
          <button 
            onClick={handleClearAllWeights} 
            className="clear-all-button ui-group" 
            title={t.clearAll}
          >
            {t.clearAll}  —  {weights.length}
          </button>
        )}
        <ConfirmationModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onConfirm={confirmClearAllWeights}
          onKeepMonth={keepLastMonth}
          onKeepWeek={keepLastWeek}
          theme={theme}
          t={t}
        />
        <WeightConflictPopup 
        isOpen={isWeightConflictModalOpen}
        onClose={() => setIsWeightConflictModalOpen(false)}
        conflict={weightConflict}
        onResolve={resolveWeightConflict}
        theme={theme}
        t={t}
      />
      <CongratulationsPopup 
        show={showCongratulations} 
        message={congratsMessage} 
        theme={theme}
        t={t}
        onClose={() => setShowCongratulations(false)}
      />
      {showFireworks && <Fireworks />}
    </div>
  );
}

export default App;