const translations = {
  en: {
    title: "Weight Tracker",
    addWeight: "Add Weight",
    goalWeight: "Goal Weight",
    currentWeight: "Current Weight",
    clearAll: "Clear All Data",
    daysToGoal: "Days to goal:",
    weightIn30Days: "Weight in 30 days:",
    percentageLeft: "Percentage left:",
    days: "days",
    save: "SAVE",
    load: "LOAD",
    week: "Week",
    month: "Month",
    threeMonths: "3 Months",
    sixMonths: "6 Months",
    nineMonths: "9 Months",
    year: "Year",
    allTime: "All Time",
    date: 'Date',
    weight: 'Weight',
    movingAverage: 'Moving Average',
    delete: 'Delete',
    points: 'POINTS',
    lostWeight: "Weight lost:",
    gainWeight: "Weight gained:",
    difference: "Difference",
    cleanPoints: "Clean Redundant Points",
    confirmClearData: "Are you sure you want to clear all data?",
    yes: "Yes, delete all",
    no: "No, keep all",
    currentGoalWeight: "Current / Goal Weight",
    daysTotal: "days total",
    averageDaily: "Average Daily:",
    averageWeekly: "Average Weekly:",
    keepMonth: "Keep 1-Month",
    keepWeek: "Keep 1-Week",
    congrats1: "Fantastic progress! Keep it up!",
    congrats2: "You're doing great! Another milestone achieved!",
    congrats3: "Wow! You're on fire! Keep pushing!",
    congrats4: "Amazing work! You're getting closer to your goal!",
    congrats5: "Incredible job! Your hard work is paying off!",
    congratsGoal: "You did it! Great job!",
    congratulationsHeader: "Congratulations!",
    firstEntryMessage: "It's your first point! Don't forget to add next one tomorrow!",
    enterGoalWeightHere: "Enter your GOAL weight here, then click Add Weight.",
    enterCurrentWeightHere: "Enter your CURRENT weight here.",
    weightConflictTitle: "Weight Conflict",
    weightConflictMessage: "A weight already exists for this date. What would you like to do?",
    existingWeight: "Existing weight",
    newWeight: "New weight",
    average: "Average weights",
    addNew: "Add as new entry",
    cancel: "Cancel"
  },
  sw: {
    title: "Kifuatiliaji cha Uzito",
    addWeight: "Ongeza Uzito",
    goalWeight: "Uzito Lengo",
    currentWeight: "Uzito wa Sasa",
    clearAll: "Futa Data Zote",
    daysToGoal: "Siku hadi lengo:",
    weightIn30Days: "Uzito baada ya siku 30:",
    percentageLeft: "Asilimia iliyobaki:",
    days: "siku",
    save: "HIFADHI",
    load: "PAKIA",
    week: "Wiki",
    month: "Mwezi",
    threeMonths: "Miezi 3",
    sixMonths: "Miezi 6",
    nineMonths: "Miezi 9",
    year: "Mwaka",
    allTime: "Wakati Wote",
    date: 'Tarehe',
    weight: 'Uzito',
    movingAverage: 'Wastani Unaobadilika',
    delete: 'Futa',
    points: 'POINTI',
    lostWeight: "Uzito uliopungua:",
    gainWeight: "Uzito ulioongezeka:",
    difference: "Tofauti",
    cleanPoints: "Safisha Pointi Zinazojirudia",
    confirmClearData: "Una uhakika unataka kufuta data zote?",
    yes: "Ndiyo, futa zote",
    no: "Hapana, zibakishe zote",
    currentGoalWeight: "Uzito wa Sasa / Lengo",
    daysTotal: "jumla ya siku",
    averageDaily: "Wastani wa Kila Siku:",
    averageWeekly: "Wastani wa Kila Wiki:",
    keepMonth: "Bakiza Mwezi-1",
    keepWeek: "Bakiza Wiki-1",
    congrats1: "Maendeleo ya ajabu! Endelea hivyo!",
    congrats2: "Unafanya vizuri sana! Umefika hatua nyingine!",
    congrats3: "Ala! Unafanya vizuri sana! Endelea kujitahidi!",
    congrats4: "Kazi nzuri! Unakaribia lengo lako!",
    congrats5: "Kazi ya kipekee! Juhudi zako zinazaa matunda!",
    congratsGoal: "Umefanikiwa! Kazi nzuri!",
    congratulationsHeader: "Hongera!",
    firstEntryMessage: "Hii ni pointi yako ya kwanza! Usisahau kuongeza nyingine kesho!",
    enterGoalWeightHere: "Ingiza uzito wako wa LENGO hapa, kisha bonyeza Ongeza Uzito.",
    enterCurrentWeightHere: "Ingiza uzito wako wa SASA hapa.",
    weightConflictTitle: "Mgogoro wa Uzito",
    weightConflictMessage: "Uzito tayari upo kwa tarehe hii. Ungependa kufanya nini?",
    existingWeight: "Uzito uliopo",
    newWeight: "Uzito mpya",
    average: "Wastani wa uzito",
    addNew: "Ongeza kama ingizo jipya",
    cancel: "Ghairi"
},
id: {
  title: "Pelacak Berat Badan",
  addWeight: "Tambah Berat",
  goalWeight: "Berat Tujuan",
  currentWeight: "Berat Saat Ini",
  clearAll: "Hapus Semua Data",
  daysToGoal: "Hari menuju tujuan:",
  weightIn30Days: "Berat dalam 30 hari:",
  percentageLeft: "Persentase tersisa:",
  days: "hari",
  save: "SIMPAN",
  load: "MUAT",
  week: "Minggu",
  month: "Bulan",
  threeMonths: "3 Bulan",
  sixMonths: "6 Bulan",
  nineMonths: "9 Bulan",
  year: "Tahun",
  allTime: "Sepanjang Waktu",
  date: 'Tanggal',
  weight: 'Berat',
  movingAverage: 'Rata-rata Bergerak',
  delete: 'Hapus',
  points: 'POIN',
  lostWeight: "Berat yang hilang:",
  gainWeight: "Berat yang bertambah:",
  difference: "Perbedaan",
  cleanPoints: "Bersihkan Poin Berlebihan",
  confirmClearData: "Apakah Anda yakin ingin menghapus semua data?",
  yes: "Ya, hapus semua",
  no: "Tidak, simpan semua",
  currentGoalWeight: "Berat Saat Ini / Tujuan",
  daysTotal: "total hari",
  averageDaily: "Rata-rata Harian:",
  averageWeekly: "Rata-rata Mingguan:",
  keepMonth: "Simpan 1-Bulan",
  keepWeek: "Simpan 1-Minggu",
  congrats1: "Kemajuan fantastis! Pertahankan!",
  congrats2: "Anda melakukannya dengan baik! Pencapaian baru lagi!",
  congrats3: "Wow! Anda luar biasa! Terus berjuang!",
  congrats4: "Kerja bagus! Anda semakin dekat dengan tujuan Anda!",
  congrats5: "Kerja luar biasa! Kerja keras Anda membuahkan hasil!",
  congratsGoal: "Anda berhasil! Kerja bagus!",
  congratulationsHeader: "Selamat!",
  firstEntryMessage: "Ini adalah poin pertama Anda! Jangan lupa untuk menambahkan yang berikutnya besok!",
  enterGoalWeightHere: "Masukkan berat TUJUAN Anda di sini, lalu klik Tambah Berat.",
  enterCurrentWeightHere: "Masukkan berat SAAT INI Anda di sini.",
  weightConflictTitle: "Konflik Berat",
    weightConflictMessage: "Berat sudah ada untuk tanggal ini. Apa yang ingin Anda lakukan?",
    existingWeight: "Berat yang ada",
    newWeight: "Berat baru",
    average: "Rata-rata berat",
    addNew: "Tambahkan sebagai entri baru",
    cancel: "Batal"
},
pa: {
  title: "ਭਾਰ ਟਰੈਕਰ",
  addWeight: "ਭਾਰ ਜੋੜੋ",
  goalWeight: "ਟੀਚਾ ਭਾਰ",
  currentWeight: "ਮੌਜੂਦਾ ਭਾਰ",
  clearAll: "ਸਾਰਾ ਡਾਟਾ ਸਾਫ਼ ਕਰੋ",
  daysToGoal: "ਟੀਚੇ ਤੱਕ ਦਿਨ:",
  weightIn30Days: "30 ਦਿਨਾਂ ਵਿੱਚ ਭਾਰ:",
  percentageLeft: "ਬਾਕੀ ਪ੍ਰਤੀਸ਼ਤ:",
  days: "ਦਿਨ",
  save: "ਸੰਭਾਲੋ",
  load: "ਲੋਡ ਕਰੋ",
  week: "ਹਫ਼ਤਾ",
  month: "ਮਹੀਨਾ",
  threeMonths: "3 ਮਹੀਨੇ",
  sixMonths: "6 ਮਹੀਨੇ",
  nineMonths: "9 ਮਹੀਨੇ",
  year: "ਸਾਲ",
  allTime: "ਸਾਰਾ ਸਮਾਂ",
  date: 'ਤਾਰੀਖ',
  weight: 'ਭਾਰ',
  movingAverage: 'ਚੱਲਦਾ ਔਸਤ',
  delete: 'ਮਿਟਾਓ',
  points: 'ਅੰਕ',
  lostWeight: "ਘਟਿਆ ਭਾਰ:",
  gainWeight: "ਵਧਿਆ ਭਾਰ:",
  difference: "ਅੰਤਰ",
  cleanPoints: "ਵਾਧੂ ਅੰਕ ਸਾਫ਼ ਕਰੋ",
  confirmClearData: "ਕੀ ਤੁਸੀਂ ਯਕੀਨੀ ਤੌਰ 'ਤੇ ਸਾਰਾ ਡਾਟਾ ਸਾਫ਼ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ?",
  yes: "ਹਾਂ, ਸਭ ਮਿਟਾਓ",
  no: "ਨਹੀਂ, ਸਭ ਰੱਖੋ",
  currentGoalWeight: "ਮੌਜੂਦਾ / ਟੀਚਾ ਭਾਰ",
  daysTotal: "ਕੁੱਲ ਦਿਨ",
  averageDaily: "ਰੋਜ਼ਾਨਾ ਔਸਤ:",
  averageWeekly: "ਹਫਤਾਵਾਰੀ ਔਸਤ:",
  keepMonth: "1-ਮਹੀਨਾ ਰੱਖੋ",
  keepWeek: "1-ਹਫ਼ਤਾ ਰੱਖੋ",
  congrats1: "ਸ਼ਾਨਦਾਰ ਤਰੱਕੀ! ਇਸੇ ਤਰ੍ਹਾਂ ਜਾਰੀ ਰੱਖੋ!",
  congrats2: "ਤੁਸੀਂ ਵਧੀਆ ਕਰ ਰਹੇ ਹੋ! ਇੱਕ ਹੋਰ ਮੀਲ ਪੱਥਰ ਹਾਸਲ ਕੀਤਾ!",
  congrats3: "ਵਾਹ! ਤੁਸੀਂ ਬਹੁਤ ਵਧੀਆ ਕਰ ਰਹੇ ਹੋ! ਜਾਰੀ ਰੱਖੋ!",
  congrats4: "ਸ਼ਾਨਦਾਰ ਕੰਮ! ਤੁਸੀਂ ਆਪਣੇ ਟੀਚੇ ਦੇ ਨੇੜੇ ਪਹੁੰਚ ਰਹੇ ਹੋ!",
  congrats5: "ਕਮਾਲ ਦਾ ਕੰਮ! ਤੁਹਾਡੀ ਮਿਹਨਤ ਰੰਗ ਲਿਆ ਰਹੀ ਹੈ!",
  congratsGoal: "ਤੁਸੀਂ ਕਰ ਦਿਖਾਇਆ! ਸ਼ਾਨਦਾਰ ਕੰਮ!",
  congratulationsHeader: "ਵਧਾਈਆਂ!",
  firstEntryMessage: "ਇਹ ਤੁਹਾਡਾ ਪਹਿਲਾ ਅੰਕ ਹੈ! ਕੱਲ੍ਹ ਅਗਲਾ ਜੋੜਨਾ ਨਾ ਭੁੱਲਣਾ!",
  enterGoalWeightHere: "ਆਪਣਾ ਟੀਚਾ ਭਾਰ ਇੱਥੇ ਦਰਜ ਕਰੋ, ਫਿਰ ਭਾਰ ਜੋੜੋ 'ਤੇ ਕਲਿੱਕ ਕਰੋ।",
  enterCurrentWeightHere: "ਆਪਣਾ ਮੌਜੂਦਾ ਭਾਰ ਇੱਥੇ ਦਰਜ ਕਰੋ।",
  weightConflictTitle: "ਭਾਰ ਵਿਵਾਦ",
    weightConflictMessage: "ਇਸ ਤਾਰੀਖ ਲਈ ਪਹਿਲਾਂ ਹੀ ਇੱਕ ਭਾਰ ਮੌਜੂਦ ਹੈ। ਤੁਸੀਂ ਕੀ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ?",
    existingWeight: "ਮੌਜੂਦਾ ਭਾਰ",
    newWeight: "ਨਵਾਂ ਭਾਰ",
    average: "ਔਸਤ ਭਾਰ",
    addNew: "ਨਵੀਂ ਐਂਟਰੀ ਵਜੋਂ ਸ਼ਾਮਲ ਕਰੋ",
    cancel: "ਰੱਦ ਕਰੋ"
},
te: {
  title: "బరువు ట్రాకర్",
  addWeight: "బరువు జోడించు",
  goalWeight: "లక్ష్య బరువు",
  currentWeight: "ప్రస్తుత బరువు",
  clearAll: "అన్ని డేటాను తొలగించు",
  daysToGoal: "లక్ష్యానికి రోజులు:",
  weightIn30Days: "30 రోజుల్లో బరువు:",
  percentageLeft: "మిగిలిన శాతం:",
  days: "రోజులు",
  save: "సేవ్ చేయి",
  load: "లోడ్ చేయి",
  week: "వారం",
  month: "నెల",
  threeMonths: "3 నెలలు",
  sixMonths: "6 నెలలు",
  nineMonths: "9 నెలలు",
  year: "సంవత్సరం",
  allTime: "మొత్తం కాలం",
  date: 'తేదీ',
  weight: 'బరువు',
  movingAverage: 'చలించే సగటు',
  delete: 'తొలగించు',
  points: 'పాయింట్లు',
  lostWeight: "తగ్గిన బరువు:",
  gainWeight: "పెరిగిన బరువు:",
  difference: "తేడా",
  cleanPoints: "అనవసర పాయింట్లను తొలగించు",
  confirmClearData: "మీరు నిజంగా అన్ని డేటాను తొలగించాలనుకుంటున్నారా?",
    yes: "అవును, అన్నింటినీ తొలగించు",
    no: "లేదు, అన్నింటినీ ఉంచు",
    currentGoalWeight: "ప్రస్తుత / లక్ష్య బరువు",
    daysTotal: "మొత్తం రోజులు",
    averageDaily: "రోజువారీ సగటు:",
    averageWeekly: "వారపు సగటు:",
    keepMonth: "1-నెల ఉంచు",
    keepWeek: "1-వారం ఉంచు",
    congrats1: "అద్భుతమైన ప్రగతి! ఇలాగే కొనసాగించండి!",
    congrats2: "మీరు చాలా బాగా చేస్తున్నారు! మరో మైలురాయి సాధించారు!",
    congrats3: "వావ్! మీరు అద్భుతంగా చేస్తున్నారు! కృషి కొనసాగించండి!",
    congrats4: "అద్భుతమైన పని! మీరు మీ లక్ష్యానికి దగ్గరవుతున్నారు!",
    congrats5: "అసాధారణమైన పని! మీ కష్టం ఫలించింది!",
    congratsGoal: "మీరు చేసారు! గొప్ప పని!",
    congratulationsHeader: "అభినందనలు!",
    firstEntryMessage: "ఇది మీ మొదటి పాయింట్! రేపు తదుపరిది జోడించడం మరచిపోకండి!",
    enterGoalWeightHere: "మీ లక్ష్య బరువును ఇక్కడ నమోదు చేసి, తరువాత బరువు జోడించు పై క్లిక్ చేయండి.",
    enterCurrentWeightHere: "మీ ప్రస్తుత బరువును ఇక్కడ నమోదు చేయండి.",
    weightConflictTitle: "బరువు వివాదం",
    weightConflictMessage: "ఈ తేదీకి ఇప్పటికే ఒక బరువు ఉంది. మీరు ఏమి చేయాలనుకుంటున్నారు?",
    existingWeight: "ప్రస్తుత బరువు",
    newWeight: "కొత్త బరువు",
    average: "సగటు బరువులు",
    addNew: "కొత్త ఎంట్రీగా జోడించండి",
    cancel: "రద్దు చేయండి"
},
mr: {
  title: "वजन ट्रॅकर",
  addWeight: "वजन जोडा",
  goalWeight: "लक्ष्य वजन",
  currentWeight: "सध्याचे वजन",
  clearAll: "सर्व डेटा साफ करा",
  daysToGoal: "लक्ष्यापर्यंत दिवस:",
  weightIn30Days: "30 दिवसांत वजन:",
  percentageLeft: "उरलेली टक्केवारी:",
  days: "दिवस",
  save: "जतन करा",
  load: "लोड करा",
  week: "आठवडा",
  month: "महिना",
  threeMonths: "3 महिने",
  sixMonths: "6 महिने",
  nineMonths: "9 महिने",
  year: "वर्ष",
  allTime: "सर्व काळ",
  date: 'तारीख',
  weight: 'वजन',
  movingAverage: 'चलित सरासरी',
  delete: 'हटवा',
  points: 'गुण',
  lostWeight: "कमी झालेले वजन:",
  gainWeight: "वाढलेले वजन:",
  difference: "फरक",
  cleanPoints: "अनावश्यक गुण साफ करा",
  confirmClearData: "तुम्हाला खरोखर सर्व डेटा साफ करायचा आहे का?",
  yes: "होय, सर्व हटवा",
  no: "नाही, सर्व ठेवा",
  currentGoalWeight: "सध्याचे / लक्ष्य वजन",
  daysTotal: "एकूण दिवस",
  averageDaily: "दैनिक सरासरी:",
  averageWeekly: "साप्ताहिक सरासरी:",
  keepMonth: "1-महिना ठेवा",
  keepWeek: "1-आठवडा ठेवा",
  congrats1: "उत्कृष्ट प्रगती! असेच चालू ठेवा!",
  congrats2: "तुम्ही छान करत आहात! आणखी एक टप्पा गाठला!",
  congrats3: "वा! तुम्ही जबरदस्त करत आहात! प्रयत्न सुरू ठेवा!",
  congrats4: "अद्भुत काम! तुम्ही तुमच्या लक्ष्याजवळ येत आहात!",
  congrats5: "अविश्वसनीय काम! तुमच्या कष्टाचे फळ मिळत आहे!",
  congratsGoal: "तुम्ही ते केले! उत्तम काम!",
  congratulationsHeader: "अभिनंदन!",
  firstEntryMessage: "हा तुमचा पहिला गुण आहे! उद्या पुढचा जोडायला विसरू नका!",
  enterGoalWeightHere: "तुमचे लक्ष्य वजन येथे टाका, नंतर वजन जोडा वर क्लिक करा.",
  enterCurrentWeightHere: "तुमचे सध्याचे वजन येथे टाका.",
  weightConflictTitle: "वजन संघर्ष",
    weightConflictMessage: "या तारखेसाठी आधीच एक वजन अस्तित्वात आहे. तुम्हाला काय करायचे आहे?",
    existingWeight: "विद्यमान वजन",
    newWeight: "नवीन वजन",
    average: "सरासरी वजन",
    addNew: "नवीन नोंद म्हणून जोडा",
    cancel: "रद्द करा"
},
th: {
  title: "เครื่องติดตามน้ำหนัก",
  addWeight: "เพิ่มน้ำหนัก",
  goalWeight: "น้ำหนักเป้าหมาย",
  currentWeight: "น้ำหนักปัจจุบัน",
  clearAll: "ล้างข้อมูลทั้งหมด",
  daysToGoal: "วันถึงเป้าหมาย:",
  weightIn30Days: "น้ำหนักใน 30 วัน:",
  percentageLeft: "เปอร์เซ็นต์ที่เหลือ:",
  days: "วัน",
  save: "บันทึก",
  load: "โหลด",
  week: "สัปดาห์",
  month: "เดือน",
  threeMonths: "3 เดือน",
  sixMonths: "6 เดือน",
  nineMonths: "9 เดือน",
  year: "ปี",
  allTime: "ทั้งหมด",
  date: 'วันที่',
  weight: 'น้ำหนัก',
  movingAverage: 'ค่าเฉลี่ยเคลื่อนที่',
  delete: 'ลบ',
  points: 'คะแนน',
  lostWeight: "น้ำหนักที่ลดลง:",
  gainWeight: "น้ำหนักที่เพิ่มขึ้น:",
  difference: "ความแตกต่าง",
  cleanPoints: "ล้างจุดที่ซ้ำซ้อน",
  confirmClearData: "คุณแน่ใจหรือไม่ที่จะล้างข้อมูลทั้งหมด?",
  yes: "ใช่ ลบทั้งหมด",
  no: "ไม่ เก็บทั้งหมดไว้",
  currentGoalWeight: "น้ำหนักปัจจุบัน / เป้าหมาย",
  daysTotal: "รวมวัน",
  averageDaily: "ค่าเฉลี่ยรายวัน:",
  averageWeekly: "ค่าเฉลี่ยรายสัปดาห์:",
  keepMonth: "เก็บ 1 เดือน",
  keepWeek: "เก็บ 1 สัปดาห์",
  congrats1: "ความก้าวหน้าที่ยอดเยี่ยม! รักษาไว้นะ!",
  congrats2: "คุณทำได้ดีมาก! บรรลุอีกหนึ่งเป้าหมายแล้ว!",
  congrats3: "ว้าว! คุณกำลังทำได้ดีมาก! พยายามต่อไป!",
  congrats4: "ทำได้ดีมาก! คุณกำลังเข้าใกล้เป้าหมายของคุณ!",
  congrats5: "งานที่น่าทึ่ง! ความพยายามของคุณกำลังส่งผล!",
  congratsGoal: "คุณทำได้แล้ว! เยี่ยมมาก!",
  congratulationsHeader: "ขอแสดงความยินดี!",
  firstEntryMessage: "นี่คือจุดแรกของคุณ! อย่าลืมเพิ่มจุดต่อไปในวันพรุ่งนี้!",
  enterGoalWeightHere: "ใส่น้ำหนักเป้าหมายของคุณที่นี่ แล้วคลิกเพิ่มน้ำหนัก",
  enterCurrentWeightHere: "ใส่น้ำหนักปัจจุบันของคุณที่นี่",
  weightConflictTitle: "ข้อขัดแย้งของน้ำหนัก",
    weightConflictMessage: "มีน้ำหนักอยู่แล้วสำหรับวันที่นี้ คุณต้องการทำอะไร?",
    existingWeight: "น้ำหนักที่มีอยู่",
    newWeight: "น้ำหนักใหม่",
    average: "น้ำหนักเฉลี่ย",
    addNew: "เพิ่มเป็นรายการใหม่",
    cancel: "ยกเลิก"
},
yo: {
  title: "Olùtọpinpin Ìwọ̀n",
  addWeight: "Fi Ìwọ̀n Kún",
  goalWeight: "Ìwọ̀n Àfojúsùn",
  currentWeight: "Ìwọ̀n Lọ́wọ́lọ́wọ́",
  clearAll: "Pa Gbogbo Dátà Rẹ́",
  daysToGoal: "Ọjọ́ sí àfojúsùn:",
  weightIn30Days: "Ìwọ̀n ní ọjọ́ 30:",
  percentageLeft: "Ìdá ọgọ́rùn-ún tó kù:",
  days: "ọjọ́",
  save: "FI PAMỌ́",
  load: "GBÉ WỌLÉ",
  week: "Ọ̀sẹ̀",
  month: "Oṣù",
  threeMonths: "Oṣù 3",
  sixMonths: "Oṣù 6",
  nineMonths: "Oṣù 9",
  year: "Ọdún",
  allTime: "Gbogbo Àkókò",
  date: 'Ọjọ́',
  weight: 'Ìwọ̀n',
  movingAverage: 'Àpapọ̀ Ìyílọ́pọ̀',
  delete: 'Parẹ́',
  points: 'ÀWỌN ÀMÌ',
  lostWeight: "Ìwọ̀n tó dinku:",
  gainWeight: "Ìwọ̀n tó pọ̀si:",
  difference: "Ìyàtọ̀",
  cleanPoints: "Wẹ́ Àwọn Àmì Àjẹmọ́",
  confirmClearData: "Ṣé o dájú pé o fẹ́ pa gbogbo dátà rẹ́?",
  yes: "Bẹ́ẹ̀ni, pa gbogbo rẹ̀",
  no: "Bẹ́ẹ̀kọ́, pa gbogbo rẹ̀ mọ́",
  currentGoalWeight: "Ìwọ̀n Lọ́wọ́lọ́wọ́ / Àfojúsùn",
  daysTotal: "àpapọ̀ ọjọ́",
  averageDaily: "Àpapọ̀ Ojoojúmọ́:",
  averageWeekly: "Àpapọ̀ Ọ̀sọ̀ọ̀sẹ̀:",
  keepMonth: "Pa Oṣù-1 Mọ́",
  keepWeek: "Pa Ọ̀sẹ̀-1 Mọ́",
  congrats1: "Ìlọsíwájú tó dára! Máa tẹ̀síwájú bẹ́ẹ̀!",
  congrats2: "O ṣe dáradára! O tí dé òkè míì!",
  congrats3: "Háà! O ń ṣe dáradára! Máa tẹ̀síwájú!",
  congrats4: "Iṣẹ́ àgbàyanu! O ń súnmọ́ àfojúsùn rẹ!",
  congrats5: "Iṣẹ́ ìyanu! Làálàá rẹ ń sanwó!",
  congratsGoal: "O ti ṣe é! Iṣẹ́ àgbàyanu!",
  congratulationsHeader: "Kú oriire!",
  firstEntryMessage: "Èyí ni àmì àkọ́kọ́ rẹ! Má gbàgbé láti fi èkejì kún ní ọ̀la!",
  enterGoalWeightHere: "Tẹ ìwọ̀n ÀFOJÚSÙN rẹ sí ibí, lẹ́yìn náà tẹ Fi Ìwọ̀n Kún.",
    enterCurrentWeightHere: "Tẹ ìwọ̀n LỌWỌLỌWỌ rẹ sí ibí.",
    weightConflictTitle: "Ìjà Ìwọ̀n",
    weightConflictMessage: "Ìwọ̀n kan ti wà fún ọjọ́ yìí tẹ́lẹ̀. Kí ni o fẹ́ ṣe?",
    existingWeight: "Ìwọ̀n tó wà tẹ́lẹ̀",
    newWeight: "Ìwọ̀n tuntun",
    average: "Àpapọ̀ ìwọ̀n",
    addNew: "Fi kún gẹ́gẹ́ bí ìkọsílẹ̀ tuntun",
    cancel: "Parẹ́"
},
tl: {
  title: "Tagasubaybay ng Timbang",
  addWeight: "Dagdag Timbang",
  goalWeight: "Layuning Timbang",
  currentWeight: "Kasalukuyang Timbang",
  clearAll: "Burahin Lahat ng Data",
  daysToGoal: "Araw hanggang sa layunin:",
  weightIn30Days: "Timbang sa loob ng 30 araw:",
  percentageLeft: "Porsyentong natitira:",
  days: "araw",
  save: "I-SAVE",
  load: "I-LOAD",
  week: "Linggo",
  month: "Buwan",
  threeMonths: "3 Buwan",
  sixMonths: "6 na Buwan",
  nineMonths: "9 na Buwan",
  year: "Taon",
  allTime: "Lahat ng Panahon",
  date: 'Petsa',
  weight: 'Timbang',
  movingAverage: 'Gumagalaw na Average',
  delete: 'Burahin',
  points: 'PUNTOS',
  lostWeight: "Nabawasang timbang:",
  gainWeight: "Nadagdagang timbang:",
  difference: "Pagkakaiba",
  cleanPoints: "Linisin ang Mga Sobrang Punto",
  confirmClearData: "Sigurado ka bang gusto mong burahin lahat ng data?",
  yes: "Oo, burahin lahat",
  no: "Hindi, panatilihin lahat",
  currentGoalWeight: "Kasalukuyan / Layuning Timbang",
  daysTotal: "kabuuang araw",
  averageDaily: "Pang-araw-araw na Average:",
  averageWeekly: "Lingguhang Average:",
  keepMonth: "Panatilihin ang 1-Buwan",
  keepWeek: "Panatilihin ang 1-Linggo",
  congrats1: "Napakahusay na progreso! Ipagpatuloy mo!",
  congrats2: "Magaling ang ginagawa mo! Isa na namang milestone ang naabot!",
  congrats3: "Wow! Ang galing mo! Tuloy lang!",
  congrats4: "Napakagandang trabaho! Palapit ka na sa iyong layunin!",
  congrats5: "Kahanga-hangang gawa! Nagbubunga na ang iyong pagsisikap!",
  congratsGoal: "Nagawa mo na! Magaling na trabaho!",
  congratulationsHeader: "Binabati kita!",
  firstEntryMessage: "Ito ang iyong unang punto! Huwag kalimutang magdagdag ng susunod bukas!",
  enterGoalWeightHere: "Ilagay ang iyong LAYUNING timbang dito, pagkatapos i-click ang Dagdag Timbang.",
  enterCurrentWeightHere: "Ilagay ang iyong KASALUKUYANG timbang dito.",
  weightConflictTitle: "Pagsasalungat ng Timbang",
    weightConflictMessage: "May timbang na para sa petsang ito. Ano ang gusto mong gawin?",
    existingWeight: "Kasalukuyang timbang",
    newWeight: "Bagong timbang",
    average: "Karaniwang timbang",
    addNew: "Idagdag bilang bagong entry",
    cancel: "Kanselahin"
},
  he:{
    title: "מעקב משקל",
    addWeight: "הוסף משקל",
    goalWeight: "משקל יעד",
    currentWeight: "משקל נוכחי",
    clearAll: "נקה את כל הנתונים",
    daysToGoal: "ימים ליעד:",
    weightIn30Days: "משקל בעוד 30 ימים:",
    percentageLeft: "אחוז שנותר:",
    days: "ימים",
    save: "שמור",
    load: "טען",
    week: "שבוע",
    month: "חודש",
    threeMonths: "3 חודשים",
    sixMonths: "6 חודשים",
    nineMonths: "9 חודשים",
    year: "שנה",
    allTime: "כל הזמן",
    date: 'תאריך',
    weight: 'משקל',
    movingAverage: 'ממוצע נע',
    delete: 'מחק',
    points: 'נקודות',
    lostWeight: "משקל שאבד:",
    gainWeight: "משקל שנוסף:",
    difference: "הבדל",
    cleanPoints: "נקה נקודות מיותרות",
    confirmClearData: "האם אתה בטוח שברצונך לנקות את כל הנתונים?",
    yes: "כן, מחק הכל",
    no: "לא, שמור הכל",
    currentGoalWeight: "משקל נוכחי / יעד",
    daysTotal: "סה״כ ימים",
    averageDaily: "ממוצע יומי:",
    averageWeekly: "ממוצע שבועי:",
    keepMonth: "שמור חודש",
    keepWeek: "שמור שבוע",
    congrats1: "התקדמות פנטסטית! המשך כך!",
    congrats2: "אתה עושה עבודה נהדרת! הגעת לאבן דרך נוספת!",
    congrats3: "וואו! אתה לוהט! המשך כך!",
    congrats4: "עבודה מדהימה! אתה מתקרב למטרה שלך!",
    congrats5: "עבודה נהדרת! העבודה הקשה שלך משתלמת!",
    congratsGoal: "עשית את זה! עבודה נהדרת!",
    congratulationsHeader: "ברכות!",
    firstEntryMessage: "זהו הנקודה הראשונה שלך! אל תשכח להוסיף את הנקודה הבאה מחר!",
    enterGoalWeightHere: "הכנס את משקל היעד שלך כאן, ואז לחץ על הוסף משקל.",
    enterCurrentWeightHere: "הכנס את משקלך הנוכחי כאן.",
    weightConflictTitle: "התנגשות משקל",
    weightConflictMessage: "כבר קיים משקל לתאריך זה. מה ברצונך לעשות?",
    existingWeight: "משקל קיים",
    newWeight: "משקל חדש",
    average: "ממוצע משקלים",
    addNew: "הוסף כרשומה חדשה",
    cancel: "ביטול"
  },
  ar_eg: {
    "title": "متتبع الوزن",
    "addWeight": "إضافة وزن",
    "goalWeight": "الوزن المستهدف",
    "currentWeight": "الوزن الحالي",
    "clearAll": "مسح كل البيانات",
    "daysToGoal": "أيام للوصول للهدف:",
    "weightIn30Days": "الوزن في 30 يومًا:",
    "percentageLeft": "النسبة المتبقية:",
    "days": "أيام",
    "save": "حفظ",
    "load": "تحميل",
    "week": "أسبوع",
    "month": "شهر",
    "threeMonths": "3 أشهر",
    "sixMonths": "6 أشهر",
    "nineMonths": "9 أشهر",
    "year": "سنة",
    "allTime": "كل الوقت",
    "date": "التاريخ",
    "weight": "الوزن",
    "movingAverage": "المتوسط المتحرك",
    "delete": "حذف",
    "points": "نقاط",
    "lostWeight": "الوزن المفقود:",
    "gainWeight": "الوزن المكتسب:",
    "difference": "الفرق",
    "cleanPoints": "تنظيف النقاط الزائدة",
    "confirmClearData": "هل أنت متأكد أنك تريد مسح كل البيانات؟",
    "yes": "نعم، احذف الكل",
    "no": "لا، احتفظ بالجميع",
    "currentGoalWeight": "الوزن الحالي / الوزن المستهدف",
    "daysTotal": "إجمالي الأيام",
    "averageDaily": "المتوسط اليومي:",
    "averageWeekly": "المتوسط الأسبوعي:",
    "keepMonth": "احتفظ بشهر واحد",
    "keepWeek": "احتفظ بأسبوع واحد",
    "congrats1": "تقدم رائع! استمر!",
    "congrats2": "أنت تفعل شيئًا عظيمًا! إنجاز آخر تحقق!",
    "congrats3": "واو! أنت في تألق! استمر في الضغط!",
    "congrats4": "عمل مذهل! أنت تقترب من هدفك!",
    "congrats5": "عمل رائع! جهدك يؤتي ثماره!",
    "congratsGoal": "لقد فعلتها! عمل رائع!",
    "congratulationsHeader": "تهانينا!",
    "firstEntryMessage": "هذه هي النقطة الأولى! لا تنسى إضافة النقطة التالية غدًا!",
    "enterGoalWeightHere": "أدخل وزنك المستهدف هنا، ثم اضغط على إضافة وزن.",
    "enterCurrentWeightHere": "أدخل وزنك الحالي هنا.",
    weightConflictTitle: "تعارض الوزن",
    weightConflictMessage: "يوجد بالفعل وزن لهذا التاريخ. ماذا تريد أن تفعل؟",
    existingWeight: "الوزن الحالي",
    newWeight: "وزن جديد",
    average: "متوسط الأوزان",
    addNew: "إضافة كإدخال جديد",
    cancel: "إلغاء"
  },
  
  ar_le: {
    "title": "متتبع الوزن",
    "addWeight": "أضف وزن",
    "goalWeight": "الوزن المستهدف",
    "currentWeight": "الوزن الحالي",
    "clearAll": "مسح جميع البيانات",
    "daysToGoal": "الأيام المتبقية لتحقيق الهدف:",
    "weightIn30Days": "الوزن بعد 30 يوم:",
    "percentageLeft": "النسبة المتبقية:",
    "days": "أيام",
    "save": "حفظ",
    "load": "تحميل",
    "week": "أسبوع",
    "month": "شهر",
    "threeMonths": "3 أشهر",
    "sixMonths": "6 أشهر",
    "nineMonths": "9 أشهر",
    "year": "سنة",
    "allTime": "كل الوقت",
    "date": "تاريخ",
    "weight": "وزن",
    "movingAverage": "المتوسط المتحرك",
    "delete": "حذف",
    "points": "نقاط",
    "lostWeight": "الوزن المفقود:",
    "gainWeight": "الوزن المكتسب:",
    "difference": "الفرق",
    "cleanPoints": "تنظيف النقاط الزائدة",
    "confirmClearData": "هل أنت متأكد أنك تريد مسح جميع البيانات؟",
    "yes": "نعم، احذف الكل",
    "no": "لا، احتفظ بالكل",
    "currentGoalWeight": "الوزن الحالي / الوزن المستهدف",
    "daysTotal": "إجمالي الأيام",
    "averageDaily": "المتوسط اليومي:",
    "averageWeekly": "المتوسط الأسبوعي:",
    "keepMonth": "احتفظ بشهر",
    "keepWeek": "احتفظ بأسبوع",
    "congrats1": "تقدم رائع! استمر في العمل!",
    "congrats2": "أنت تقوم بعمل رائع! هدف آخر تحقق!",
    "congrats3": "رائع! أنت ممتاز! استمر!",
    "congrats4": "عمل مذهل! أنت تقترب من هدفك!",
    "congrats5": "عمل لا يصدق! جهدك يؤتي ثماره!",
    "congratsGoal": "لقد فعلتها! عمل رائع!",
    "congratulationsHeader": "تهانينا!",
    "firstEntryMessage": "هذه أول نقطة لك! لا تنسى إضافة النقطة التالية غدًا!",
    "enterGoalWeightHere": "أدخل وزنك المستهدف هنا، ثم اضغط على أضف وزن.",
    "enterCurrentWeightHere": "أدخل وزنك الحالي هنا.",
    weightConflictTitle: "تعارض الوزن",
    weightConflictMessage: "هناك وزن موجود بالفعل لهذا التاريخ. ماذا تريد أن تفعل؟",
    existingWeight: "الوزن الموجود",
    newWeight: "وزن جديد",
    average: "متوسط الأوزان",
    addNew: "إضافة كإدخال جديد",
    cancel: "إلغاء"
  }
  ,
  am: {
    "title": "Քաշի Հետևում",
    "addWeight": "Ավելացնել Քաշ",
    "goalWeight": "Նպատակ Քաշ",
    "currentWeight": "Ներկա Քաշ",
    "clearAll": "Մաքրել Բոլոր Տվյալները",
    "daysToGoal": "Օրեր մինչ նպատակ:",
    "weightIn30Days": "Քաշը 30 օրում:",
    "percentageLeft": "Մնացած տոկոս:",
    "days": "օրեր",
    "save": "ՊԱՀՊԱՆԵԼ",
    "load": "ԲԵՌՆԵԼ",
    "week": "Շաբաթ",
    "month": "Ամիս",
    "threeMonths": "3 Ամիս",
    "sixMonths": "6 Ամիս",
    "nineMonths": "9 Ամիս",
    "year": "Տարի",
    "allTime": "Ամբողջ Ժամանակը",
    "date": "Ամսաթիվ",
    "weight": "Քաշ",
    "movingAverage": "Շարժական Միջին",
    "delete": "Ջնջել",
    "points": "ԿԵՏԵՐ",
    "lostWeight": "Կորցրած Քաշ:",
    "gainWeight": "Հավաքած Քաշ:",
    "difference": "Տարբերություն",
    "cleanPoints": "Մաքրել Կրկնվող Կետերը",
    "confirmClearData": "Համոզված ե՞ք, որ ցանկանում եք մաքրել բոլոր տվյալները:",
    "yes": "Այո, ջնջել բոլորը",
    "no": "Ոչ, պահպանել բոլորը",
    "currentGoalWeight": "Ներկա / Նպատակ Քաշ",
    "daysTotal": "ընդհանուր օրեր",
    "averageDaily": "Օրական Միջին:",
    "averageWeekly": "Շաբաթական Միջին:",
    "keepMonth": "Պահպանել 1-Ամիս",
    "keepWeek": "Պահպանել 1-Շաբաթ",
    "congrats1": "Հիանալի առաջընթաց! Շարունակիր այսպես!",
    "congrats2": "Դու մեծ գործ ես կատարում! Եվս մեկ նպատակ է իրականացել!",
    "congrats3": "Վաու! Դու առաջ ես գնում! Շարունակիր պայքարել!",
    "congrats4": "Հիասքանչ աշխատանք! Դու մոտենում ես քո նպատակին!",
    "congrats5": "Անհավատալի աշխատանք! Քո ջանքերը արդյունք են տալիս!",
    "congratsGoal": "Դու արեցիր դա! Հիանալի աշխատանք!",
    "congratulationsHeader": "Շնորհավորանքներ!",
    "firstEntryMessage": "Սա քո առաջին կետն է! Մի մոռացիր ավելացնել հաջորդ կետը վաղը!",
    "enterGoalWeightHere": "Մուտքագրիր քո ՆՊԱՏԱԿ քաշը այստեղ, ապա սեղմիր Ավելացնել Քաշ.",
    "enterCurrentWeightHere": "Մուտքագրիր քո ՆԵՐԿԱ քաշը այստեղ.",
    weightConflictTitle: "Քաշի կոնֆլիկտ",
    weightConflictMessage: "Այս ամսաթվի համար արդեն գոյություն ունի քաշ: Ի՞նչ եք ցանկանում անել:",
    existingWeight: "Առկա քաշ",
    newWeight: "Նոր քաշ",
    average: "Միջին քաշեր",
    addNew: "Ավելացնել որպես նոր գրառում",
    cancel: "Չեղարկել"
  }
  ,
  be: {
    "title": "Сачэнне за вагой",
    "addWeight": "Дадаць вагу",
    "goalWeight": "Мэтавая вага",
    "currentWeight": "Бягучая вага",
    "clearAll": "Ачысціць усе дадзеныя",
    "daysToGoal": "Дні да мэты:",
    "weightIn30Days": "Вага праз 30 дзён:",
    "percentageLeft": "Астатак працэнта:",
    "days": "дні",
    "save": "ЗАХАВАЦЬ",
    "load": "ЗАГРУЗІЦЬ",
    "week": "Тыдзень",
    "month": "Месяц",
    "threeMonths": "3 месяцы",
    "sixMonths": "6 месяцаў",
    "nineMonths": "9 месяцаў",
    "year": "Год",
    "allTime": "Увесь час",
    "date": "Дата",
    "weight": "Вага",
    "movingAverage": "Сярэдні рух",
    "delete": "Выдаліць",
    "points": "БАЛЫ",
    "lostWeight": "Страчаная вага:",
    "gainWeight": "Набраная вага:",
    "difference": "Розніца",
    "cleanPoints": "Ачысціць лішнія пункты",
    "confirmClearData": "Вы ўпэўненыя, што хочаце ачысціць усе дадзеныя?",
    "yes": "Так, выдаліць усё",
    "no": "Не, захаваць усё",
    "currentGoalWeight": "Бягучая / Мэтавая вага",
    "daysTotal": "агулам дзён",
    "averageDaily": "Сярэднядзённы:",
    "averageWeekly": "Сярэднятыднёвы:",
    "keepMonth": "Захаваць 1-Месяц",
    "keepWeek": "Захаваць 1-Тыдзень",
    "congrats1": "Фантастычны прагрэс! Працягвай у тым жа духу!",
    "congrats2": "Вы выдатна спраўляецеся! Яшчэ адзін мэтавы дасягнуты!",
    "congrats3": "Ваў! Вы ў агні! Працягвайце націскаць!",
    "congrats4": "Выдатная праца! Вы набліжаецеся да сваёй мэты!",
    "congrats5": "Неверагодная праца! Ваша праца дае вынікі!",
    "congratsGoal": "Вы зрабілі гэта! Выдатная праца!",
    "congratulationsHeader": "Віншаванні!",
    "firstEntryMessage": "Гэта ваша першая кропка! Не забудзьцеся дадаць наступную заўтра!",
    "enterGoalWeightHere": "Увядзіце сваю МЭТУ вагу тут, затым націсніце Дадаць вагу.",
    "enterCurrentWeightHere": "Увядзіце сваю БЯГУЧУЮ вагу тут.",
    weightConflictTitle: "Канфлікт вагі",
    weightConflictMessage: "Для гэтай даты ўжо існуе вага. Што вы хочаце зрабіць?",
    existingWeight: "Існуючая вага",
    newWeight: "Новая вага",
    average: "Сярэдняя вага",
    addNew: "Дадаць як новы запіс",
    cancel: "Адмяніць"
  }
  ,
  bn: {
    "title": "ওজন ট্র্যাকার",
    "addWeight": "ওজন যোগ করুন",
    "goalWeight": "লক্ষ্যমাত্রা ওজন",
    "currentWeight": "বর্তমান ওজন",
    "clearAll": "সব তথ্য মুছে ফেলুন",
    "daysToGoal": "লক্ষ্যমাত্রা পৌঁছাতে দিন:",
    "weightIn30Days": "৩০ দিনের মধ্যে ওজন:",
    "percentageLeft": "বাকি শতাংশ:",
    "days": "দিন",
    "save": "সংরক্ষণ করুন",
    "load": "লোড করুন",
    "week": "সপ্তাহ",
    "month": "মাস",
    "threeMonths": "৩ মাস",
    "sixMonths": "৬ মাস",
    "nineMonths": "৯ মাস",
    "year": "বছর",
    "allTime": "সমস্ত সময়",
    "date": "তারিখ",
    "weight": "ওজন",
    "movingAverage": "মুভিং গড়",
    "delete": "মুছে ফেলুন",
    "points": "পয়েন্ট",
    "lostWeight": "ওজন হারিয়েছে:",
    "gainWeight": "ওজন বেড়েছে:",
    "difference": "পার্থক্য",
    "cleanPoints": "অতিরিক্ত পয়েন্ট মুছুন",
    "confirmClearData": "আপনি কি নিশ্চিত যে আপনি সমস্ত তথ্য মুছে ফেলতে চান?",
    "yes": "হ্যাঁ, সব মুছে ফেলুন",
    "no": "না, সব রাখুন",
    "currentGoalWeight": "বর্তমান / লক্ষ্যমাত্রা ওজন",
    "daysTotal": "মোট দিন",
    "averageDaily": "গড় দৈনিক:",
    "averageWeekly": "গড় সাপ্তাহিক:",
    "keepMonth": "১ মাস রাখুন",
    "keepWeek": "১ সপ্তাহ রাখুন",
    "congrats1": "অসাধারণ অগ্রগতি! চালিয়ে যান!",
    "congrats2": "আপনি দুর্দান্ত করছেন! আরেকটি লক্ষ্য অর্জিত!",
    "congrats3": "ওয়াও! আপনি চমৎকার করছেন! চালিয়ে যান!",
    "congrats4": "অসাধারণ কাজ! আপনি আপনার লক্ষ্যের কাছাকাছি আসছেন!",
    "congrats5": "অবিশ্বাস্য কাজ! আপনার পরিশ্রম ফলপ্রসূ হচ্ছে!",
    "congratsGoal": "আপনি এটি করেছেন! দুর্দান্ত কাজ!",
    "congratulationsHeader": "অভিনন্দন!",
    "firstEntryMessage": "এটি আপনার প্রথম পয়েন্ট! আগামীকাল পরেরটি যোগ করতে ভুলবেন না!",
    "enterGoalWeightHere": "আপনার লক্ষ্যমাত্রা ওজন এখানে প্রবেশ করুন, তারপর ওজন যোগ করুন ক্লিক করুন।",
    "enterCurrentWeightHere": "আপনার বর্তমান ওজন এখানে প্রবেশ করুন।",
    weightConflictTitle: "ওজন দ্বন্দ্ব",
    weightConflictMessage: "এই তারিখের জন্য ইতিমধ্যে একটি ওজন বিদ্যমান। আপনি কি করতে চান?",
    existingWeight: "বিদ্যমান ওজন",
    newWeight: "নতুন ওজন",
    average: "গড় ওজন",
    addNew: "নতুন এন্ট্রি হিসাবে যোগ করুন",
    cancel: "বাতিল"
  }
  ,
  bho: {
    "title": "वजन ट्रैकर",
    "addWeight": "वजन जोड़ें",
    "goalWeight": "लक्ष्य वजन",
    "currentWeight": "वर्तमान वजन",
    "clearAll": "सभी डेटा साफ करें",
    "daysToGoal": "लक्ष्य तक दिन:",
    "weightIn30Days": "30 दिनों में वजन:",
    "percentageLeft": "बचा हुआ प्रतिशत:",
    "days": "दिन",
    "save": "सुरक्षित करें",
    "load": "लोड करें",
    "week": "सप्ताह",
    "month": "महीना",
    "threeMonths": "3 महीने",
    "sixMonths": "6 महीने",
    "nineMonths": "9 महीने",
    "year": "साल",
    "allTime": "सभी समय",
    "date": "तारीख",
    "weight": "वजन",
    "movingAverage": "चलती औसत",
    "delete": "हटाएं",
    "points": "पॉइंट्स",
    "lostWeight": "घटाया वजन:",
    "gainWeight": "बढ़ा वजन:",
    "difference": "अंतर",
    "cleanPoints": "अनावश्यक पॉइंट्स साफ करें",
    "confirmClearData": "क्या आप सुनिश्चित हैं कि आप सभी डेटा साफ करना चाहते हैं?",
    "yes": "हां, सभी हटाएं",
    "no": "नहीं, सभी रखें",
    "currentGoalWeight": "वर्तमान / लक्ष्य वजन",
    "daysTotal": "कुल दिन",
    "averageDaily": "औसत दैनिक:",
    "averageWeekly": "औसत साप्ताहिक:",
    "keepMonth": "1-महीना रखें",
    "keepWeek": "1-सप्ताह रखें",
    "congrats1": "शानदार प्रगति! इसी तरह बनाए रखें!",
    "congrats2": "आप बहुत अच्छा कर रहे हैं! एक और लक्ष्य पूरा हुआ!",
    "congrats3": "वाह! आप आग पर हैं! दबाव बनाए रखें!",
    "congrats4": "अद्भुत काम! आप अपने लक्ष्य के करीब पहुँच रहे हैं!",
    "congrats5": "अविश्वसनीय काम! आपकी मेहनत रंग ला रही है!",
    "congratsGoal": "आपने इसे कर दिखाया! बढ़िया काम!",
    "congratulationsHeader": "बधाई हो!",
    "firstEntryMessage": "यह आपकी पहली प्रविष्टि है! कल दूसरा जोड़ना न भूलें!",
    "enterGoalWeightHere": "यहां अपना लक्ष्य वजन दर्ज करें, फिर वजन जोड़ें पर क्लिक करें।",
    "enterCurrentWeightHere": "यहां अपना वर्तमान वजन दर्ज करें।",
    weightConflictTitle: "वजन विवाद",
    weightConflictMessage: "ई तारीख खातिर पहिले से एगो वजन मौजूद बा। रउआ का करल चाहत बानी?",
    existingWeight: "मौजूदा वजन",
    newWeight: "नया वजन",
    average: "औसत वजन",
    addNew: "नया एंट्री के रूप में जोड़ीं",
    cancel: "रद्द करीं"
  }
  ,
  cs: {
    "title": "Sledovač váhy",
    "addWeight": "Přidat váhu",
    "goalWeight": "Cílová váha",
    "currentWeight": "Aktuální váha",
    "clearAll": "Vymazat všechna data",
    "daysToGoal": "Dny do cíle:",
    "weightIn30Days": "Váha za 30 dní:",
    "percentageLeft": "Zbývající procento:",
    "days": "dny",
    "save": "ULOŽIT",
    "load": "NAHRÁT",
    "week": "Týden",
    "month": "Měsíc",
    "threeMonths": "3 měsíce",
    "sixMonths": "6 měsíců",
    "nineMonths": "9 měsíců",
    "year": "Rok",
    "allTime": "Celý čas",
    "date": "Datum",
    "weight": "Váha",
    "movingAverage": "Klouzavý průměr",
    "delete": "Smazat",
    "points": "BODY",
    "lostWeight": "Ztracená váha:",
    "gainWeight": "Nabytá váha:",
    "difference": "Rozdíl",
    "cleanPoints": "Vyčistit nadbytečné body",
    "confirmClearData": "Jste si jisti, že chcete vymazat všechna data?",
    "yes": "Ano, vymazat vše",
    "no": "Ne, zachovat vše",
    "currentGoalWeight": "Aktuální / Cílová váha",
    "daysTotal": "celkem dní",
    "averageDaily": "Průměr denně:",
    "averageWeekly": "Průměr týdně:",
    "keepMonth": "Zachovat 1 měsíc",
    "keepWeek": "Zachovat 1 týden",
    "congrats1": "Fantastický pokrok! Pokračujte!",
    "congrats2": "Děláte to skvěle! Další milník dosažen!",
    "congrats3": "Wow! Jste na vlně! Pokračujte!",
    "congrats4": "Úžasná práce! Blížíte se ke svému cíli!",
    "congrats5": "Neuvěřitelná práce! Vaše úsilí se vyplácí!",
    "congratsGoal": "Dokázali jste to! Skvělá práce!",
    "congratulationsHeader": "Gratulace!",
    "firstEntryMessage": "To je váš první bod! Nezapomeňte přidat další zítra!",
    "enterGoalWeightHere": "Zde zadejte svou CÍLOVOU váhu a poté klikněte na Přidat váhu.",
    "enterCurrentWeightHere": "Zadejte svou AKTUÁLNÍ váhu zde.",
    weightConflictTitle: "Konflikt váhy",
    weightConflictMessage: "Pro toto datum již existuje váha. Co chcete udělat?",
    existingWeight: "Existující váha",
    newWeight: "Nová váha",
    average: "Průměrné váhy",
    addNew: "Přidat jako nový záznam",
    cancel: "Zrušit"
  }
  ,
  da: {
    "title": "Vægt Tracker",
    "addWeight": "Tilføj Vægt",
    "goalWeight": "Mål Vægt",
    "currentWeight": "Nuværende Vægt",
    "clearAll": "Slet Alle Data",
    "daysToGoal": "Dage til mål:",
    "weightIn30Days": "Vægt om 30 dage:",
    "percentageLeft": "Procent tilbage:",
    "days": "dage",
    "save": "GEM",
    "load": "INDLÆS",
    "week": "Uge",
    "month": "Måned",
    "threeMonths": "3 Måneder",
    "sixMonths": "6 Måneder",
    "nineMonths": "9 Måneder",
    "year": "År",
    "allTime": "Al Tid",
    "date": "Dato",
    "weight": "Vægt",
    "movingAverage": "Bevægende Gennemsnit",
    "delete": "Slet",
    "points": "POINTS",
    "lostWeight": "Tabt Vægt:",
    "gainWeight": "Opnået Vægt:",
    "difference": "Forskel",
    "cleanPoints": "Rens Overflødige Punkter",
    "confirmClearData": "Er du sikker på, at du vil slette alle data?",
    "yes": "Ja, slet alt",
    "no": "Nej, behold alt",
    "currentGoalWeight": "Nuværende / Mål Vægt",
    "daysTotal": "dage i alt",
    "averageDaily": "Gennemsnit Dagligt:",
    "averageWeekly": "Gennemsnit Ugentligt:",
    "keepMonth": "Behold 1-Måned",
    "keepWeek": "Behold 1-Uge",
    "congrats1": "Fantastisk fremgang! Bliv ved!",
    "congrats2": "Du gør det fantastisk! Endnu en milepæl nået!",
    "congrats3": "Wow! Du er på! Fortsæt!",
    "congrats4": "Fantastisk arbejde! Du kommer tættere på dit mål!",
    "congrats5": "Utroligt arbejde! Dit hårde arbejde betaler sig!",
    "congratsGoal": "Du gjorde det! Godt arbejde!",
    "congratulationsHeader": "Tillykke!",
    "firstEntryMessage": "Det er dit første punkt! Glem ikke at tilføje næste punkt i morgen!",
    "enterGoalWeightHere": "Indtast din MÅL vægt her, og klik derefter på Tilføj Vægt.",
    "enterCurrentWeightHere": "Indtast din NUVÆRENDE vægt her.",
    weightConflictTitle: "Vægtkonflikt",
    weightConflictMessage: "Der eksisterer allerede en vægt for denne dato. Hvad vil du gøre?",
    existingWeight: "Eksisterende vægt",
    newWeight: "Ny vægt",
    average: "Gennemsnitlige vægte",
    addNew: "Tilføj som ny post",
    cancel: "Annuller"
  }
  ,
  de: {
    "title": "Gewichtstracker",
    "addWeight": "Gewicht hinzufügen",
    "goalWeight": "Zielgewicht",
    "currentWeight": "Aktuelles Gewicht",
    "clearAll": "Alle Daten löschen",
    "daysToGoal": "Tage zum Ziel:",
    "weightIn30Days": "Gewicht in 30 Tagen:",
    "percentageLeft": "Verbleibender Prozentsatz:",
    "days": "Tage",
    "save": "SPEICHERN",
    "load": "LADEN",
    "week": "Woche",
    "month": "Monat",
    "threeMonths": "3 Monate",
    "sixMonths": "6 Monate",
    "nineMonths": "9 Monate",
    "year": "Jahr",
    "allTime": "Alle Zeit",
    "date": "Datum",
    "weight": "Gewicht",
    "movingAverage": "Gleitender Durchschnitt",
    "delete": "Löschen",
    "points": "PUNKTE",
    "lostWeight": "Verlorenes Gewicht:",
    "gainWeight": "Zugenommenes Gewicht:",
    "difference": "Unterschied",
    "cleanPoints": "Überflüssige Punkte bereinigen",
    "confirmClearData": "Sind Sie sicher, dass Sie alle Daten löschen möchten?",
    "yes": "Ja, alles löschen",
    "no": "Nein, alles behalten",
    "currentGoalWeight": "Aktuelles / Zielgewicht",
    "daysTotal": "Tage insgesamt",
    "averageDaily": "Durchschnittlich täglich:",
    "averageWeekly": "Durchschnittlich wöchentlich:",
    "keepMonth": "1 Monat behalten",
    "keepWeek": "1 Woche behalten",
    "congrats1": "Fantastischer Fortschritt! Mach weiter so!",
    "congrats2": "Du machst das großartig! Noch ein Meilenstein erreicht!",
    "congrats3": "Wow! Du bist auf einem guten Weg! Mach weiter!",
    "congrats4": "Tolle Arbeit! Du kommst deinem Ziel näher!",
    "congrats5": "Unglaubliche Arbeit! Deine harte Arbeit zahlt sich aus!",
    "congratsGoal": "Du hast es geschafft! Tolle Arbeit!",
    "congratulationsHeader": "Herzlichen Glückwunsch!",
    "firstEntryMessage": "Das ist dein erster Punkt! Vergiss nicht, morgen den nächsten hinzuzufügen!",
    "enterGoalWeightHere": "Geben Sie hier Ihr ZIELGEWICHT ein und klicken Sie dann auf Gewicht hinzufügen.",
    "enterCurrentWeightHere": "Geben Sie hier Ihr AKTUELLES GEWICHT ein.",
    weightConflictTitle: "Gewichtskonflikt",
    weightConflictMessage: "Für dieses Datum existiert bereits ein Gewicht. Was möchten Sie tun?",
    existingWeight: "Bestehendes Gewicht",
    newWeight: "Neues Gewicht",
    average: "Durchschnittliche Gewichte",
    addNew: "Als neuen Eintrag hinzufügen",
    cancel: "Abbrechen"
  }
  ,
  el: {
    "title": "Παρακολούθηση Βάρους",
    "addWeight": "Προσθήκη Βάρους",
    "goalWeight": "Βάρος Στόχος",
    "currentWeight": "Τρέχον Βάρος",
    "clearAll": "Διαγραφή Όλων των Δεδομένων",
    "daysToGoal": "Ημέρες μέχρι τον στόχο:",
    "weightIn30Days": "Βάρος σε 30 ημέρες:",
    "percentageLeft": "Υπόλοιπο ποσοστό:",
    "days": "ημέρες",
    "save": "ΑΠΟΘΗΚΕΥΣΗ",
    "load": "ΦΟΡΤΩΣΗ",
    "week": "Εβδομάδα",
    "month": "Μήνας",
    "threeMonths": "3 Μήνες",
    "sixMonths": "6 Μήνες",
    "nineMonths": "9 Μήνες",
    "year": "Έτος",
    "allTime": "Όλη την Ώρα",
    "date": "Ημερομηνία",
    "weight": "Βάρος",
    "movingAverage": "Κινητός Μέσος Όρος",
    "delete": "Διαγραφή",
    "points": "ΣΗΜΕΙΑ",
    "lostWeight": "Χαμένο Βάρος:",
    "gainWeight": "Κερδισμένο Βάρος:",
    "difference": "Διαφορά",
    "cleanPoints": "Καθαρισμός Περιττών Σημείων",
    "confirmClearData": "Είστε σίγουροι ότι θέλετε να διαγράψετε όλα τα δεδομένα;",
    "yes": "Ναι, διαγράψτε τα όλα",
    "no": "Όχι, διατηρήστε τα όλα",
    "currentGoalWeight": "Τρέχον / Βάρος Στόχος",
    "daysTotal": "συνολικές ημέρες",
    "averageDaily": "Μέσος Όρος Ημερήσιος:",
    "averageWeekly": "Μέσος Όρος Εβδομαδιαίος:",
    "keepMonth": "Διατήρηση 1 Μήνα",
    "keepWeek": "Διατήρηση 1 Εβδομάδας",
    "congrats1": "Φανταστική πρόοδος! Συνέχισε έτσι!",
    "congrats2": "Τα πας εξαιρετικά! Ένας ακόμα στόχος επιτεύχθηκε!",
    "congrats3": "Ουάου! Είσαι φοβερός! Συνέχισε την προσπάθεια!",
    "congrats4": "Καταπληκτική δουλειά! Πλησιάζεις στον στόχο σου!",
    "congrats5": "Απίστευτη δουλειά! Η σκληρή δουλειά σου αποδίδει καρπούς!",
    "congratsGoal": "Τα κατάφερες! Καλή δουλειά!",
    "congratulationsHeader": "Συγχαρητήρια!",
    "firstEntryMessage": "Αυτό είναι το πρώτο σου σημείο! Μην ξεχάσεις να προσθέσεις το επόμενο αύριο!",
    "enterGoalWeightHere": "Εισαγάγετε το ΣΤΟΧΟ ΒΑΡΟΥΣ σας εδώ και στη συνέχεια κάντε κλικ στην Προσθήκη Βάρους.",
    "enterCurrentWeightHere": "Εισαγάγετε το ΤΡΕΧΟΝ ΒΑΡΟΣ σας εδώ.",
    weightConflictTitle: "Σύγκρουση Βάρους",
    weightConflictMessage: "Υπάρχει ήδη ένα βάρος για αυτή την ημερομηνία. Τι θα θέλατε να κάνετε;",
    existingWeight: "Υπάρχον βάρος",
    newWeight: "Νέο βάρος",
    average: "Μέσος όρος βαρών",
    addNew: "Προσθήκη ως νέα καταχώρηση",
    cancel: "Ακύρωση"
  }
  ,
  es: {
    "title": "Rastreador de Peso",
    "addWeight": "Agregar Peso",
    "goalWeight": "Peso Meta",
    "currentWeight": "Peso Actual",
    "clearAll": "Borrar Todos los Datos",
    "daysToGoal": "Días hasta la meta:",
    "weightIn30Days": "Peso en 30 días:",
    "percentageLeft": "Porcentaje restante:",
    "days": "días",
    "save": "GUARDAR",
    "load": "CARGAR",
    "week": "Semana",
    "month": "Mes",
    "threeMonths": "3 Meses",
    "sixMonths": "6 Meses",
    "nineMonths": "9 Meses",
    "year": "Año",
    "allTime": "Todo el Tiempo",
    "date": "Fecha",
    "weight": "Peso",
    "movingAverage": "Promedio Móvil",
    "delete": "Eliminar",
    "points": "PUNTOS",
    "lostWeight": "Peso perdido:",
    "gainWeight": "Peso ganado:",
    "difference": "Diferencia",
    "cleanPoints": "Limpiar Puntos Redundantes",
    "confirmClearData": "¿Está seguro de que desea borrar todos los datos?",
    "yes": "Sí, borrar todo",
    "no": "No, mantener todo",
    "currentGoalWeight": "Peso Actual / Meta",
    "daysTotal": "días en total",
    "averageDaily": "Promedio Diario:",
    "averageWeekly": "Promedio Semanal:",
    "keepMonth": "Mantener 1 Mes",
    "keepWeek": "Mantener 1 Semana",
    "congrats1": "¡Progreso fantástico! ¡Sigue así!",
    "congrats2": "¡Lo estás haciendo genial! ¡Otro objetivo alcanzado!",
    "congrats3": "¡Wow! ¡Estás en racha! ¡Sigue así!",
    "congrats4": "¡Trabajo increíble! ¡Te estás acercando a tu meta!",
    "congrats5": "¡Trabajo increíble! ¡Tu esfuerzo está dando frutos!",
    "congratsGoal": "¡Lo hiciste! ¡Gran trabajo!",
    "congratulationsHeader": "¡Felicidades!",
    "firstEntryMessage": "¡Este es tu primer punto! ¡No olvides agregar el siguiente mañana!",
    "enterGoalWeightHere": "Ingrese su peso META aquí y luego haga clic en Agregar Peso.",
    "enterCurrentWeightHere": "Ingrese su peso ACTUAL aquí.",
    weightConflictTitle: "Conflicto de Peso",
    weightConflictMessage: "Ya existe un peso para esta fecha. ¿Qué te gustaría hacer?",
    existingWeight: "Peso existente",
    newWeight: "Nuevo peso",
    average: "Pesos promedio",
    addNew: "Agregar como nueva entrada",
    cancel: "Cancelar"
  }
  ,
  et: {
    "title": "Kaalujälgija",
    "addWeight": "Lisa kaal",
    "goalWeight": "Eesmärk kaal",
    "currentWeight": "Praegune kaal",
    "clearAll": "Kustuta kõik andmed",
    "daysToGoal": "Päevad eesmärgini:",
    "weightIn30Days": "Kaal 30 päeva pärast:",
    "percentageLeft": "Järelejäänud protsent:",
    "days": "päeva",
    "save": "SALVESTA",
    "load": "LAADI",
    "week": "Nädal",
    "month": "Kuu",
    "threeMonths": "3 kuud",
    "sixMonths": "6 kuud",
    "nineMonths": "9 kuud",
    "year": "Aasta",
    "allTime": "Kogu aeg",
    "date": "Kuupäev",
    "weight": "Kaal",
    "movingAverage": "Liikuv keskmine",
    "delete": "Kustuta",
    "points": "PUNKTE",
    "lostWeight": "Kaotatud kaal:",
    "gainWeight": "Kaalutõus:",
    "difference": "Erinevus",
    "cleanPoints": "Puhasta liigsed punktid",
    "confirmClearData": "Kas olete kindel, et soovite kõik andmed kustutada?",
    "yes": "Jah, kustuta kõik",
    "no": "Ei, hoia kõik",
    "currentGoalWeight": "Praegune / Eesmärk kaal",
    "daysTotal": "päevi kokku",
    "averageDaily": "Keskmine päevas:",
    "averageWeekly": "Keskmine nädalas:",
    "keepMonth": "Hoia 1 kuu",
    "keepWeek": "Hoia 1 nädal",
    "congrats1": "Fantastiline edasiminek! Jätka samas vaimus!",
    "congrats2": "Sa teed suurepärast tööd! Veel üks verstapost saavutatud!",
    "congrats3": "Vau! Sa oled tõeline tulija! Jätka samas vaimus!",
    "congrats4": "Hämmastav töö! Sa lähed oma eesmärgile lähemale!",
    "congrats5": "Uskumatu töö! Sinu raske töö tasub end ära!",
    "congratsGoal": "Sa tegid seda! Hea töö!",
    "congratulationsHeader": "Õnnitleme!",
    "firstEntryMessage": "See on teie esimene punkt! Ärge unustage homme järgmist lisada!",
    "enterGoalWeightHere": "Sisestage oma EESMÄRK kaal siia ja klõpsake siis Lisa kaal.",
    "enterCurrentWeightHere": "Sisestage oma PRAEGUNE kaal siia.",
    weightConflictTitle: "Kaalu konflikt",
    weightConflictMessage: "Selle kuupäeva jaoks on juba kaal olemas. Mida soovite teha?",
    existingWeight: "Olemasolev kaal",
    newWeight: "Uus kaal",
    average: "Keskmised kaalud",
    addNew: "Lisa uue kirjena",
    cancel: "Tühista"
  }
  ,
  fa: {
    "title": "ردیاب وزن",
    "addWeight": "افزودن وزن",
    "goalWeight": "وزن هدف",
    "currentWeight": "وزن کنونی",
    "clearAll": "حذف تمام داده‌ها",
    "daysToGoal": "روز تا هدف:",
    "weightIn30Days": "وزن در ۳۰ روز:",
    "percentageLeft": "درصد باقی مانده:",
    "days": "روز",
    "save": "ذخیره",
    "load": "بارگذاری",
    "week": "هفته",
    "month": "ماه",
    "threeMonths": "۳ ماه",
    "sixMonths": "۶ ماه",
    "nineMonths": "۹ ماه",
    "year": "سال",
    "allTime": "تمام وقت",
    "date": "تاریخ",
    "weight": "وزن",
    "movingAverage": "میانگین متحرک",
    "delete": "حذف",
    "points": "نقاط",
    "lostWeight": "وزن از دست رفته:",
    "gainWeight": "وزن به دست آمده:",
    "difference": "تفاوت",
    "cleanPoints": "حذف نقاط غیرضروری",
    "confirmClearData": "آیا مطمئن هستید که می‌خواهید تمام داده‌ها را حذف کنید؟",
    "yes": "بله، همه را حذف کن",
    "no": "نه، همه را نگه دار",
    "currentGoalWeight": "وزن کنونی / هدف",
    "daysTotal": "مجموع روزها",
    "averageDaily": "میانگین روزانه:",
    "averageWeekly": "میانگین هفتگی:",
    "keepMonth": "نگه داشتن ۱ ماه",
    "keepWeek": "نگه داشتن ۱ هفته",
    "congrats1": "پیشرفت فوق‌العاده! همین‌طور ادامه بده!",
    "congrats2": "عالی عمل می‌کنی! یک هدف دیگر رسیدی!",
    "congrats3": "وای! داری عالی پیش می‌روی! ادامه بده!",
    "congrats4": "کار شگفت‌انگیز! به هدف نزدیک‌تر می‌شوی!",
    "congrats5": "کار باور نکردنی! تلاش‌های تو نتیجه می‌دهد!",
    "congratsGoal": "تو موفق شدی! کار عالی!",
    "congratulationsHeader": "تبریک!",
    "firstEntryMessage": "این اولین نقطه توست! فراموش نکن که فردا نقطه بعدی را اضافه کنی!",
    "enterGoalWeightHere": "وزن هدف خود را اینجا وارد کنید، سپس روی افزودن وزن کلیک کنید.",
    "enterCurrentWeightHere": "وزن کنونی خود را اینجا وارد کنید.",
    weightConflictTitle: "تعارض وزن",
    weightConflictMessage: "برای این تاریخ قبلاً وزنی ثبت شده است. چه کاری می‌خواهید انجام دهید؟",
    existingWeight: "وزن موجود",
    newWeight: "وزن جدید",
    average: "میانگین وزن‌ها",
    addNew: "افزودن به عنوان ورودی جدید",
    cancel: "لغو"
  }
  ,
  fi: {
    "title": "Painonseuranta",
    "addWeight": "Lisää Paino",
    "goalWeight": "Tavoitepaino",
    "currentWeight": "Nykyinen Paino",
    "clearAll": "Tyhjennä Kaikki Tiedot",
    "daysToGoal": "Päiviä tavoitteeseen:",
    "weightIn30Days": "Paino 30 päivän kuluttua:",
    "percentageLeft": "Jäljellä oleva prosentti:",
    "days": "päivää",
    "save": "TALLENNA",
    "load": "LATAA",
    "week": "Viikko",
    "month": "Kuukausi",
    "threeMonths": "3 Kuukautta",
    "sixMonths": "6 Kuukautta",
    "nineMonths": "9 Kuukautta",
    "year": "Vuosi",
    "allTime": "Kaikki Aika",
    "date": "Päivämäärä",
    "weight": "Paino",
    "movingAverage": "Liukuva Keskiarvo",
    "delete": "Poista",
    "points": "PISTEET",
    "lostWeight": "Menetetty paino:",
    "gainWeight": "Saavutettu paino:",
    "difference": "Ero",
    "cleanPoints": "Poista turhat pisteet",
    "confirmClearData": "Oletko varma, että haluat tyhjentää kaikki tiedot?",
    "yes": "Kyllä, poista kaikki",
    "no": "Ei, pidä kaikki",
    "currentGoalWeight": "Nykyinen / Tavoitepaino",
    "daysTotal": "päivää yhteensä",
    "averageDaily": "Keskimääräinen päivittäin:",
    "averageWeekly": "Keskimääräinen viikoittain:",
    "keepMonth": "Pidä 1 kuukausi",
    "keepWeek": "Pidä 1 viikko",
    "congrats1": "Fantastinen edistys! Jatka samaan malliin!",
    "congrats2": "Teet loistavaa työtä! Toinen virstanpylväs saavutettu!",
    "congrats3": "Wow! Olet tulessa! Jatka painamista!",
    "congrats4": "Mahtavaa työtä! Lähestyt tavoitettasi!",
    "congrats5": "Uskomaton työ! Kovasi työ tuottaa tulosta!",
    "congratsGoal": "Teit sen! Hienoa työtä!",
    "congratulationsHeader": "Onnittelut!",
    "firstEntryMessage": "Tämä on ensimmäinen pisteesi! Älä unohda lisätä seuraavaa huomenna!",
    "enterGoalWeightHere": "Syötä TAVOITEPAINOSI tähän ja napsauta sitten Lisää Paino.",
    "enterCurrentWeightHere": "Syötä NYKYPÄINOSI tähän.",
    weightConflictTitle: "Painoristiriita",
    weightConflictMessage: "Tälle päivämäärälle on jo olemassa paino. Mitä haluat tehdä?",
    existingWeight: "Olemassa oleva paino",
    newWeight: "Uusi paino",
    average: "Keskimääräiset painot",
    addNew: "Lisää uutena merkintänä",
    cancel: "Peruuta"
  }
  ,
  fr: {
    "title": "Suivi de Poids",
    "addWeight": "Ajouter du Poids",
    "goalWeight": "Poids Cible",
    "currentWeight": "Poids Actuel",
    "clearAll": "Effacer Toutes les Données",
    "daysToGoal": "Jours jusqu'à l'objectif:",
    "weightIn30Days": "Poids dans 30 jours:",
    "percentageLeft": "Pourcentage restant:",
    "days": "jours",
    "save": "ENREGISTRER",
    "load": "CHARGER",
    "week": "Semaine",
    "month": "Mois",
    "threeMonths": "3 Mois",
    "sixMonths": "6 Mois",
    "nineMonths": "9 Mois",
    "year": "Année",
    "allTime": "Tout le Temps",
    "date": "Date",
    "weight": "Poids",
    "movingAverage": "Moyenne Mobile",
    "delete": "Supprimer",
    "points": "POINTS",
    "lostWeight": "Poids perdu:",
    "gainWeight": "Poids gagné:",
    "difference": "Différence",
    "cleanPoints": "Nettoyer les Points Redondants",
    "confirmClearData": "Êtes-vous sûr de vouloir effacer toutes les données?",
    "yes": "Oui, tout supprimer",
    "no": "Non, tout conserver",
    "currentGoalWeight": "Poids Actuel / Cible",
    "daysTotal": "jours au total",
    "averageDaily": "Moyenne quotidienne:",
    "averageWeekly": "Moyenne hebdomadaire:",
    "keepMonth": "Garder 1 Mois",
    "keepWeek": "Garder 1 Semaine",
    "congrats1": "Progrès fantastique! Continuez comme ça!",
    "congrats2": "Vous faites un excellent travail! Un autre objectif atteint!",
    "congrats3": "Wow! Vous êtes en feu! Continuez!",
    "congrats4": "Travail incroyable! Vous vous rapprochez de votre objectif!",
    "congrats5": "Travail incroyable! Votre dur labeur porte ses fruits!",
    "congratsGoal": "Vous l'avez fait! Bon travail!",
    "congratulationsHeader": "Félicitations!",
    "firstEntryMessage": "C'est votre premier point! N'oubliez pas d'ajouter le suivant demain!",
    "enterGoalWeightHere": "Entrez votre poids CIBLE ici, puis cliquez sur Ajouter du Poids.",
    "enterCurrentWeightHere": "Entrez votre poids ACTUEL ici.",
    weightConflictTitle: "Conflit de Poids",
    weightConflictMessage: "Un poids existe déjà pour cette date. Que voulez-vous faire ?",
    existingWeight: "Poids existant",
    newWeight: "Nouveau poids",
    average: "Poids moyens",
    addNew: "Ajouter comme nouvelle entrée",
    cancel: "Annuler"
  }
  ,
  gu: {
    "title": "વજન ટ્રેકર",
    "addWeight": "વજન ઉમેરો",
    "goalWeight": "લક્ષ્ય વજન",
    "currentWeight": "વર્તમાન વજન",
    "clearAll": "તમામ ડેટા સાફ કરો",
    "daysToGoal": "લક્ષ્ય સુધીના દિવસો:",
    "weightIn30Days": "30 દિવસોમાં વજન:",
    "percentageLeft": "બાકી ટકાવારી:",
    "days": "દિવસ",
    "save": "સાચવો",
    "load": "લોડ કરો",
    "week": "અઠવાડિયું",
    "month": "મહિનો",
    "threeMonths": "3 મહિના",
    "sixMonths": "6 મહિના",
    "nineMonths": "9 મહિના",
    "year": "વર્ષ",
    "allTime": "સમયગાળો",
    "date": "તારીખ",
    "weight": "વજન",
    "movingAverage": "મૂવિંગ એવરેજ",
    "delete": "કાઢી નાખો",
    "points": "પોઈન્ટ્સ",
    "lostWeight": "કમાવેલો વજન:",
    "gainWeight": "વિસ્તરેલો વજન:",
    "difference": "ફરક",
    "cleanPoints": "અનાવશ્યક પોઈન્ટ્સ સાફ કરો",
    "confirmClearData": "શું તમે ખાતરીપૂર્વક તમામ ડેટા સાફ કરવા માંગો છો?",
    "yes": "હા, બધું કાઢી નાખો",
    "no": "ના, બધું રાખો",
    "currentGoalWeight": "વર્તમાન / લક્ષ્ય વજન",
    "daysTotal": "કુલ દિવસો",
    "averageDaily": "દૈનિક સરેરાશ:",
    "averageWeekly": "સાપ્તાહિક સરેરાશ:",
    "keepMonth": "1 મહિનો રાખો",
    "keepWeek": "1 અઠવાડિયું રાખો",
    "congrats1": "અદ્ભુત પ્રગતિ! આમ જ રાખો!",
    "congrats2": "તમે ખૂબ સારું કરી રહ્યા છો! બીજું લક્ષ્ય પ્રાપ્ત કર્યું!",
    "congrats3": "વાહ! તમે ઉત્તેજિત છો! ચાલો આગળ વધો!",
    "congrats4": "શાનદાર કામ! તમે તમારા લક્ષ્યની નજીક છો!",
    "congrats5": "અવિશ્વસનીય કામ! તમારો કઠોર પરિશ્રમ ફળીભૂત થઈ રહ્યો છે!",
    "congratsGoal": "તમે તે કરી! સારું કામ!",
    "congratulationsHeader": "અભિનંદન!",
    "firstEntryMessage": "આ તમારો પહેલો પોઈન્ટ છે! કાલે બીજું ઉમેરવાનું ભૂલશો નહીં!",
    "enterGoalWeightHere": "તમારું લક્ષ્ય વજન અહીં દાખલ કરો અને પછી વજન ઉમેરો ક્લિક કરો.",
    "enterCurrentWeightHere": "તમારું વર્તમાન વજન અહીં દાખલ કરો.",
    weightConflictTitle: "વજન સંઘર્ષ",
    weightConflictMessage: "આ તારીખ માટે પહેલેથી જ એક વજન અસ્તિત્વમાં છે. તમે શું કરવા માંગો છો?",
    existingWeight: "હાલનું વજન",
    newWeight: "નવું વજન",
    average: "સરેરાશ વજન",
    addNew: "નવી એન્ટ્રી તરીકે ઉમેરો",
    cancel: "રદ કરો"
  }
  ,
  ha: {
    "title": "Mai Saurin Nauyi",
    "addWeight": "Ƙara Nauyi",
    "goalWeight": "Nauyin Manufa",
    "currentWeight": "Nauyin Yanzu",
    "clearAll": "Share Duk Bayanan",
    "daysToGoal": "Kwanaki zuwa manufa:",
    "weightIn30Days": "Nauyi a cikin kwanaki 30:",
    "percentageLeft": "Kashi da ya rage:",
    "days": "kwanaki",
    "save": "AJIYE",
    "load": "DAUKO",
    "week": "Mako",
    "month": "Wata",
    "threeMonths": "Watanni 3",
    "sixMonths": "Watanni 6",
    "nineMonths": "Watanni 9",
    "year": "Shekara",
    "allTime": "Duk Lokaci",
    "date": "Kwanan wata",
    "weight": "Nauyi",
    "movingAverage": "Matsakaicin Motsi",
    "delete": "Share",
    "points": "MAKASUDI",
    "lostWeight": "Nauyin da aka rasa:",
    "gainWeight": "Nauyin da aka ƙara:",
    "difference": "Bambanci",
    "cleanPoints": "Tsaftace Makasudin Da Suke Yawa",
    "confirmClearData": "Kuna tabbatar da cewa kuna son share duk bayanan?",
    "yes": "Eh, share duk",
    "no": "A'a, ajiye duk",
    "currentGoalWeight": "Nauyin Yanzu / Manufa",
    "daysTotal": "jimlar kwanaki",
    "averageDaily": "Matsakaicin Yau da Kullum:",
    "averageWeekly": "Matsakaicin Mako:",
    "keepMonth": "Riƙe 1-Wata",
    "keepWeek": "Riƙe 1-Mako",
    "congrats1": "Cigaba mai kyau! Ci gaba da yin hakan!",
    "congrats2": "Kana yin kyau! An cimma wani maƙasudi!",
    "congrats3": "Wow! Kana ƙoƙari sosai! Ci gaba!",
    "congrats4": "Aikin ban mamaki! Kana kusantar burinka!",
    "congrats5": "Aikin ban mamaki! Aikinka mai ƙarfi yana biya!",
    "congratsGoal": "Ka yi shi! Kyakkyawan aiki!",
    "congratulationsHeader": "Taya Murna!",
    "firstEntryMessage": "Wannan shine makasudinka na farko! Kada ka manta da ƙara na gaba gobe!",
    "enterGoalWeightHere": "Shigar da nauyin MANUFA a nan, sannan danna Ƙara Nauyi.",
    "enterCurrentWeightHere": "Shigar da nauyin YANZU a nan.",
    weightConflictTitle: "Rikicin Nauyi",
    weightConflictMessage: "Akwai nauyi da ya riga ya kasance don wannan rana. Menene kake son yi?",
    existingWeight: "Nauyin da ke nan",
    newWeight: "Sabon nauyi",
    average: "Nauyin matsayi",
    addNew: "Ƙara a matsayin sabon shigarwa",
    cancel: "Soke"
  }
  ,
  hr: {
    "title": "Praćenje Težine",
    "addWeight": "Dodaj Težinu",
    "goalWeight": "Ciljna Težina",
    "currentWeight": "Trenutna Težina",
    "clearAll": "Obriši Sve Podatke",
    "daysToGoal": "Dani do cilja:",
    "weightIn30Days": "Težina za 30 dana:",
    "percentageLeft": "Preostali postotak:",
    "days": "dana",
    "save": "SPREMI",
    "load": "UČITAJ",
    "week": "Tjedan",
    "month": "Mjesec",
    "threeMonths": "3 Mjeseca",
    "sixMonths": "6 Mjeseci",
    "nineMonths": "9 Mjeseci",
    "year": "Godina",
    "allTime": "Cijelo Vrijeme",
    "date": "Datum",
    "weight": "Težina",
    "movingAverage": "Pokretni Prosjek",
    "delete": "Obriši",
    "points": "TOČKE",
    "lostWeight": "Izgubljena težina:",
    "gainWeight": "Dobivena težina:",
    "difference": "Razlika",
    "cleanPoints": "Očisti Suvišne Točke",
    "confirmClearData": "Jeste li sigurni da želite obrisati sve podatke?",
    "yes": "Da, obriši sve",
    "no": "Ne, zadrži sve",
    "currentGoalWeight": "Trenutna / Ciljna Težina",
    "daysTotal": "ukupno dana",
    "averageDaily": "Prosjek Dnevno:",
    "averageWeekly": "Prosjek Tjedno:",
    "keepMonth": "Zadrži 1-Mjesec",
    "keepWeek": "Zadrži 1-Tjedan",
    "congrats1": "Fantastičan napredak! Nastavi tako!",
    "congrats2": "Odlično ti ide! Još jedan cilj postignut!",
    "congrats3": "Wow! Stvarno si na vatri! Nastavi gurati!",
    "congrats4": "Nevjerojatan posao! Sve si bliže svom cilju!",
    "congrats5": "Nevjerojatan posao! Tvoj trud se isplaćuje!",
    "congratsGoal": "Uspio si! Odličan posao!",
    "congratulationsHeader": "Čestitamo!",
    "firstEntryMessage": "Ovo je tvoj prvi unos! Ne zaboravi dodati sljedeći sutra!",
    "enterGoalWeightHere": "Unesi svoju CILJNU težinu ovdje, zatim klikni Dodaj Težinu.",
    "enterCurrentWeightHere": "Unesi svoju TRENUTNU težinu ovdje.",
    weightConflictTitle: "Sukob težine",
    weightConflictMessage: "Već postoji težina za ovaj datum. Što želite učiniti?",
    existingWeight: "Postojeća težina",
    newWeight: "Nova težina",
    average: "Prosječne težine",
    addNew: "Dodaj kao novi unos",
    cancel: "Odustani"
  }
  ,
  it: {
    "title": "Tracker del Peso",
    "addWeight": "Aggiungi Peso",
    "goalWeight": "Peso Obiettivo",
    "currentWeight": "Peso Attuale",
    "clearAll": "Cancella Tutti i Dati",
    "daysToGoal": "Giorni all'obiettivo:",
    "weightIn30Days": "Peso in 30 giorni:",
    "percentageLeft": "Percentuale rimanente:",
    "days": "giorni",
    "save": "SALVA",
    "load": "CARICA",
    "week": "Settimana",
    "month": "Mese",
    "threeMonths": "3 Mesi",
    "sixMonths": "6 Mesi",
    "nineMonths": "9 Mesi",
    "year": "Anno",
    "allTime": "Tutto il Tempo",
    "date": "Data",
    "weight": "Peso",
    "movingAverage": "Media Mobile",
    "delete": "Elimina",
    "points": "PUNTI",
    "lostWeight": "Peso perso:",
    "gainWeight": "Peso guadagnato:",
    "difference": "Differenza",
    "cleanPoints": "Pulisci Punti Ridondanti",
    "confirmClearData": "Sei sicuro di voler cancellare tutti i dati?",
    "yes": "Sì, cancella tutto",
    "no": "No, mantieni tutto",
    "currentGoalWeight": "Peso Attuale / Obiettivo",
    "daysTotal": "giorni totali",
    "averageDaily": "Media Giornaliera:",
    "averageWeekly": "Media Settimanale:",
    "keepMonth": "Mantieni 1 Mese",
    "keepWeek": "Mantieni 1 Settimana",
    "congrats1": "Progresso fantastico! Continua così!",
    "congrats2": "Stai andando alla grande! Un altro traguardo raggiunto!",
    "congrats3": "Wow! Sei incredibile! Continua così!",
    "congrats4": "Lavoro incredibile! Sei sempre più vicino al tuo obiettivo!",
    "congrats5": "Lavoro incredibile! Il tuo duro lavoro sta pagando!",
    "congratsGoal": "Ce l'hai fatta! Ottimo lavoro!",
    "congratulationsHeader": "Congratulazioni!",
    "firstEntryMessage": "Questo è il tuo primo punto! Non dimenticare di aggiungere il prossimo domani!",
    "enterGoalWeightHere": "Inserisci qui il tuo PESO OBIETTIVO, quindi fai clic su Aggiungi Peso.",
    "enterCurrentWeightHere": "Inserisci qui il tuo PESO ATTUALE.",
    weightConflictTitle: "Conflitto di Peso",
    weightConflictMessage: "Esiste già un peso per questa data. Cosa vorresti fare?",
    existingWeight: "Peso esistente",
    newWeight: "Nuovo peso",
    average: "Pesi medi",
    addNew: "Aggiungi come nuova voce",
    cancel: "Annulla"
  }
  ,
  ja: {
    "title": "体重トラッカー",
    "addWeight": "体重を追加",
    "goalWeight": "目標体重",
    "currentWeight": "現在の体重",
    "clearAll": "すべてのデータをクリア",
    "daysToGoal": "目標までの日数:",
    "weightIn30Days": "30日後の体重:",
    "percentageLeft": "残りの割合:",
    "days": "日",
    "save": "保存",
    "load": "読み込む",
    "week": "週間",
    "month": "月",
    "threeMonths": "3ヶ月",
    "sixMonths": "6ヶ月",
    "nineMonths": "9ヶ月",
    "year": "年",
    "allTime": "全期間",
    "date": "日付",
    "weight": "体重",
    "movingAverage": "移動平均",
    "delete": "削除",
    "points": "ポイント",
    "lostWeight": "減少した体重:",
    "gainWeight": "増加した体重:",
    "difference": "差異",
    "cleanPoints": "冗長なポイントをクリア",
    "confirmClearData": "すべてのデータをクリアしてもよろしいですか？",
    "yes": "はい、すべて削除",
    "no": "いいえ、すべて保持",
    "currentGoalWeight": "現在の体重 / 目標体重",
    "daysTotal": "合計日数",
    "averageDaily": "日平均:",
    "averageWeekly": "週平均:",
    "keepMonth": "1ヶ月保持",
    "keepWeek": "1週間保持",
    "congrats1": "素晴らしい進歩です！この調子で！",
    "congrats2": "とても良い調子です！もう一つの目標達成です！",
    "congrats3": "素晴らしい！あなたは絶好調です！続けてください！",
    "congrats4": "素晴らしい仕事です！目標に近づいています！",
    "congrats5": "素晴らしい仕事です！あなたの努力が報われています！",
    "congratsGoal": "やりました！素晴らしい仕事です！",
    "congratulationsHeader": "おめでとうございます！",
    "firstEntryMessage": "これが最初のポイントです！明日も忘れずに追加してください！",
    "enterGoalWeightHere": "ここに目標体重を入力し、「体重を追加」をクリックしてください。",
    "enterCurrentWeightHere": "ここに現在の体重を入力してください。",
    weightConflictTitle: "体重の競合",
    weightConflictMessage: "この日付には既に体重が存在します。どうしますか？",
    existingWeight: "既存の体重",
    newWeight: "新しい体重",
    average: "平均体重",
    addNew: "新しいエントリーとして追加",
    cancel: "キャンセル"
  }
  ,
  ka: {
    "title": "წონის მონიტორი",
    "addWeight": "წონის დამატება",
    "goalWeight": "სასურველი წონა",
    "currentWeight": "მიმდინარე წონა",
    "clearAll": "მონაცემების წაშლა",
    "daysToGoal": "სასურველ წონამდე დღეები:",
    "weightIn30Days": "წონა 30 დღეში:",
    "percentageLeft": "დარჩენილი პროცენტი:",
    "days": "დღეები",
    "save": "შენახვა",
    "load": "ჩატვირთვა",
    "week": "კვირა",
    "month": "თვე",
    "threeMonths": "3 თვე",
    "sixMonths": "6 თვე",
    "nineMonths": "9 თვე",
    "year": "წელი",
    "allTime": "ყველა დრო",
    "date": "თარიღი",
    "weight": "წონა",
    "movingAverage": "მოძრავი საშუალო",
    "delete": "წაშლა",
    "points": "ქულები",
    "lostWeight": "დაკლებული წონა:",
    "gainWeight": "მატებული წონა:",
    "difference": "განსხვავება",
    "cleanPoints": "მიმართების გასუფთავება",
    "confirmClearData": "დარწმუნებული ხართ, რომ გსურთ ყველა მონაცემის წაშლა?",
    "yes": "დიახ, წაშალე ყველა",
    "no": "არა, შეინახე ყველა",
    "currentGoalWeight": "მიმდინარე / სასურველი წონა",
    "daysTotal": "სულ დღეები",
    "averageDaily": "დღიური საშუალო:",
    "averageWeekly": "კვირის საშუალო:",
    "keepMonth": "შეინახე 1 თვე",
    "keepWeek": "შეინახე 1 კვირა",
    "congrats1": "ფანტასტიკური პროგრესი! განაგრძე ასე!",
    "congrats2": "შენ ძალიან კარგად აკეთებ! კიდევ ერთი მიზანი მიღწეულია!",
    "congrats3": "ვაუ! შენ მაგარ ხარ! განაგრძე!",
    "congrats4": "შესანიშნავი მუშაობა! უფრო და უფრო ახლოს ხარ მიზანთან!",
    "congrats5": "შესანიშნავი მუშაობა! შენი შრომა ამართლებს!",
    "congratsGoal": "შენ გააკეთე ეს! შესანიშნავი მუშაობა!",
    "congratulationsHeader": "გილოცავთ!",
    "firstEntryMessage": "ეს შენი პირველი პუნქტია! არ დაივიწყო შემდეგის დამატება ხვალ!",
    "enterGoalWeightHere": "შეიყვანე შენი სასურველი წონა აქ, შემდეგ დააჭირე 'წონის დამატება'.",
    "enterCurrentWeightHere": "შეიყვანე შენი მიმდინარე წონა აქ.",
    weightConflictTitle: "წონის კონფლიქტი",
    weightConflictMessage: "ამ თარიღისთვის უკვე არსებობს წონა. რა გსურთ გააკეთოთ?",
    existingWeight: "არსებული წონა",
    newWeight: "ახალი წონა",
    average: "საშუალო წონები",
    addNew: "დაამატეთ ახალი ჩანაწერის სახით",
    cancel: "გაუქმება"
  }
  ,
  kk: {
    "title": "Салмақ бақылау",
    "addWeight": "Салмақ қосу",
    "goalWeight": "Мақсат салмағы",
    "currentWeight": "Ағымдағы салмақ",
    "clearAll": "Барлық деректерді жою",
    "daysToGoal": "Мақсатқа дейінгі күндер:",
    "weightIn30Days": "30 күндегі салмақ:",
    "percentageLeft": "Қалған пайыз:",
    "days": "күндер",
    "save": "САҚТАУ",
    "load": "ЖҮКТЕУ",
    "week": "Апта",
    "month": "Ай",
    "threeMonths": "3 Ай",
    "sixMonths": "6 Ай",
    "nineMonths": "9 Ай",
    "year": "Жыл",
    "allTime": "Барлық уақыт",
    "date": "Күні",
    "weight": "Салмақ",
    "movingAverage": "Орташа мән",
    "delete": "Жою",
    "points": "ҰПАЙЛАР",
    "lostWeight": "Жоғалған салмақ:",
    "gainWeight": "Қосылған салмақ:",
    "difference": "Айырмашылық",
    "cleanPoints": "Қажетсіз нүктелерді тазалау",
    "confirmClearData": "Барлық деректерді жоюды қалайсыз ба?",
    "yes": "Иә, бәрін жою",
    "no": "Жоқ, бәрін сақтау",
    "currentGoalWeight": "Ағымдағы / Мақсат салмағы",
    "daysTotal": "барлық күндер",
    "averageDaily": "Орташа күнделікті:",
    "averageWeekly": "Орташа апталық:",
    "keepMonth": "1 Ай сақтау",
    "keepWeek": "1 Апта сақтау",
    "congrats1": "Керемет жетістік! Жалғастырыңыз!",
    "congrats2": "Тамаша орындадыңыз! Тағы бір мақсат орындалды!",
    "congrats3": "Оу! Сіз кереметсіз! Жалғастырыңыз!",
    "congrats4": "Тамаша жұмыс! Мақсатыңызға жақынсыз!",
    "congrats5": "Тамаша жұмыс! Сіздің еңбегіңіз ақталады!",
    "congratsGoal": "Сіз мұны жасадыңыз! Тамаша жұмыс!",
    "congratulationsHeader": "Құттықтаймыз!",
    "firstEntryMessage": "Бұл сіздің алғашқы нүктеңіз! Ертеңгі нүктені қосуды ұмытпаңыз!",
    "enterGoalWeightHere": "Мақсат салмағыңызды осында енгізіп, Салмақ қосу түймесін басыңыз.",
    "enterCurrentWeightHere": "Ағымдағы салмағыңызды осында енгізіңіз.",
    weightConflictTitle: "Салмақ қақтығысы",
    weightConflictMessage: "Бұл күнге салмақ қазірдің өзінде бар. Не істегіңіз келеді?",
    existingWeight: "Қазіргі салмақ",
    newWeight: "Жаңа салмақ",
    average: "Орташа салмақтар",
    addNew: "Жаңа жазба ретінде қосу",
    cancel: "Болдырмау"
  }
  ,
  ko: {
    "title": "체중 추적기",
    "addWeight": "체중 추가",
    "goalWeight": "목표 체중",
    "currentWeight": "현재 체중",
    "clearAll": "모든 데이터 지우기",
    "daysToGoal": "목표까지의 일수:",
    "weightIn30Days": "30일 후 체중:",
    "percentageLeft": "남은 비율:",
    "days": "일",
    "save": "저장",
    "load": "로드",
    "week": "주",
    "month": "월",
    "threeMonths": "3개월",
    "sixMonths": "6개월",
    "nineMonths": "9개월",
    "year": "년",
    "allTime": "전체 시간",
    "date": "날짜",
    "weight": "체중",
    "movingAverage": "이동 평균",
    "delete": "삭제",
    "points": "포인트",
    "lostWeight": "감량된 체중:",
    "gainWeight": "증가된 체중:",
    "difference": "차이",
    "cleanPoints": "중복 포인트 정리",
    "confirmClearData": "모든 데이터를 지우시겠습니까?",
    "yes": "예, 모두 삭제",
    "no": "아니요, 모두 유지",
    "currentGoalWeight": "현재 / 목표 체중",
    "daysTotal": "총 일수",
    "averageDaily": "일일 평균:",
    "averageWeekly": "주간 평균:",
    "keepMonth": "1개월 유지",
    "keepWeek": "1주 유지",
    "congrats1": "놀라운 진전입니다! 계속하세요!",
    "congrats2": "정말 잘하고 있어요! 또 하나의 목표 달성!",
    "congrats3": "우와! 정말 멋져요! 계속 밀어붙이세요!",
    "congrats4": "놀라운 작업입니다! 목표에 점점 가까워지고 있어요!",
    "congrats5": "굉장한 작업입니다! 당신의 노력이 결실을 맺고 있어요!",
    "congratsGoal": "해냈어요! 훌륭한 작업!",
    "congratulationsHeader": "축하합니다!",
    "firstEntryMessage": "이것이 첫 번째 포인트입니다! 내일도 추가하는 것을 잊지 마세요!",
    "enterGoalWeightHere": "여기에 목표 체중을 입력한 후 체중 추가를 클릭하세요.",
    "enterCurrentWeightHere": "여기에 현재 체중을 입력하세요.",
    weightConflictTitle: "체중 충돌",
    weightConflictMessage: "이 날짜에 대한 체중이 이미 존재합니다. 어떻게 하시겠습니까?",
    existingWeight: "기존 체중",
    newWeight: "새 체중",
    average: "평균 체중",
    addNew: "새 항목으로 추가",
    cancel: "취소"
  }
  ,
  ky: {
    "title": "Салмак көзөмөлдөө",
    "addWeight": "Салмак кошуу",
    "goalWeight": "Максат салмагы",
    "currentWeight": "Учурдагы салмак",
    "clearAll": "Бардык маалыматтарды өчүрүү",
    "daysToGoal": "Максатка күндөр:",
    "weightIn30Days": "30 күндөн кийин салмак:",
    "percentageLeft": "Калган пайыз:",
    "days": "күндөр",
    "save": "САКТОО",
    "load": "ЖҮКТӨӨ",
    "week": "Апта",
    "month": "Ай",
    "threeMonths": "3 Ай",
    "sixMonths": "6 Ай",
    "nineMonths": "9 Ай",
    "year": "Жыл",
    "allTime": "Бардык убакыт",
    "date": "Күнү",
    "weight": "Салмак",
    "movingAverage": "Орто чен",
    "delete": "Өчүрүү",
    "points": "ПАЙДАЛУУ ПАЙДАЛАР",
    "lostWeight": "Жоголгон салмак:",
    "gainWeight": "Кошулган салмак:",
    "difference": "Айырмачылык",
    "cleanPoints": "Артыкча пункттарды тазалоо",
    "confirmClearData": "Бардык маалыматтарды өчүрүүгө ишенимдүүсүзбү?",
    "yes": "Ооба, баарын өчүрүү",
    "no": "Жок, баарын сактоо",
    "currentGoalWeight": "Учурдагы / Максат салмагы",
    "daysTotal": "бардык күндөр",
    "averageDaily": "Орто ченде күндөлүк:",
    "averageWeekly": "Орто ченде жума сайын:",
    "keepMonth": "1 Ай сактоо",
    "keepWeek": "1 Апта сактоо",
    "congrats1": "Керемет прогресс! Улантыңыз!",
    "congrats2": "Сиз сонун жасап жатасыз! Дагы бир максатка жеттиңиз!",
    "congrats3": "Вау! Сиз оттосуз! Улантыңыз!",
    "congrats4": "Керемет иш! Сиз максатыңызга жакындап жатасыз!",
    "congrats5": "Тамаша иш! Сиздин эмгегиңиз натыйжа берет!",
    "congratsGoal": "Сиз муну жасадыңыз! Сонун иш!",
    "congratulationsHeader": "Куттуктайбыз!",
    "firstEntryMessage": "Бул сиздин биринчи пунктуңуз! Эртең дагы кошууну унутпаңыз!",
    "enterGoalWeightHere": "Максат салмагыңызды бул жерге киргизиңиз, андан кийин Салмак кошуу баскычын басыңыз.",
    "enterCurrentWeightHere": "Учурдагы салмагыңызды бул жерге киргизиңиз.",
    weightConflictTitle: "Салмак конфликти",
    weightConflictMessage: "Бул күнгө салмак мурунтан эле бар. Эмне кылгыңыз келет?",
    existingWeight: "Учурдагы салмак",
    newWeight: "Жаңы салмак",
    average: "Орточо салмактар",
    addNew: "Жаңы жазуу катары кошуу",
    cancel: "Жокко чыгаруу"
  }
  ,
  lt: {
    "title": "Svorio Stebėjimo Priemonė",
    "addWeight": "Pridėti Svorį",
    "goalWeight": "Tikslinis Svoris",
    "currentWeight": "Dabartinis Svoris",
    "clearAll": "Išvalyti Visus Duomenis",
    "daysToGoal": "Dienos iki tikslo:",
    "weightIn30Days": "Svoris per 30 dienų:",
    "percentageLeft": "Likutis procentais:",
    "days": "dienos",
    "save": "IŠSAUGOTI",
    "load": "PAKRAUTI",
    "week": "Savaitė",
    "month": "Mėnuo",
    "threeMonths": "3 Mėnesiai",
    "sixMonths": "6 Mėnesiai",
    "nineMonths": "9 Mėnesiai",
    "year": "Metai",
    "allTime": "Visas Laikas",
    "date": "Data",
    "weight": "Svoris",
    "movingAverage": "Slenkantis Vidurkis",
    "delete": "Ištrinti",
    "points": "TAŠKAI",
    "lostWeight": "Prarastas Svoris:",
    "gainWeight": "Gautas Svoris:",
    "difference": "Skirtumas",
    "cleanPoints": "Išvalyti nereikalingus taškus",
    "confirmClearData": "Ar tikrai norite išvalyti visus duomenis?",
    "yes": "Taip, ištrinti viską",
    "no": "Ne, išsaugoti viską",
    "currentGoalWeight": "Dabartinis / Tikslinis Svoris",
    "daysTotal": "iš viso dienų",
    "averageDaily": "Vidutiniškai per dieną:",
    "averageWeekly": "Vidutiniškai per savaitę:",
    "keepMonth": "Išlaikyti 1 mėnesį",
    "keepWeek": "Išlaikyti 1 savaitę",
    "congrats1": "Fantastiška pažanga! Tęskite!",
    "congrats2": "Jūs darote puikų darbą! Dar vienas tikslas pasiektas!",
    "congrats3": "Oho! Jūs esate nuostabus! Tęskite taip!",
    "congrats4": "Nuostabus darbas! Jūs artėjate prie savo tikslo!",
    "congrats5": "Neįtikėtinas darbas! Jūsų sunkus darbas atsiperka!",
    "congratsGoal": "Jūs tai padarėte! Puikus darbas!",
    "congratulationsHeader": "Sveikiname!",
    "firstEntryMessage": "Tai jūsų pirmasis taškas! Nepamirškite pridėti kitą rytoj!",
    "enterGoalWeightHere": "Įveskite savo TIKSLINĮ SVORĮ čia ir spustelėkite Pridėti Svorį.",
    "enterCurrentWeightHere": "Įveskite savo DABARTINĮ SVORĮ čia.",
    weightConflictTitle: "Svorio konfliktas",
    weightConflictMessage: "Šiai datai jau egzistuoja svoris. Ką norėtumėte daryti?",
    existingWeight: "Esamas svoris",
    newWeight: "Naujas svoris",
    average: "Vidutiniai svoriai",
    addNew: "Pridėti kaip naują įrašą",
    cancel: "Atšaukti"
  }
  ,
  lv: {
    "title": "Svara Sekotājs",
    "addWeight": "Pievienot Svaru",
    "goalWeight": "Mērķa Svars",
    "currentWeight": "Pašreizējais Svars",
    "clearAll": "Dzēst Visus Datus",
    "daysToGoal": "Dienas līdz mērķim:",
    "weightIn30Days": "Svars pēc 30 dienām:",
    "percentageLeft": "Atlikušais procents:",
    "days": "dienas",
    "save": "SAGLABĀT",
    "load": "IEKRAUT",
    "week": "Nedēļa",
    "month": "Mēnesis",
    "threeMonths": "3 Mēneši",
    "sixMonths": "6 Mēneši",
    "nineMonths": "9 Mēneši",
    "year": "Gads",
    "allTime": "Visu Laiku",
    "date": "Datums",
    "weight": "Svars",
    "movingAverage": "Slīdošais Vidējais",
    "delete": "Dzēst",
    "points": "PUNKTI",
    "lostWeight": "Zaudētais Svars:",
    "gainWeight": "Iegūtais Svars:",
    "difference": "Atšķirība",
    "cleanPoints": "Tīrīt Lieko Punktus",
    "confirmClearData": "Vai tiešām vēlaties dzēst visus datus?",
    "yes": "Jā, dzēst visu",
    "no": "Nē, paturēt visu",
    "currentGoalWeight": "Pašreizējais / Mērķa Svars",
    "daysTotal": "kopējās dienas",
    "averageDaily": "Vidēji Dienā:",
    "averageWeekly": "Vidēji Nedēļā:",
    "keepMonth": "Saglabāt 1-Mēnesi",
    "keepWeek": "Saglabāt 1-Nedēļu",
    "congrats1": "Fantastiskais progress! Turpiniet tāpat!",
    "congrats2": "Jūs darāt lielisku darbu! Vēl viens mērķis sasniegts!",
    "congrats3": "Wow! Jūs esat lieliski! Turpiniet tāpat!",
    "congrats4": "Brīnišķīgs darbs! Jūs esat tuvāk savam mērķim!",
    "congrats5": "Neticams darbs! Jūsu smagais darbs atmaksājas!",
    "congratsGoal": "Jūs to izdarījāt! Lielisks darbs!",
    "congratulationsHeader": "Apsveicam!",
    "firstEntryMessage": "Šis ir jūsu pirmais punkts! Neaizmirstiet pievienot nākamo rīt!",
    "enterGoalWeightHere": "Ievadiet šeit savu MĒRĶA SVARU un noklikšķiniet uz Pievienot Svaru.",
    "enterCurrentWeightHere": "Ievadiet šeit savu PAŠREIZĒJO SVARU.",
    weightConflictTitle: "Svara konflikts",
    weightConflictMessage: "Šim datumam jau eksistē svars. Ko jūs vēlaties darīt?",
    existingWeight: "Esošais svars",
    newWeight: "Jaunais svars",
    average: "Vidējie svari",
    addNew: "Pievienot kā jaunu ierakstu",
    cancel: "Atcelt"
  }
  ,
  md: {
    "title": "Urmăritor Greutate",
    "addWeight": "Adăugați Greutate",
    "goalWeight": "Greutate Țintă",
    "currentWeight": "Greutate Curentă",
    "clearAll": "Ștergeți Toate Datele",
    "daysToGoal": "Zile până la țintă:",
    "weightIn30Days": "Greutate în 30 de zile:",
    "percentageLeft": "Procent rămas:",
    "days": "zile",
    "save": "SALVAȚI",
    "load": "ÎNCĂRCAȚI",
    "week": "Săptămână",
    "month": "Lună",
    "threeMonths": "3 Luni",
    "sixMonths": "6 Luni",
    "nineMonths": "9 Luni",
    "year": "An",
    "allTime": "Tot Timpul",
    "date": "Data",
    "weight": "Greutate",
    "movingAverage": "Medie Mobilă",
    "delete": "Șterge",
    "points": "PUNCTE",
    "lostWeight": "Greutate Pierdută:",
    "gainWeight": "Greutate Câștigată:",
    "difference": "Diferență",
    "cleanPoints": "Curățați Punctele Redundante",
    "confirmClearData": "Sunteți sigur că doriți să ștergeți toate datele?",
    "yes": "Da, ștergeți tot",
    "no": "Nu, păstrați tot",
    "currentGoalWeight": "Greutate Curentă / Țintă",
    "daysTotal": "zile totale",
    "averageDaily": "Medie Zilnică:",
    "averageWeekly": "Medie Săptămânală:",
    "keepMonth": "Păstrați 1-Lună",
    "keepWeek": "Păstrați 1-Săptămână",
    "congrats1": "Progres fantastic! Continuați așa!",
    "congrats2": "Faceți o treabă excelentă! Un alt obiectiv atins!",
    "congrats3": "Wow! Sunteți incredibil! Continuați!",
    "congrats4": "Muncă uimitoare! Sunteți aproape de obiectiv!",
    "congrats5": "Muncă incredibilă! Efortul dvs. dă roade!",
    "congratsGoal": "Ați reușit! Treabă bună!",
    "congratulationsHeader": "Felicitări!",
    "firstEntryMessage": "Acesta este primul dvs. punct! Nu uitați să adăugați următorul mâine!",
    "enterGoalWeightHere": "Introduceți aici greutatea dvs. ȚINTĂ, apoi faceți clic pe Adăugați Greutate.",
    "enterCurrentWeightHere": "Introduceți aici greutatea dvs. CURENTĂ.",
    weightConflictTitle: "Conflict de greutate",
    weightConflictMessage: "Există deja o greutate pentru această dată. Ce doriți să faceți?",
    existingWeight: "Greutatea existentă",
    newWeight: "Greutate nouă",
    average: "Greutăți medii",
    addNew: "Adăugați ca intrare nouă",
    cancel: "Anulare"
  }
  ,
  nan: {
    "title": "重量追踪器",
    "addWeight": "添加重量",
    "goalWeight": "目标重量",
    "currentWeight": "当前重量",
    "clearAll": "清除所有数据",
    "daysToGoal": "达到目标的天数:",
    "weightIn30Days": "30天后的重量:",
    "percentageLeft": "剩余百分比:",
    "days": "天",
    "save": "保存",
    "load": "加载",
    "week": "周",
    "month": "月",
    "threeMonths": "3个月",
    "sixMonths": "6个月",
    "nineMonths": "9个月",
    "year": "年",
    "allTime": "所有时间",
    "date": "日期",
    "weight": "重量",
    "movingAverage": "移动平均",
    "delete": "删除",
    "points": "点数",
    "lostWeight": "减轻的重量:",
    "gainWeight": "增加的重量:",
    "difference": "差异",
    "cleanPoints": "清除多余点数",
    "confirmClearData": "您确定要清除所有数据吗？",
    "yes": "是的，删除所有",
    "no": "不，保留所有",
    "currentGoalWeight": "当前/目标重量",
    "daysTotal": "总天数",
    "averageDaily": "每日平均:",
    "averageWeekly": "每周平均:",
    "keepMonth": "保留1个月",
    "keepWeek": "保留1周",
    "congrats1": "进展神速！继续加油！",
    "congrats2": "你做得很好！又一个目标达成！",
    "congrats3": "哇！你真棒！继续努力！",
    "congrats4": "好极了！你离目标越来越近了！",
    "congrats5": "不可思议的工作！你的努力得到了回报！",
    "congratsGoal": "你做到了！干得好！",
    "congratulationsHeader": "恭喜！",
    "firstEntryMessage": "这是你的第一个点！别忘了明天再添加一个！",
    "enterGoalWeightHere": "在这里输入你的目标重量，然后点击添加重量。",
    "enterCurrentWeightHere": "在这里输入你的当前重量。",
    weightConflictTitle: "重量衝突",
    weightConflictMessage: "這個日期已經存在一個重量。你想做什麼？",
    existingWeight: "現有重量",
    newWeight: "新重量",
    average: "平均重量",
    addNew: "添加為新條目",
    cancel: "取消"
  }
  ,
  nl: {
    "title": "Gewichtsvolger",
    "addWeight": "Voeg Gewicht Toe",
    "goalWeight": "Streefgewicht",
    "currentWeight": "Huidig Gewicht",
    "clearAll": "Alle Gegevens Wissen",
    "daysToGoal": "Dagen tot doel:",
    "weightIn30Days": "Gewicht over 30 dagen:",
    "percentageLeft": "Overgebleven percentage:",
    "days": "dagen",
    "save": "OPSLAAN",
    "load": "LADEN",
    "week": "Week",
    "month": "Maand",
    "threeMonths": "3 Maanden",
    "sixMonths": "6 Maanden",
    "nineMonths": "9 Maanden",
    "year": "Jaar",
    "allTime": "Alle Tijd",
    "date": "Datum",
    "weight": "Gewicht",
    "movingAverage": "Voortschrijdend Gemiddelde",
    "delete": "Verwijderen",
    "points": "PUNTEN",
    "lostWeight": "Verloren Gewicht:",
    "gainWeight": "Gewonnen Gewicht:",
    "difference": "Verschil",
    "cleanPoints": "Overbodige Punten Verwijderen",
    "confirmClearData": "Weet u zeker dat u alle gegevens wilt wissen?",
    "yes": "Ja, verwijder alles",
    "no": "Nee, alles behouden",
    "currentGoalWeight": "Huidig / Streefgewicht",
    "daysTotal": "totaal aantal dagen",
    "averageDaily": "Gemiddeld Dagelijks:",
    "averageWeekly": "Gemiddeld Wekelijks:",
    "keepMonth": "Bewaar 1 Maand",
    "keepWeek": "Bewaar 1 Week",
    "congrats1": "Fantastische vooruitgang! Ga zo door!",
    "congrats2": "Je doet het geweldig! Weer een mijlpaal bereikt!",
    "congrats3": "Wauw! Je bent geweldig bezig! Ga zo door!",
    "congrats4": "Geweldig werk! Je komt dichter bij je doel!",
    "congrats5": "Ongelooflijk werk! Je harde werk loont!",
    "congratsGoal": "Je hebt het gehaald! Goed gedaan!",
    "congratulationsHeader": "Gefeliciteerd!",
    "firstEntryMessage": "Dit is je eerste punt! Vergeet niet morgen de volgende toe te voegen!",
    "enterGoalWeightHere": "Voer hier je STREEFGEWICHT in en klik vervolgens op Voeg Gewicht Toe.",
    "enterCurrentWeightHere": "Voer hier je HUIDIGE GEWICHT in.",
    weightConflictTitle: "Gewichtsconflict",
    weightConflictMessage: "Er bestaat al een gewicht voor deze datum. Wat wilt u doen?",
    existingWeight: "Bestaand gewicht",
    newWeight: "Nieuw gewicht",
    average: "Gemiddelde gewichten",
    addNew: "Toevoegen als nieuwe invoer",
    cancel: "Annuleren"
  }
  ,
  no: {
    "title": "Vekt Tracker",
    "addWeight": "Legg til Vekt",
    "goalWeight": "Målvekt",
    "currentWeight": "Nåværende Vekt",
    "clearAll": "Slett All Data",
    "daysToGoal": "Dager til mål:",
    "weightIn30Days": "Vekt om 30 dager:",
    "percentageLeft": "Gjenværende prosent:",
    "days": "dager",
    "save": "LAGRE",
    "load": "LASTE",
    "week": "Uke",
    "month": "Måned",
    "threeMonths": "3 Måneder",
    "sixMonths": "6 Måneder",
    "nineMonths": "9 Måneder",
    "year": "År",
    "allTime": "Hele tiden",
    "date": "Dato",
    "weight": "Vekt",
    "movingAverage": "Glidende Gjennomsnitt",
    "delete": "Slett",
    "points": "POENG",
    "lostWeight": "Tapt vekt:",
    "gainWeight": "Økt vekt:",
    "difference": "Forskjell",
    "cleanPoints": "Rens Overflødige Poeng",
    "confirmClearData": "Er du sikker på at du vil slette all data?",
    "yes": "Ja, slett alt",
    "no": "Nei, behold alt",
    "currentGoalWeight": "Nåværende / Målvekt",
    "daysTotal": "totalt antall dager",
    "averageDaily": "Gjennomsnittlig Daglig:",
    "averageWeekly": "Gjennomsnittlig Ukentlig:",
    "keepMonth": "Behold 1-Måned",
    "keepWeek": "Behold 1-Uke",
    "congrats1": "Fantastisk fremgang! Fortsett slik!",
    "congrats2": "Du gjør det kjempebra! Enda en milepæl nådd!",
    "congrats3": "Wow! Du er på en bølge! Fortsett!",
    "congrats4": "Utrolig arbeid! Du nærmer deg målet ditt!",
    "congrats5": "Utrolig arbeid! Din innsats lønner seg!",
    "congratsGoal": "Du klarte det! Godt arbeid!",
    "congratulationsHeader": "Gratulerer!",
    "firstEntryMessage": "Dette er ditt første punkt! Ikke glem å legge til neste i morgen!",
    "enterGoalWeightHere": "Skriv inn din MÅLVEKT her og klikk deretter på Legg til Vekt.",
    "enterCurrentWeightHere": "Skriv inn din NÅVÆRENDE VEKT her.",
    weightConflictTitle: "Vektkonflikt",
    weightConflictMessage: "Det eksisterer allerede en vekt for denne datoen. Hva vil du gjøre?",
    existingWeight: "Eksisterende vekt",
    newWeight: "Ny vekt",
    average: "Gjennomsnittlige vekter",
    addNew: "Legg til som ny oppføring",
    cancel: "Avbryt"
  }
  ,
  pl: {
    "title": "Śledzenie Wagi",
    "addWeight": "Dodaj Wagę",
    "goalWeight": "Waga Docelowa",
    "currentWeight": "Obecna Waga",
    "clearAll": "Wyczyść Wszystkie Dane",
    "daysToGoal": "Dni do celu:",
    "weightIn30Days": "Waga za 30 dni:",
    "percentageLeft": "Pozostały procent:",
    "days": "dni",
    "save": "ZAPISZ",
    "load": "ŁADUJ",
    "week": "Tydzień",
    "month": "Miesiąc",
    "threeMonths": "3 Miesiące",
    "sixMonths": "6 Miesięcy",
    "nineMonths": "9 Miesięcy",
    "year": "Rok",
    "allTime": "Cały Czas",
    "date": "Data",
    "weight": "Waga",
    "movingAverage": "Średnia Krocząca",
    "delete": "Usuń",
    "points": "PUNKTY",
    "lostWeight": "Utracona Waga:",
    "gainWeight": "Zyskana Waga:",
    "difference": "Różnica",
    "cleanPoints": "Wyczyść Zbędne Punkty",
    "confirmClearData": "Czy na pewno chcesz wyczyścić wszystkie dane?",
    "yes": "Tak, usuń wszystko",
    "no": "Nie, zachowaj wszystko",
    "currentGoalWeight": "Obecna / Docelowa Waga",
    "daysTotal": "łącznie dni",
    "averageDaily": "Średnio Dziennie:",
    "averageWeekly": "Średnio Tygodniowo:",
    "keepMonth": "Zachowaj 1-Miesiąc",
    "keepWeek": "Zachowaj 1-Tydzień",
    "congrats1": "Fantastyczny postęp! Tak trzymaj!",
    "congrats2": "Świetnie ci idzie! Kolejny cel osiągnięty!",
    "congrats3": "Wow! Jesteś na fali! Kontynuuj!",
    "congrats4": "Niesamowita praca! Jesteś coraz bliżej celu!",
    "congrats5": "Niesamowita praca! Twój wysiłek się opłaca!",
    "congratsGoal": "Udało ci się! Dobra robota!",
    "congratulationsHeader": "Gratulacje!",
    "firstEntryMessage": "To twój pierwszy punkt! Nie zapomnij dodać kolejnego jutro!",
    "enterGoalWeightHere": "Wprowadź swoją WAGĘ DOCELOWĄ tutaj, a następnie kliknij Dodaj Wagę.",
    "enterCurrentWeightHere": "Wprowadź swoją OBECNĄ WAGĘ tutaj.",
    weightConflictTitle: "Konflikt wagi",
    weightConflictMessage: "Dla tej daty istnieje już waga. Co chcesz zrobić?",
    existingWeight: "Istniejąca waga",
    newWeight: "Nowa waga",
    average: "Średnie wagi",
    addNew: "Dodaj jako nowy wpis",
    cancel: "Anuluj"
  }
  ,
  pt: {
    "title": "Rastreador de Peso",
    "addWeight": "Adicionar Peso",
    "goalWeight": "Peso Meta",
    "currentWeight": "Peso Atual",
    "clearAll": "Limpar Todos os Dados",
    "daysToGoal": "Dias até o objetivo:",
    "weightIn30Days": "Peso em 30 dias:",
    "percentageLeft": "Porcentagem restante:",
    "days": "dias",
    "save": "SALVAR",
    "load": "CARREGAR",
    "week": "Semana",
    "month": "Mês",
    "threeMonths": "3 Meses",
    "sixMonths": "6 Meses",
    "nineMonths": "9 Meses",
    "year": "Ano",
    "allTime": "Todo o Tempo",
    "date": "Data",
    "weight": "Peso",
    "movingAverage": "Média Móvel",
    "delete": "Excluir",
    "points": "PONTOS",
    "lostWeight": "Peso perdido:",
    "gainWeight": "Peso ganho:",
    "difference": "Diferença",
    "cleanPoints": "Limpar Pontos Redundantes",
    "confirmClearData": "Tem certeza de que deseja limpar todos os dados?",
    "yes": "Sim, apagar tudo",
    "no": "Não, manter tudo",
    "currentGoalWeight": "Peso Atual / Meta",
    "daysTotal": "dias totais",
    "averageDaily": "Média Diária:",
    "averageWeekly": "Média Semanal:",
    "keepMonth": "Manter 1 Mês",
    "keepWeek": "Manter 1 Semana",
    "congrats1": "Progresso fantástico! Continue assim!",
    "congrats2": "Você está indo muito bem! Outro objetivo alcançado!",
    "congrats3": "Uau! Você está com tudo! Continue!",
    "congrats4": "Trabalho incrível! Você está cada vez mais perto do seu objetivo!",
    "congrats5": "Trabalho incrível! Seu esforço está valendo a pena!",
    "congratsGoal": "Você conseguiu! Bom trabalho!",
    "congratulationsHeader": "Parabéns!",
    "firstEntryMessage": "Este é seu primeiro ponto! Não se esqueça de adicionar o próximo amanhã!",
    "enterGoalWeightHere": "Digite seu PESO META aqui e clique em Adicionar Peso.",
    "enterCurrentWeightHere": "Digite seu PESO ATUAL aqui.",
    weightConflictTitle: "Conflito de Peso",
    weightConflictMessage: "Já existe um peso para esta data. O que você gostaria de fazer?",
    existingWeight: "Peso existente",
    newWeight: "Novo peso",
    average: "Pesos médios",
    addNew: "Adicionar como nova entrada",
    cancel: "Cancelar"
  }
  ,
  ro: {
    "title": "Urmăritor de Greutate",
    "addWeight": "Adaugă Greutate",
    "goalWeight": "Greutate Țintă",
    "currentWeight": "Greutate Curentă",
    "clearAll": "Șterge Toate Datele",
    "daysToGoal": "Zile până la obiectiv:",
    "weightIn30Days": "Greutate în 30 de zile:",
    "percentageLeft": "Procent rămas:",
    "days": "zile",
    "save": "SALVEAZĂ",
    "load": "ÎNCARCĂ",
    "week": "Săptămână",
    "month": "Lună",
    "threeMonths": "3 Luni",
    "sixMonths": "6 Luni",
    "nineMonths": "9 Luni",
    "year": "An",
    "allTime": "Tot Timpul",
    "date": "Data",
    "weight": "Greutate",
    "movingAverage": "Media Mobilă",
    "delete": "Șterge",
    "points": "PUNCTE",
    "lostWeight": "Greutate pierdută:",
    "gainWeight": "Greutate câștigată:",
    "difference": "Diferență",
    "cleanPoints": "Curăță Punctele Redundante",
    "confirmClearData": "Ești sigur că vrei să ștergi toate datele?",
    "yes": "Da, șterge tot",
    "no": "Nu, păstrează tot",
    "currentGoalWeight": "Greutate Curentă / Țintă",
    "daysTotal": "total zile",
    "averageDaily": "Medie Zilnică:",
    "averageWeekly": "Medie Săptămânală:",
    "keepMonth": "Păstrează 1 Lună",
    "keepWeek": "Păstrează 1 Săptămână",
    "congrats1": "Progres fantastic! Continuă așa!",
    "congrats2": "Faci o treabă grozavă! Încă un obiectiv atins!",
    "congrats3": "Wow! Ești pe val! Continuă așa!",
    "congrats4": "Muncă incredibilă! Te apropii de obiectivul tău!",
    "congrats5": "Muncă incredibilă! Efortul tău dă roade!",
    "congratsGoal": "Ai reușit! Felicitări!",
    "congratulationsHeader": "Felicitări!",
    "firstEntryMessage": "Acesta este primul tău punct! Nu uita să adaugi următorul mâine!",
    "enterGoalWeightHere": "Introduceți greutatea țintă aici, apoi faceți clic pe Adaugă Greutate.",
    "enterCurrentWeightHere": "Introduceți greutatea curentă aici.",
    weightConflictTitle: "Conflict de Greutate",
    weightConflictMessage: "Există deja o greutate pentru această dată. Ce doriți să faceți?",
    existingWeight: "Greutatea existentă",
    newWeight: "Greutate nouă",
    average: "Greutăți medii",
    addNew: "Adăugați ca intrare nouă",
    cancel: "Anulare"
  }
  ,
  ru: {
    "title": "Отслеживание Веса",
    "addWeight": "Добавить Вес",
    "goalWeight": "Целевой Вес",
    "currentWeight": "Текущий Вес",
    "clearAll": "Очистить Все Данные",
    "daysToGoal": "Дни до цели:",
    "weightIn30Days": "Вес через 30 дней:",
    "percentageLeft": "Остаток в процентах:",
    "days": "дни",
    "save": "СОХРАНИТЬ",
    "load": "ЗАГРУЗИТЬ",
    "week": "Неделя",
    "month": "Месяц",
    "threeMonths": "3 Месяца",
    "sixMonths": "6 Месяцев",
    "nineMonths": "9 Месяцев",
    "year": "Год",
    "allTime": "Все Время",
    "date": "Дата",
    "weight": "Вес",
    "movingAverage": "Скользящее Среднее",
    "delete": "Удалить",
    "points": "БАЛЛЫ",
    "lostWeight": "Потерянный вес:",
    "gainWeight": "Набранный вес:",
    "difference": "Разница",
    "cleanPoints": "Очистить избыточные точки",
    "confirmClearData": "Вы уверены, что хотите очистить все данные?",
    "yes": "Да, удалить все",
    "no": "Нет, сохранить все",
    "currentGoalWeight": "Текущий / Целевой Вес",
    "daysTotal": "всего дней",
    "averageDaily": "Средний Ежедневно:",
    "averageWeekly": "Средний Еженедельно:",
    "keepMonth": "Сохранить 1 Месяц",
    "keepWeek": "Сохранить 1 Неделю",
    "congrats1": "Фантастический прогресс! Продолжай в том же духе!",
    "congrats2": "Вы молодец! Еще одна цель достигнута!",
    "congrats3": "Вау! Вы в огне! Продолжайте!",
    "congrats4": "Невероятная работа! Вы приближаетесь к своей цели!",
    "congrats5": "Классная работа! Ваш тяжелый труд окупается!",
    "congratsGoal": "Вы это сделали! Отличная работа!",
    "congratulationsHeader": "Поздравляем!",
    "firstEntryMessage": "Это ваша первая точка! Не забудьте добавить следующую завтра!",
    "enterGoalWeightHere": "Введите свой ЦЕЛЕВОЙ вес здесь, затем нажмите Добавить Вес.",
    "enterCurrentWeightHere": "Введите свой ТЕКУЩИЙ вес здесь.",
    weightConflictTitle: "Конфликт веса",
    weightConflictMessage: "Для этой даты уже существует вес. Что вы хотите сделать?",
    existingWeight: "Существующий вес",
    newWeight: "Новый вес",
    average: "Усреднить вес",
    addNew: "Добавить как новую запись",
    cancel: "Отмена"
  }
  ,
  sk: {
    "title": "Sledovač Hmotnosti",
    "addWeight": "Pridať Hmotnosť",
    "goalWeight": "Cieľová Hmotnosť",
    "currentWeight": "Aktuálna Hmotnosť",
    "clearAll": "Vymazať Všetky Údaje",
    "daysToGoal": "Dni do cieľa:",
    "weightIn30Days": "Hmotnosť za 30 dní:",
    "percentageLeft": "Zostávajúce percento:",
    "days": "dni",
    "save": "ULOŽIŤ",
    "load": "NAČÍTAŤ",
    "week": "Týždeň",
    "month": "Mesiac",
    "threeMonths": "3 Mesiace",
    "sixMonths": "6 Mesiacov",
    "nineMonths": "9 Mesiacov",
    "year": "Rok",
    "allTime": "Celý Čas",
    "date": "Dátum",
    "weight": "Hmotnosť",
    "movingAverage": "Kĺzavý Priemer",
    "delete": "Vymazať",
    "points": "BODY",
    "lostWeight": "Stratená hmotnosť:",
    "gainWeight": "Získaná hmotnosť:",
    "difference": "Rozdiel",
    "cleanPoints": "Vyčistiť nadbytočné body",
    "confirmClearData": "Ste si istí, že chcete vymazať všetky údaje?",
    "yes": "Áno, vymazať všetko",
    "no": "Nie, zachovať všetko",
    "currentGoalWeight": "Aktuálna / Cieľová Hmotnosť",
    "daysTotal": "celkovo dní",
    "averageDaily": "Priemerné Denne:",
    "averageWeekly": "Priemerné Týždenne:",
    "keepMonth": "Zachovať 1 Mesiac",
    "keepWeek": "Zachovať 1 Týždeň",
    "congrats1": "Fantastický pokrok! Len tak ďalej!",
    "congrats2": "Robíte to skvele! Ďalší cieľ dosiahnutý!",
    "congrats3": "Wow! Ste úžasní! Pokračujte!",
    "congrats4": "Úžasná práca! Blížite sa k svojmu cieľu!",
    "congrats5": "Neuvediteľná práca! Vaša tvrdá práca sa vypláca!",
    "congratsGoal": "Urobili ste to! Skvelá práca!",
    "congratulationsHeader": "Blahoželáme!",
    "firstEntryMessage": "Toto je váš prvý bod! Nezabudnite zajtra pridať ďalší!",
    "enterGoalWeightHere": "Zadajte svoju CIEĽOVÚ hmotnosť tu a potom kliknite na Pridať Hmotnosť.",
    "enterCurrentWeightHere": "Zadajte svoju AKTUÁLNU hmotnosť tu.",
    weightConflictTitle: "Konflikt hmotnosti",
    weightConflictMessage: "Pre tento dátum už existuje hmotnosť. Čo chcete urobiť?",
    existingWeight: "Existujúca hmotnosť",
    newWeight: "Nová hmotnosť",
    average: "Priemerné hmotnosti",
    addNew: "Pridať ako nový záznam",
    cancel: "Zrušiť"
  }
  ,
  sr: {
    "title": "Праћење Тежине",
    "addWeight": "Додај Тежину",
    "goalWeight": "Циљна Тежина",
    "currentWeight": "Тренутна Тежина",
    "clearAll": "Обриши Све Подацке",
    "daysToGoal": "Дани до циља:",
    "weightIn30Days": "Тежина за 30 дана:",
    "percentageLeft": "Преостали проценат:",
    "days": "дани",
    "save": "САЧУВАЈ",
    "load": "УЧИТАЈ",
    "week": "Недеља",
    "month": "Месец",
    "threeMonths": "3 Месеца",
    "sixMonths": "6 Месеци",
    "nineMonths": "9 Месеци",
    "year": "Година",
    "allTime": "Све Време",
    "date": "Датум",
    "weight": "Тежина",
    "movingAverage": "Кретање Просека",
    "delete": "Обриши",
    "points": "БОДОВИ",
    "lostWeight": "Изгубљена тежина:",
    "gainWeight": "Добијена тежина:",
    "difference": "Разлика",
    "cleanPoints": "Очисти вишак тачака",
    "confirmClearData": "Да ли сте сигурни да желите да обришете све податке?",
    "yes": "Да, обриши све",
    "no": "Не, задржи све",
    "currentGoalWeight": "Тренутна / Циљна Тежина",
    "daysTotal": "укупно дана",
    "averageDaily": "Просечно Дневно:",
    "averageWeekly": "Просечно Недељно:",
    "keepMonth": "Задржи 1-Месец",
    "keepWeek": "Задржи 1-Недељу",
    "congrats1": "Фантастичан напредак! Наставите тако!",
    "congrats2": "Радите одлично! Још један циљ постигнут!",
    "congrats3": "Вау! Сјајни сте! Наставите тако!",
    "congrats4": "Невероватан рад! Приближавате се свом циљу!",
    "congrats5": "Невероватан рад! Ваш труд се исплати!",
    "congratsGoal": "Успели сте! Сјајан рад!",
    "congratulationsHeader": "Честитамо!",
    "firstEntryMessage": "Ово је ваша прва тачка! Не заборавите да додате следећу сутра!",
    "enterGoalWeightHere": "Унесите своју ЦИЉНУ тежину овде, а затим кликните на Додај Тежину.",
    "enterCurrentWeightHere": "Унесите своју ТРЕНУТНУ тежину овде.",
    weightConflictTitle: "Сукоб тежине",
    weightConflictMessage: "Већ постоји тежина за овај датум. Шта желите да урадите?",
    existingWeight: "Постојећа тежина",
    newWeight: "Нова тежина",
    average: "Просечне тежине",
    addNew: "Додај као нови унос",
    cancel: "Откажи"
  }
  ,
  sv: {
    "title": "Viktspårare",
    "addWeight": "Lägg till vikt",
    "goalWeight": "Målvikt",
    "currentWeight": "Nuvarande vikt",
    "clearAll": "Rensa alla data",
    "daysToGoal": "Dagar till mål:",
    "weightIn30Days": "Vikt om 30 dagar:",
    "percentageLeft": "Kvarstående procent:",
    "days": "dagar",
    "save": "SPARA",
    "load": "LADDA",
    "week": "Vecka",
    "month": "Månad",
    "threeMonths": "3 Månader",
    "sixMonths": "6 Månader",
    "nineMonths": "9 Månader",
    "year": "År",
    "allTime": "All Tid",
    "date": "Datum",
    "weight": "Vikt",
    "movingAverage": "Glidande medelvärde",
    "delete": "Radera",
    "points": "POÄNG",
    "lostWeight": "Förlorad vikt:",
    "gainWeight": "Vunnen vikt:",
    "difference": "Skillnad",
    "cleanPoints": "Rensa överflödiga poäng",
    "confirmClearData": "Är du säker på att du vill rensa alla data?",
    "yes": "Ja, radera allt",
    "no": "Nej, behåll allt",
    "currentGoalWeight": "Nuvarande / Målvikt",
    "daysTotal": "totala dagar",
    "averageDaily": "Genomsnittligt Dagligt:",
    "averageWeekly": "Genomsnittligt Veckovis:",
    "keepMonth": "Behåll 1-Månad",
    "keepWeek": "Behåll 1-Vecka",
    "congrats1": "Fantastisk framgång! Fortsätt så!",
    "congrats2": "Du gör ett fantastiskt jobb! Ännu ett mål nått!",
    "congrats3": "Wow! Du är otrolig! Fortsätt kämpa!",
    "congrats4": "Fantastiskt arbete! Du närmar dig ditt mål!",
    "congrats5": "Otroligt arbete! Din hårda arbete ger resultat!",
    "congratsGoal": "Du klarade det! Bra jobbat!",
    "congratulationsHeader": "Grattis!",
    "firstEntryMessage": "Det här är din första poäng! Glöm inte att lägga till nästa imorgon!",
    "enterGoalWeightHere": "Ange din MÅLVIKT här och klicka sedan på Lägg till vikt.",
    "enterCurrentWeightHere": "Ange din NUVARANDE VIKT här.",
    weightConflictTitle: "Viktkonflikt",
    weightConflictMessage: "Det finns redan en vikt för detta datum. Vad vill du göra?",
    existingWeight: "Befintlig vikt",
    newWeight: "Ny vikt",
    average: "Genomsnittliga vikter",
    addNew: "Lägg till som ny post",
    cancel: "Avbryt"
  }
  ,
  ta: {
    "title": "எடையை கண்காணிப்பவர்",
    "addWeight": "எடை சேர்",
    "goalWeight": "இலக்கு எடை",
    "currentWeight": "தற்போதைய எடை",
    "clearAll": "அனைத்து தரவுகளையும் அழி",
    "daysToGoal": "இலக்கிற்கான நாட்கள்:",
    "weightIn30Days": "30 நாட்களில் எடை:",
    "percentageLeft": "மீதமுள்ள சதவீதம்:",
    "days": "நாட்கள்",
    "save": "சேமி",
    "load": "ஏற்று",
    "week": "வாரம்",
    "month": "மாதம்",
    "threeMonths": "3 மாதங்கள்",
    "sixMonths": "6 மாதங்கள்",
    "nineMonths": "9 மாதங்கள்",
    "year": "ஆண்டு",
    "allTime": "எல்லா நேரமும்",
    "date": "தேதி",
    "weight": "எடை",
    "movingAverage": "நகரும் சராசரி",
    "delete": "அழி",
    "points": "புள்ளிகள்",
    "lostWeight": "இழந்த எடை:",
    "gainWeight": "பெருகிய எடை:",
    "difference": "வேறுபாடு",
    "cleanPoints": "அதிர்ந்த புள்ளிகளை சுத்தம் செய்",
    "confirmClearData": "அனைத்து தரவுகளையும் நீக்க விரும்புகிறீர்களா?",
    "yes": "ஆம், அனைத்தையும் நீக்கு",
    "no": "இல்லை, அனைத்தையும் வைத்திரு",
    "currentGoalWeight": "தற்போதைய / இலக்கு எடை",
    "daysTotal": "மொத்த நாட்கள்",
    "averageDaily": "சராசரி தினசரி:",
    "averageWeekly": "சராசரி வாராந்திர:",
    "keepMonth": "1 மாதம் வைத்திரு",
    "keepWeek": "1 வாரம் வைத்திரு",
    "congrats1": "அற்புதமான முன்னேற்றம்! தொடர்ந்து சென்று கொள்க!",
    "congrats2": "நீங்கள் மிகவும் சிறப்பாக செய்கிறீர்கள்! மற்றொரு இலக்கை அடைந்துவிட்டீர்கள்!",
    "congrats3": "வாவ்! நீங்கள் மிகவும் சிறப்பாக இருக்கிறீர்கள்! தொடர்ந்து முயற்சி செய்க!",
    "congrats4": "அற்புதமான வேலை! நீங்கள் உங்கள் இலக்கிற்கு அருகில் இருக்கிறீர்கள்!",
    "congrats5": "மிகவும் சிறப்பாக செய்துள்ளீர்கள்! உங்கள் கடின உழைப்பு பயன் தருகிறது!",
    "congratsGoal": "நீங்கள் செய்துவிட்டீர்கள்! சிறந்த வேலை!",
    "congratulationsHeader": "வாழ்த்துக்கள்!",
    "firstEntryMessage": "இது உங்கள் முதல் புள்ளி! நாளை மற்றொன்றை சேர்க்க மறக்காதீர்கள்!",
    "enterGoalWeightHere": "உங்கள் இலக்கு எடையை இங்கு உள்ளிடவும், பின்னர் எடை சேர் என்பதை கிளிக் செய்யவும்.",
    "enterCurrentWeightHere": "உங்கள் தற்போதைய எடையை இங்கு உள்ளிடவும்.",
    weightConflictTitle: "எடை முரண்பாடு",
    weightConflictMessage: "இந்த தேதிக்கு ஏற்கனவே ஒரு எடை உள்ளது. நீங்கள் என்ன செய்ய விரும்புகிறீர்கள்?",
    existingWeight: "தற்போதைய எடை",
    newWeight: "புதிய எடை",
    average: "சராசரி எடைகள்",
    addNew: "புதிய பதிவாக சேர்க்கவும்",
    cancel: "ரத்து செய்"
  }
  ,
  tk: {
    "title": "Agram Yzarlaýjy",
    "addWeight": "Agram goş",
    "goalWeight": "Maksat agyrlygy",
    "currentWeight": "Häzirki agram",
    "clearAll": "Hemmäni öçür",
    "daysToGoal": "Maksada çenli günler:",
    "weightIn30Days": "30 günüň içinde agram:",
    "percentageLeft": "Galýan göterim:",
    "days": "günler",
    "save": "SAKLA",
    "load": "ÝÜKLE",
    "week": "Hepde",
    "month": "Aý",
    "threeMonths": "3 Aý",
    "sixMonths": "6 Aý",
    "nineMonths": "9 Aý",
    "year": "Ýyl",
    "allTime": "Hemişe",
    "date": "Senesi",
    "weight": "Agram",
    "movingAverage": "Üýtgäp duran ortaça",
    "delete": "Öçür",
    "points": "BAL",
    "lostWeight": "Ýitilen agram:",
    "gainWeight": "Alnan agram:",
    "difference": "Tapawut",
    "cleanPoints": "Artykmaç baly arassala",
    "confirmClearData": "Hemmäni öçürmek isleýärsiňizmi?",
    "yes": "Hawa, hemmäni öçür",
    "no": "Ýok, hemmäni sakla",
    "currentGoalWeight": "Häzirki / Maksat agramy",
    "daysTotal": "jemi günler",
    "averageDaily": "Ortaça Günlük:",
    "averageWeekly": "Ortaça Hepdelik:",
    "keepMonth": "1 Aý sakla",
    "keepWeek": "1 Hepde sakla",
    "congrats1": "Ajaýyp öňe gidişlik! Şeýdip dowam et!",
    "congrats2": "Siz ajaýyp iş edýärsiňiz! Ýene bir maksat gazandy!",
    "congrats3": "Wow! Siz örän täsin! Şeýdip dowam ediň!",
    "congrats4": "Ajaýyp iş! Siz maksatlaryna ýakynlaşýarsyňyz!",
    "congrats5": "Täsin iş! Siziň kyn işiňiz kömek edýär!",
    "congratsGoal": "Siz muny etdiňiz! Ajaýyp iş!",
    "congratulationsHeader": "Gutlag!",
    "firstEntryMessage": "Bu siziň ilkinji nokadyňyz! Ertir ýene birini goşmagy unutmaň!",
    "enterGoalWeightHere": "Siziň maksat agyrlygyňyzy şu ýere ýazyň, soňra Agram goş-ny basyň.",
    "enterCurrentWeightHere": "Häzirki agyrlygyňyzy şu ýere ýazyň.",
    weightConflictTitle: "Agram çaknyşmasy",
    weightConflictMessage: "Bu sene üçin eýýäm agram bar. Näme etmek isleýärsiňiz?",
    existingWeight: "Bar bolan agram",
    newWeight: "Täze agram",
    average: "Ortaça agramlar",
    addNew: "Täze ýazgy hökmünde goşuň",
    cancel: "Ýatyr"
  }
  ,
  tr: {
    "title": "Ağırlık İzleyici",
    "addWeight": "Ağırlık Ekle",
    "goalWeight": "Hedef Ağırlık",
    "currentWeight": "Mevcut Ağırlık",
    "clearAll": "Tüm Verileri Temizle",
    "daysToGoal": "Hedefe kadar gün:",
    "weightIn30Days": "30 gün içinde ağırlık:",
    "percentageLeft": "Kalan yüzde:",
    "days": "gün",
    "save": "KAYDET",
    "load": "YÜKLE",
    "week": "Hafta",
    "month": "Ay",
    "threeMonths": "3 Ay",
    "sixMonths": "6 Ay",
    "nineMonths": "9 Ay",
    "year": "Yıl",
    "allTime": "Tüm Zamanlar",
    "date": "Tarih",
    "weight": "Ağırlık",
    "movingAverage": "Hareketli Ortalama",
    "delete": "Sil",
    "points": "PUANLAR",
    "lostWeight": "Kayıp ağırlık:",
    "gainWeight": "Kazanılan ağırlık:",
    "difference": "Fark",
    "cleanPoints": "Gereksiz Puanları Temizle",
    "confirmClearData": "Tüm verileri silmek istediğinizden emin misiniz?",
    "yes": "Evet, hepsini sil",
    "no": "Hayır, hepsini sakla",
    "currentGoalWeight": "Mevcut / Hedef Ağırlık",
    "daysTotal": "toplam gün",
    "averageDaily": "Günlük Ortalama:",
    "averageWeekly": "Haftalık Ortalama:",
    "keepMonth": "1 Ay Sakla",
    "keepWeek": "1 Hafta Sakla",
    "congrats1": "Harika ilerleme! Böyle devam et!",
    "congrats2": "Çok iyi gidiyorsun! Bir hedef daha başardın!",
    "congrats3": "Vay! Harikasın! Devam et!",
    "congrats4": "Harika iş! Hedefine yaklaşıyorsun!",
    "congrats5": "İnanılmaz iş! Sıkı çalışman işe yarıyor!",
    "congratsGoal": "Başardın! Harika iş!",
    "congratulationsHeader": "Tebrikler!",
    "firstEntryMessage": "Bu senin ilk noktan! Yarın bir tane daha eklemeyi unutma!",
    "enterGoalWeightHere": "HEDEF ağırlığınızı buraya girin ve ardından Ağırlık Ekle'yi tıklayın.",
    "enterCurrentWeightHere": "Mevcut ağırlığınızı buraya girin.",
    weightConflictTitle: "Ağırlık Çakışması",
    weightConflictMessage: "Bu tarih için zaten bir ağırlık mevcut. Ne yapmak istersiniz?",
    existingWeight: "Mevcut ağırlık",
    newWeight: "Yeni ağırlık",
    average: "Ortalama ağırlıklar",
    addNew: "Yeni giriş olarak ekle",
    cancel: "İptal"
  }
  ,
  uk: {
    "title": "Трекер Ваги",
    "addWeight": "Додати Вагу",
    "goalWeight": "Цільова Вага",
    "currentWeight": "Поточна Вага",
    "clearAll": "Очистити Всі Дані",
    "daysToGoal": "Днів до мети:",
    "weightIn30Days": "Вага за 30 днів:",
    "percentageLeft": "Залишок відсотка:",
    "days": "дні",
    "save": "ЗБЕРЕГТИ",
    "load": "ЗАВАНТАЖИТИ",
    "week": "Тиждень",
    "month": "Місяць",
    "threeMonths": "3 Місяці",
    "sixMonths": "6 Місяців",
    "nineMonths": "9 Місяців",
    "year": "Рік",
    "allTime": "Весь Час",
    "date": "Дата",
    "weight": "Вага",
    "movingAverage": "Змінний Середній",
    "delete": "Видалити",
    "points": "ОЧКИ",
    "lostWeight": "Втрачена вага:",
    "gainWeight": "Набрана вага:",
    "difference": "Різниця",
    "cleanPoints": "Очистити зайві точки",
    "confirmClearData": "Ви впевнені, що хочете очистити всі дані?",
    "yes": "Так, видалити все",
    "no": "Ні, зберегти все",
    "currentGoalWeight": "Поточна / Цільова Вага",
    "daysTotal": "всього днів",
    "averageDaily": "Середньодобовий:",
    "averageWeekly": "Середньотижневий:",
    "keepMonth": "Зберегти 1-Місяць",
    "keepWeek": "Зберегти 1-Тиждень",
    "congrats1": "Фантастичний прогрес! Продовжуй!",
    "congrats2": "Ти робиш це чудово! Ще одна мета досягнута!",
    "congrats3": "Вау! Ти просто супер! Продовжуй!",
    "congrats4": "Неймовірна робота! Ти наближаєшся до своєї мети!",
    "congrats5": "Неймовірна робота! Твоя наполегливість окупається!",
    "congratsGoal": "Ти це зробив! Гарна робота!",
    "congratulationsHeader": "Вітаємо!",
    "firstEntryMessage": "Це твоя перша точка! Не забудь додати наступну завтра!",
    "enterGoalWeightHere": "Введи свою ЦІЛЬОВУ вагу тут, потім натисни Додати Вагу.",
    "enterCurrentWeightHere": "Введи свою ПОТОЧНУ вагу тут.",
    weightConflictTitle: "Конфлікт ваги",
    weightConflictMessage: "Для цієї дати вже існує вага. Що ви хочете зробити?",
    existingWeight: "Існуюча вага",
    newWeight: "Нова вага",
    average: "Середні ваги",
    addNew: "Додати як новий запис",
    cancel: "Скасувати"
  }
  ,
  ur: {
    "title": "وزن ٹریکر",
    "addWeight": "وزن شامل کریں",
    "goalWeight": "ہدف وزن",
    "currentWeight": "موجودہ وزن",
    "clearAll": "تمام ڈیٹا صاف کریں",
    "daysToGoal": "ہدف تک دن:",
    "weightIn30Days": "30 دن میں وزن:",
    "percentageLeft": "باقی فیصد:",
    "days": "دن",
    "save": "محفوظ کریں",
    "load": "لوڈ کریں",
    "week": "ہفتہ",
    "month": "مہینہ",
    "threeMonths": "3 مہینے",
    "sixMonths": "6 مہینے",
    "nineMonths": "9 مہینے",
    "year": "سال",
    "allTime": "تمام وقت",
    "date": "تاریخ",
    "weight": "وزن",
    "movingAverage": "متحرک اوسط",
    "delete": "حذف کریں",
    "points": "پوائنٹس",
    "lostWeight": "کھویا وزن:",
    "gainWeight": "وزن بڑھا:",
    "difference": "فرق",
    "cleanPoints": "غیر ضروری پوائنٹس صاف کریں",
    "confirmClearData": "کیا آپ واقعی تمام ڈیٹا صاف کرنا چاہتے ہیں؟",
    "yes": "جی ہاں، سب حذف کریں",
    "no": "نہیں، سب رکھیں",
    "currentGoalWeight": "موجودہ / ہدف وزن",
    "daysTotal": "کل دن",
    "averageDaily": "اوسط روزانہ:",
    "averageWeekly": "اوسط ہفتہ وار:",
    "keepMonth": "1 مہینہ رکھیں",
    "keepWeek": "1 ہفتہ رکھیں",
    "congrats1": "شاندار ترقی! اسی طرح جاری رکھیں!",
    "congrats2": "آپ بہت اچھا کر رہے ہیں! ایک اور مقصد حاصل کر لیا!",
    "congrats3": "واہ! آپ شاندار ہیں! جاری رکھیں!",
    "congrats4": "شاندار کام! آپ اپنے مقصد کے قریب ہیں!",
    "congrats5": "شاندار کام! آپ کی محنت رنگ لا رہی ہے!",
    "congratsGoal": "آپ نے یہ کر دکھایا! بہت خوب!",
    "congratulationsHeader": "مبارک ہو!",
    "firstEntryMessage": "یہ آپ کا پہلا پوائنٹ ہے! کل دوسرا شامل کرنا نہ بھولیں!",
    "enterGoalWeightHere": "اپنا ہدف وزن یہاں درج کریں، پھر وزن شامل کریں پر کلک کریں۔",
    "enterCurrentWeightHere": "اپنا موجودہ وزن یہاں درج کریں۔",
    weightConflictTitle: "وزن کا تنازعہ",
    weightConflictMessage: "اس تاریخ کے لیے پہلے سے ہی ایک وزن موجود ہے۔ آپ کیا کرنا چاہتے ہیں؟",
    existingWeight: "موجودہ وزن",
    newWeight: "نیا وزن",
    average: "اوسط وزن",
    addNew: "نئی اندراج کے طور پر شامل کریں",
    cancel: "منسوخ کریں"
  }
  ,
  uz: {
    "title": "Vazn Kuzatuvchi",
    "addWeight": "Vazn Qo'shish",
    "goalWeight": "Maqsad Vazni",
    "currentWeight": "Hozirgi Vazn",
    "clearAll": "Barcha Ma'lumotlarni Tozalash",
    "daysToGoal": "Maqsadgacha Kunlar:",
    "weightIn30Days": "30 Kunda Vazn:",
    "percentageLeft": "Qolgan Foiz:",
    "days": "kunlar",
    "save": "SAQLASH",
    "load": "YUKLASH",
    "week": "Hafta",
    "month": "Oy",
    "threeMonths": "3 Oy",
    "sixMonths": "6 Oy",
    "nineMonths": "9 Oy",
    "year": "Yil",
    "allTime": "Barcha Vaqt",
    "date": "Sana",
    "weight": "Vazn",
    "movingAverage": "O'rtacha Harakat",
    "delete": "O'chirish",
    "points": "NUQTALAR",
    "lostWeight": "Yo'qotilgan Vazn:",
    "gainWeight": "Qo'shilgan Vazn:",
    "difference": "Farq",
    "cleanPoints": "Ortiqcha Nuqtalarni Tozalash",
    "confirmClearData": "Barcha ma'lumotlarni o'chirmoqchimisiz?",
    "yes": "Ha, hammasini o'chirish",
    "no": "Yo'q, hammasini saqlash",
    "currentGoalWeight": "Hozirgi / Maqsad Vazni",
    "daysTotal": "jami kunlar",
    "averageDaily": "O'rtacha Kunlik:",
    "averageWeekly": "O'rtacha Haftalik:",
    "keepMonth": "1 Oy Saqlash",
    "keepWeek": "1 Hafta Saqlash",
    "congrats1": "Ajoyib yutuq! Shunday davom eting!",
    "congrats2": "Siz ajoyib ish qilmoqdasiz! Yana bir maqsadga erishildi!",
    "congrats3": "Vau! Siz ajoyibsiz! Shunday davom eting!",
    "congrats4": "Ajoyib ish! Maqsadingizga yaqinlashyapsiz!",
    "congrats5": "Ajoyib ish! Mehnatingiz samara beryapti!",
    "congratsGoal": "Siz buni qildingiz! Ajoyib ish!",
    "congratulationsHeader": "Tabriklaymiz!",
    "firstEntryMessage": "Bu sizning birinchi nuqtangiz! Ertaga yana birini qo'shishni unutmang!",
    "enterGoalWeightHere": "Maqsad vazningizni bu yerga kiriting, keyin Vazn Qo'shish tugmasini bosing.",
    "enterCurrentWeightHere": "Hozirgi vazningizni bu yerga kiriting.",
    weightConflictTitle: "Vazn ziddiyati",
    weightConflictMessage: "Bu sana uchun allaqachon vazn mavjud. Nima qilishni xohlaysiz?",
    existingWeight: "Mavjud vazn",
    newWeight: "Yangi vazn",
    average: "O'rtacha vaznlar",
    addNew: "Yangi yozuv sifatida qo'shish",
    cancel: "Bekor qilish"
  }
  ,
  vi: {
    "title": "Theo Dõi Cân Nặng",
    "addWeight": "Thêm Cân Nặng",
    "goalWeight": "Cân Nặng Mục Tiêu",
    "currentWeight": "Cân Nặng Hiện Tại",
    "clearAll": "Xóa Tất Cả Dữ Liệu",
    "daysToGoal": "Ngày đạt mục tiêu:",
    "weightIn30Days": "Cân nặng sau 30 ngày:",
    "percentageLeft": "Phần trăm còn lại:",
    "days": "ngày",
    "save": "LƯU",
    "load": "TẢI",
    "week": "Tuần",
    "month": "Tháng",
    "threeMonths": "3 Tháng",
    "sixMonths": "6 Tháng",
    "nineMonths": "9 Tháng",
    "year": "Năm",
    "allTime": "Toàn Thời Gian",
    "date": "Ngày",
    "weight": "Cân Nặng",
    "movingAverage": "Trung Bình Di Động",
    "delete": "Xóa",
    "points": "ĐIỂM",
    "lostWeight": "Cân nặng đã giảm:",
    "gainWeight": "Cân nặng đã tăng:",
    "difference": "Sự khác biệt",
    "cleanPoints": "Làm Sạch Điểm Thừa",
    "confirmClearData": "Bạn có chắc chắn muốn xóa tất cả dữ liệu?",
    "yes": "Có, xóa tất cả",
    "no": "Không, giữ lại tất cả",
    "currentGoalWeight": "Cân Nặng Hiện Tại / Mục Tiêu",
    "daysTotal": "tổng số ngày",
    "averageDaily": "Trung Bình Hàng Ngày:",
    "averageWeekly": "Trung Bình Hàng Tuần:",
    "keepMonth": "Giữ 1 Tháng",
    "keepWeek": "Giữ 1 Tuần",
    "congrats1": "Tiến bộ tuyệt vời! Tiếp tục nhé!",
    "congrats2": "Bạn đang làm rất tốt! Đã đạt được một cột mốc nữa!",
    "congrats3": "Wow! Bạn thật tuyệt! Tiếp tục đẩy mạnh!",
    "congrats4": "Công việc tuyệt vời! Bạn đang tiến gần đến mục tiêu của mình!",
    "congrats5": "Công việc không thể tin được! Sự nỗ lực của bạn đang được đền đáp!",
    "congratsGoal": "Bạn đã làm được! Công việc tuyệt vời!",
    "congratulationsHeader": "Chúc Mừng!",
    "firstEntryMessage": "Đây là điểm đầu tiên của bạn! Đừng quên thêm điểm tiếp theo vào ngày mai!",
    "enterGoalWeightHere": "Nhập cân nặng MỤC TIÊU của bạn ở đây, sau đó nhấp vào Thêm Cân Nặng.",
    "enterCurrentWeightHere": "Nhập cân nặng HIỆN TẠI của bạn ở đây.",
    weightConflictTitle: "Xung đột cân nặng",
    weightConflictMessage: "Đã tồn tại một cân nặng cho ngày này. Bạn muốn làm gì?",
    existingWeight: "Cân nặng hiện tại",
    newWeight: "Cân nặng mới",
    average: "Cân nặng trung bình",
    addNew: "Thêm như mục nhập mới",
    cancel: "Hủy"
  }
  ,
  zh: {
    "title": "体重跟踪器",
    "addWeight": "添加体重",
    "goalWeight": "目标体重",
    "currentWeight": "当前体重",
    "clearAll": "清除所有数据",
    "daysToGoal": "达到目标的天数:",
    "weightIn30Days": "30天后的体重:",
    "percentageLeft": "剩余百分比:",
    "days": "天",
    "save": "保存",
    "load": "加载",
    "week": "周",
    "month": "月",
    "threeMonths": "3个月",
    "sixMonths": "6个月",
    "nineMonths": "9个月",
    "year": "年",
    "allTime": "所有时间",
    "date": "日期",
    "weight": "体重",
    "movingAverage": "移动平均",
    "delete": "删除",
    "points": "点数",
    "lostWeight": "减轻的体重:",
    "gainWeight": "增加的体重:",
    "difference": "差异",
    "cleanPoints": "清除多余点数",
    "confirmClearData": "您确定要清除所有数据吗？",
    "yes": "是的，删除所有",
    "no": "不，保留所有",
    "currentGoalWeight": "当前/目标体重",
    "daysTotal": "总天数",
    "averageDaily": "每日平均:",
    "averageWeekly": "每周平均:",
    "keepMonth": "保留1个月",
    "keepWeek": "保留1周",
    "congrats1": "进展神速！继续加油！",
    "congrats2": "你做得很好！又一个目标达成！",
    "congrats3": "哇！你真棒！继续努力！",
    "congrats4": "好极了！你离目标越来越近了！",
    "congrats5": "不可思议的工作！你的努力得到了回报！",
    "congratsGoal": "你做到了！干得好！",
    "congratulationsHeader": "恭喜！",
    "firstEntryMessage": "这是你的第一个点！别忘了明天再添加一个！",
    "enterGoalWeightHere": "在这里输入你的目标体重，然后点击添加体重。",
    "enterCurrentWeightHere": "在这里输入你的当前体重。",
    weightConflictTitle: "体重冲突",
    weightConflictMessage: "这个日期已经存在体重记录。你想做什么？",
    existingWeight: "现有体重",
    newWeight: "新体重",
    average: "平均体重",
    addNew: "添加为新记录",
    cancel: "取消"
  }
  
};

export default translations;