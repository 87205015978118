import React, { useState } from 'react';
import { format } from 'date-fns';

function SaveLoadData({ weights, handleDataLoaded, goalWeight, t }) {
  const [isLoading, setIsLoading] = useState(false);

  const saveData = () => {
    const csvContent = "data:text/csv;charset=utf-8,"
      + "Date,Weight,GoalWeight\n"
      + weights.map(w => `${w.date},${w.weight},${goalWeight || ''}`).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    const fileName = `weight_data_${format(new Date(), 'dd_MM_yyyy')}.csv`;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const loadData = (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsLoading(true);
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const content = e.target.result;
          const lines = content.split('\n');
          const newWeights = [];
          let newGoalWeight = null;

          lines.slice(1).forEach(line => {
            const [date, weight, goalWeight] = line.split(',');
            if (date && !isNaN(parseFloat(weight))) {
              newWeights.push({ date: date.trim(), weight: parseFloat(weight) });
            }
            if (goalWeight && !isNaN(parseFloat(goalWeight))) {
              newGoalWeight = parseFloat(goalWeight);
            }
          });

          handleDataLoaded(newWeights, newGoalWeight);
        } catch (error) {
          console.error('Error processing file:', error);
          alert('Error processing file. Please check the file format and try again.');
        } finally {
          setIsLoading(false);
        }
      };
      reader.onerror = (error) => {
        console.error('Error reading file:', error);
        alert('Error reading file. Please try again.');
        setIsLoading(false);
      };
      reader.readAsText(file);
    }
    // Reset the file input
    event.target.value = '';
  };

  return (
    <>
      <button onClick={saveData} className="save-button" disabled={isLoading}>
        {t.save}
      </button>
      <label className="load-button">
        {isLoading ? 'Loading...' : t.load}
        <input 
          type="file" 
          accept=".csv" 
          style={{ display: 'none' }} 
          onChange={loadData} 
          disabled={isLoading}
        />
      </label>
    </>
  );
}

export default SaveLoadData;