// components/Skeleton.jsx
import React from 'react';
import './Skeleton.css';

const Skeleton = () => (
  <div className="skeleton-wrapper">
    <div className="skeleton-content">
      <div className="skeleton-row">
        <div className="skeleton-element" style={{ width: '225px', height: '100px', marginRight: 'auto' }}></div>
      </div>
      <div className="skeleton-row">
        <div className="skeleton-element" style={{ width: '390px', height: '154px', marginLeft: 'auto' }}></div>
        <div className="skeleton-element" style={{ width: '372px', height: '75px', marginRight: 'auto' }}></div>
      </div>
      <div className="skeleton-row">
        <div className="skeleton-element" style={{ width: '585px', height: '38px', margin: '0 auto' }}></div>
      </div>
      <div className="skeleton-row">
        <div className="skeleton-element" style={{ width: '750px', height: '266px', margin: '0 auto' }}></div>
      </div>
      <div className="skeleton-row">
        <div className="skeleton-element" style={{ width: '750px', height: '150px', margin: '0 auto' }}></div>
      </div>
      <div className="skeleton-grid">
        <div className="skeleton-element" style={{ width: '244px', height: '60px' }}></div>
        <div className="skeleton-element" style={{ width: '244px', height: '60px' }}></div>
        <div className="skeleton-element" style={{ width: '244px', height: '60px' }}></div>
        <div className="skeleton-element" style={{ width: '244px', height: '60px' }}></div>
        <div className="skeleton-element" style={{ width: '244px', height: '60px' }}></div>
        <div className="skeleton-element" style={{ width: '244px', height: '60px' }}></div>
      </div>
      <div className="skeleton-row">
        <div className="skeleton-element" style={{ width: '200px', height: '38px', margin: '0 auto' }}></div>
      </div>
    </div>
  </div>
);

export default Skeleton;