import React from 'react';

const WeightConflictPopup = ({ isOpen, onClose, conflict, onResolve, theme, t }) => {
  if (!isOpen) return null;

  const handleOutsideClick = (e) => {
    if (e.target.classList.contains('weight-conflict-popup')) {
      onClose();
    }
  };

  return (
    <div className={`weight-conflict-popup ${theme}`} onClick={handleOutsideClick}>
      <div className="modal-content">
        <h2>{t.weightConflictTitle}</h2>
        <p>{t.weightConflictMessage}</p>
        <p>{t.existingWeight}: <span className="weight-value">{conflict.existingWeight}</span></p>
        <p>{t.newWeight}: <span className="weight-value">{conflict.newWeight}</span></p>
        <div className="button-container">
          <button className="average" onClick={() => { onResolve('average'); onClose(); }}>{t.average}</button>
          <button className="new" onClick={() => { onResolve('new'); onClose(); }}>{t.addNew}</button>
          <button className="cancel" onClick={onClose}>{t.cancel}</button>
        </div>
      </div>
    </div>
  );
};

export default WeightConflictPopup;