import React, { useState, useRef, useEffect } from 'react';

const languageOptions = [
  { value: 'en', label: 'English', flag: '🇺🇸', englishSpelling: 'English' },
  { value: 'ar_eg', label: 'العربية المصرية', flag: '🇪🇬', englishSpelling: 'Egyptian Arabic' },
  { value: 'ar_le', label: 'اللهجة الشامية', flag: '🇱🇧', englishSpelling: 'Levantine Arabic' },
  { value: 'am', label: 'հայերեն', flag: '🇦🇲', englishSpelling: 'Armenian' },
  { value: 'be', label: 'Беларуская', flag: '🇧🇾', englishSpelling: 'Belarusian' },
  { value: 'bn', label: 'বাংলা', flag: '🇧🇩', englishSpelling: 'Bengali' },
  { value: 'bho', label: 'भोजपुरी', flag: '🇮🇳', englishSpelling: 'Bhojpuri' },
  { value: 'cs', label: 'Čeština', flag: '🇨🇿', englishSpelling: 'Czech' },
  { value: 'da', label: 'Dansk', flag: '🇩🇰', englishSpelling: 'Danish' },
  { value: 'de', label: 'Deutsch', flag: '🇩🇪', englishSpelling: 'German' },
  { value: 'el', label: 'Ελληνικά', flag: '🇬🇷', englishSpelling: 'Greek' },
  { value: 'es', label: 'Español', flag: '🇪🇸', englishSpelling: 'Spanish' },
  { value: 'et', label: 'Eesti', flag: '🇪🇪', englishSpelling: 'Estonian' },
  { value: 'fa', label: 'فارسی', flag: '🇮🇷', englishSpelling: 'Persian' },
  { value: 'fi', label: 'Suomi', flag: '🇫🇮', englishSpelling: 'Finnish' },
  { value: 'fr', label: 'Français', flag: '🇫🇷', englishSpelling: 'French' },
  { value: 'gu', label: 'ગુજરાતી', flag: '🇮🇳', englishSpelling: 'Gujarati' },
  { value: 'ha', label: 'Hausa', flag: '🇳🇬', englishSpelling: 'Hausa' },
  { value: 'he', label: 'עברית', flag: '🇮🇱', englishSpelling: 'Hebrew' },
  { value: 'hr', label: 'Hrvatski', flag: '🇭🇷', englishSpelling: 'Croatian' },
  { value: 'id', label: 'Bahasa Indonesia', flag: '🇮🇩', englishSpelling: 'Indonesian' },
  { value: 'it', label: 'Italiano', flag: '🇮🇹', englishSpelling: 'Italian' },
  { value: 'ja', label: '日本語', flag: '🇯🇵', englishSpelling: 'Japanese' },
  { value: 'ka', label: 'ქართული', flag: '🇬🇪', englishSpelling: 'Georgian' },
  { value: 'kk', label: 'Қазақ', flag: '🇰🇿', englishSpelling: 'Kazakh' },
  { value: 'ko', label: '한국어', flag: '🇰🇷', englishSpelling: 'Korean' },
  { value: 'ky', label: 'Кыргызча', flag: '🇰🇬', englishSpelling: 'Kyrgyz' },
  { value: 'lt', label: 'Lietuvių', flag: '🇱🇹', englishSpelling: 'Lithuanian' },
  { value: 'lv', label: 'Latviešu', flag: '🇱🇻', englishSpelling: 'Latvian' },
  { value: 'md', label: 'Moldovenească', flag: '🇲🇩', englishSpelling: 'Moldovan' },
  { value: 'mr', label: 'मराठी', flag: '🇮🇳', englishSpelling: 'Marathi' },
  { value: 'nan', label: '閩南語', flag: '🇹🇼', englishSpelling: 'Min Nan' },
  { value: 'nl', label: 'Nederlands', flag: '🇳🇱', englishSpelling: 'Dutch' },
  { value: 'no', label: 'Norsk', flag: '🇳🇴', englishSpelling: 'Norwegian' },
  { value: 'pa', label: 'ਪੰਜਾਬੀ', flag: '🇮🇳', englishSpelling: 'Punjabi' },
  { value: 'pl', label: 'Polski', flag: '🇵🇱', englishSpelling: 'Polish' },
  { value: 'pt', label: 'Português', flag: '🇵🇹', englishSpelling: 'Portuguese' },
  { value: 'ro', label: 'Română', flag: '🇷🇴', englishSpelling: 'Romanian' },
  { value: 'ru', label: 'Русский', flag: '🇷🇺', englishSpelling: 'Russian' },
  { value: 'sk', label: 'Slovenčina', flag: '🇸🇰', englishSpelling: 'Slovak' },
  { value: 'sr', label: 'Српски', flag: '🇷🇸', englishSpelling: 'Serbian' },
  { value: 'sv', label: 'Svenska', flag: '🇸🇪', englishSpelling: 'Swedish' },
  { value: 'sw', label: 'Kiswahili', flag: '🇹🇿', englishSpelling: 'Swahili' },
  { value: 'ta', label: 'தமிழ்', flag: '🇮🇳', englishSpelling: 'Tamil' },
  { value: 'te', label: 'తెలుగు', flag: '🇮🇳', englishSpelling: 'Telugu' },
  { value: 'th', label: 'ไทย', flag: '🇹🇭', englishSpelling: 'Thai' },
  { value: 'tk', label: 'Türkmen', flag: '🇹🇲', englishSpelling: 'Turkmen' },
  { value: 'tl', label: 'Tagalog', flag: '🇵🇭', englishSpelling: 'Tagalog' },
  { value: 'tr', label: 'Türkçe', flag: '🇹🇷', englishSpelling: 'Turkish' },
  { value: 'uk', label: 'Українська', flag: '🇺🇦', englishSpelling: 'Ukrainian' },
  { value: 'ur', label: 'اردو', flag: '🇵🇰', englishSpelling: 'Urdu' },
  { value: 'uz', label: "O'zbek", flag: '🇺🇿', englishSpelling: 'Uzbek' },
  { value: 'vi', label: 'Tiếng Việt', flag: '🇻🇳', englishSpelling: 'Vietnamese' },
  { value: 'yo', label: 'Yorùbá', flag: '🇳🇬', englishSpelling: 'Yoruba' },
  { value: 'zh', label: '中文', flag: '🇨🇳', englishSpelling: 'Chinese' },
];


const unitOptions = [
  { value: 'kg', label: 'kg' },
  { value: 'lbs', label: 'lbs' }
];

const LanguageSelector = ({ language, setLanguage, theme, unit, setUnit }) => {
  const [isLanguageOpen, setIsLanguageOpen] = useState(false);
  const [isUnitOpen, setIsUnitOpen] = useState(false);
  const [hoveredLanguageOption, setHoveredLanguageOption] = useState(null);
  const [hoveredUnitOption, setHoveredUnitOption] = useState(null);
  const languageDropdownRef = useRef(null);
  const unitDropdownRef = useRef(null);

  const selectedLanguage = languageOptions.find(option => option.value === language);
  const selectedUnit = unitOptions.find(option => option.value === unit);

  // Sort languageOptions alphabetically by label
  const sortedLanguageOptions = [
    languageOptions.find(option => option.value === 'en'),
    ...languageOptions
      .filter(option => option.value !== 'en')
      .sort((a, b) => a.englishSpelling
      .localeCompare(b.englishSpelling, undefined, { sensitivity: 'base' }))
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (languageDropdownRef.current && !languageDropdownRef.current.contains(event.target)) {
        setIsLanguageOpen(false);
      }
      if (unitDropdownRef.current && !unitDropdownRef.current.contains(event.target)) {
        setIsUnitOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleLanguageDropdown = () => setIsLanguageOpen(!isLanguageOpen);
  const toggleUnitDropdown = () => setIsUnitOpen(!isUnitOpen);

  const handleLanguageOptionClick = (option) => {
    if (option.value !== language) {
      setLanguage(option.value);
      setIsLanguageOpen(false);
    }
  };

  const handleUnitOptionClick = (option) => {
    if (option.value !== unit) {
      setUnit(option.value);
      setIsUnitOpen(false);
      localStorage.setItem('unit', option.value);
    }
  };

  const backgroundColor = theme === 'light' ? '#fff' : '#2a2a2a';
  const color = theme === 'light' ? '#333' : '#fff';
  const borderColor = theme === 'light' ? '#ccc' : '#555';
  const hoverColor = theme === 'light' ? '#f0f0f0' : '#444';

  const dropdownStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px 8px',
    backgroundColor,
    color,
    border: `1px solid ${borderColor}`,
    borderRadius: '20px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease'
  };

  const dropdownListStyle = {
    position: 'absolute',
    top: 'calc(100% + 5px)',
    left: 0,
    right: 0,
    backgroundColor,
    border: `1px solid ${borderColor}`,
    borderRadius: '20px',
    listStyle: 'none',
    margin: 0,
    padding: '5px 0',
    zIndex: 1010,
    boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
    overflow: 'hidden',
  };

  const dropdownListStyle1 = {
    ...dropdownListStyle,
    width: '120px'
  };

  const dropdownListStyle2 = {
    ...dropdownListStyle,
    width: '45px'
  };

  const scrollableContainerStyle = {
    maxHeight: '400px',
    overflowY: 'auto',
    overflowX: 'hidden',
    scrollbarWidth: 'thin',
    scrollbarColor: `${borderColor} transparent`,
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: borderColor,
      borderRadius: '20px',
      border: `3px solid ${backgroundColor}`,
    },
  };

  const dropdownItemStyle = (isSelected, isHovered) => ({
    display: 'flex',
    alignItems: 'center',
    padding: '8px 12px',
    cursor: 'pointer',
    color,
    backgroundColor: 
      isSelected ? hoverColor :
      isHovered ? hoverColor : 'transparent',
    transition: 'background-color 0.3s ease'
  });

  return (
    <div style={{ display: 'flex' }}>
      <div ref={languageDropdownRef} style={{ position: 'relative', width: '90px', marginRight: '5px' }}>
        <div onClick={toggleLanguageDropdown} style={dropdownStyle}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: 10 }}>{selectedLanguage.flag}</span>
            {selectedLanguage.label}
          </div>
          <span style={{ marginLeft: 10 }}>&#9660;</span>
        </div>
        {isLanguageOpen && (
          <div style={dropdownListStyle1}>
            <div style={scrollableContainerStyle}>
              <ul style={{ margin: 0, padding: 0 }}>
                {sortedLanguageOptions.map((option) => (
                  option.value !== language && (
                    <li
                      key={option.value}
                      onClick={() => handleLanguageOptionClick(option)}
                      onMouseEnter={() => setHoveredLanguageOption(option.value)}
                      onMouseLeave={() => setHoveredLanguageOption(null)}
                      style={dropdownItemStyle(option.value === language, hoveredLanguageOption === option.value)}
                    >
                      <span style={{ marginRight: 10 }}>{option.flag}</span>
                      {option.label}
                    </li>
                  ) 
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
      <div ref={unitDropdownRef} style={{ position: 'relative', width: '44px' }}>
        <div onClick={toggleUnitDropdown} style={dropdownStyle}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {selectedUnit.label}
          </div>
          <span style={{ marginLeft: 10 }}>&#9660;</span>
        </div>
        {isUnitOpen && (
          <ul style={dropdownListStyle2}>
            {unitOptions.map((option) => (
              option.value !== unit && (
                <li
                  key={option.value}
                  onClick={() => handleUnitOptionClick(option)}
                  onMouseEnter={() => setHoveredUnitOption(option.value)}
                  onMouseLeave={() => setHoveredUnitOption(null)}
                  style={dropdownItemStyle(option.value === unit, hoveredUnitOption === option.value)}
                >
                  {option.label}
                </li>
              )
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default LanguageSelector;