import React, { useState, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function WeightInput({ addWeight, lastWeight, t, unit, goalWeight, darkTheme = false, dateLocale }) {
  const [weight, setWeight] = useState('');
  const [date, setDate] = useState(new Date());
  const inputRef = useRef(null);

  useEffect(() => {
    if (lastWeight !== null && lastWeight !== undefined) {
      setWeight(convertWeight(lastWeight, unit).toFixed(2));
    } else {
      setWeight('');
    }
  }, [lastWeight, unit]);

  const isGoalHigher = goalWeight !== null && goalWeight !== undefined && parseFloat(weight) < goalWeight;

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      if (now.getDate() !== date.getDate() || now.getMonth() !== date.getMonth() || now.getFullYear() !== date.getFullYear()) {
        setDate(now);
      }
    }, 60000); // Check every minute

    return () => clearInterval(timer);
  }, [date]);

  useEffect(() => {
    const handleWheel = (e) => {
      e.preventDefault();
      const delta = e.deltaY > 0 ? -0.01 : 0.01;
      const currentWeight = parseFloat(weight) || 0;
      const newWeight = (currentWeight + delta).toFixed(2);
      setWeight(newWeight);
    };

    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement.addEventListener('wheel', handleWheel, { passive: false });
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('wheel', handleWheel);
      }
    };
  }, [weight]);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Submitting weight:", weight);
    console.log("Current date:", date);
    
    if (!weight || isNaN(parseFloat(weight))) {
      console.error("Invalid weight value");
      return;
    }
    
    if (!date || !(date instanceof Date) || isNaN(date.getTime())) {
      console.error("Invalid date value");
      return;
    }
  
    const weightInKg = unit === 'lbs' ? parseFloat(weight) / 2.20462 : parseFloat(weight);
    console.log("Weight in kg:", weightInKg);
    
    try {
      addWeight(weightInKg.toFixed(2), date);
    } catch (error) {
      console.error("Error in addWeight function:", error);
    }
  };

  const handleFocus = () => {
    inputRef.current.select();
  };

  const changeDate = (days) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);
    setDate(newDate);
  };

  const handleWeightChange = (e) => {
    setWeight(e.target.value);
  };

  const adjustWeight = (delta) => {
    const currentWeight = parseFloat(weight) || 0;
    const newWeight = (currentWeight + delta).toFixed(2);
    setWeight(newWeight);
  };

  function convertWeight(weight, unit) {
    if (weight === null || weight === undefined) return 0;
    return unit === 'lbs' ? (parseFloat(weight) * 2.20462) : parseFloat(weight);
  }

  return (
    <form onSubmit={handleSubmit} className={`weight-input-form ${darkTheme ? 'dark-theme' : ''}`}>
      <div className="date-picker-container">
        <button type="button" onClick={() => changeDate(-1)} title="Previous day">&larr;</button>
        <DatePicker
          selected={date}
          onChange={(date) => setDate(date)}
          dateFormat="dd/MM/yyyy"
          className="datepicker"
          locale={dateLocale}
        />
        <button type="button" onClick={() => changeDate(1)} title="Next day">&rarr;</button>
      </div>
      <div className="weight-input-container">
        <div className="weight-adjust-container">
          <button 
            type="button" 
            onClick={() => adjustWeight(isGoalHigher ? -0.01 : 0.01)} 
            className={'weight-adjust-button decrease'} 
            title={isGoalHigher ? "Decrease weight" : "Increase weight"}
          >
            {isGoalHigher ? '-' : '+'}
          </button>
          <input
            className='weight-input-number'
            ref={inputRef}
            type="number"
            step="0.01"
            value={weight}
            onChange={handleWeightChange}
            onFocus={handleFocus}
            title="Enter your weight"
          />
          <button 
            type="button" 
            onClick={() => adjustWeight(isGoalHigher ? 0.01 : -0.01)} 
            className={'weight-adjust-button increase'} 
            title={isGoalHigher ? "Increase weight" : "Decrease weight"}
          >
            {isGoalHigher ? '+' : '-'}
          </button>
        </div>
        
        <button type="submit" title={t.addWeight} className="submit-button">{t.addWeight}</button>
      </div>
    </form>
  );
}

export default WeightInput;